/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import '../logo.scss';

export const Telegram1 = ({className}) => {
    return (
        <svg
            onClick={() => window.open('https://telegram.com', '_blank', 'noopener,noreferrer')}

            className={`${className} icon`}
            fill="none"
            height="23"
            viewBox="0 0 23 23"
            width="23"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.9081 3.22831C23.0861 2.07763 21.9921 1.16939 20.969 1.6186L0.591476 10.5654C-0.142218 10.8875 -0.0885472 11.999 0.672404 12.2412L4.8747 13.5795C5.67684 13.8349 6.54526 13.7028 7.24569 13.2189L16.7202 6.67328C17.0059 6.47586 17.3173 6.88214 17.0732 7.13371L10.2532 14.1651C9.59167 14.8472 9.72295 16.0031 10.5188 16.5021L18.1545 21.2903C19.0109 21.8273 20.1126 21.2879 20.2727 20.2529L22.9081 3.22831Z"
                fill="#E8E7EC"
            />
        </svg>
    );
};
