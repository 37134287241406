/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, {useReducer} from "react";

export const PropertyDefaultWrapper = ({property1, className}) => {
    const [state, dispatch] = useReducer(reducer, {
        property1: property1 || "default",
    });

    return (
        <div
            className={`inline-flex items-center gap-[10.67px] px-6 py-0 h-9 rounded-sm justify-center relative ${
                state.property1 === "variant-2" ? "bg-[#c2aee526]" : "bg-[#87849b26]"
            } ${className}`}
            onMouseLeave={() => {
                dispatch("mouse_leave");
            }}
            onMouseEnter={() => {
                dispatch("mouse_enter");
            }}
        >
            <div
                className={`[font-family:'Aeonik-Medium',Helvetica] w-fit tracking-[0] text-base font-medium text-center whitespace-nowrap leading-[16.0px] relative cursor-pointer select-none ${
                    state.property1 === "variant-2" ? "text-[#c2aee5] transition duration-500" : "text-[#f4f4f6]"
                }`}
            >
                Launch a project
            </div>
        </div>
    );
};

function reducer(state, action) {
    switch (action) {
        case "mouse_enter":
            return {
                ...state,
                property1: "variant-2",
            };

        case "mouse_leave":
            return {
                ...state,
                property1: "default",
            };
    }

    return state;
}

PropertyDefaultWrapper.propTypes = {
    property1: PropTypes.oneOf(["variant-2", "default"]),
};
