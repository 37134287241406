import React from "react";
import '../logo.scss';

export const Telegram2 = ({ className }) => {
    return (
        <svg
            onClick={() => window.open('https://telegram.com', '_blank', 'noopener,noreferrer')}
            className={`${className} icon`} // Add a custom class for styling
            fill="none"
            height="23"
            viewBox="0 0 24 23"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M23.3456 3.22684C23.5236 2.07617 22.4296 1.16793 21.4065 1.61714L1.02898 10.5639C0.295282 10.8861 0.348953 11.9975 1.1099 12.2398L5.3122 13.5781C6.11434 13.8335 6.98276 13.7013 7.68319 13.2175L17.1577 6.67182C17.4434 6.47439 17.7548 6.88067 17.5107 7.13224L10.6907 14.1636C10.0292 14.8458 10.1605 16.0016 10.9563 16.5006L18.592 21.2889C19.4484 21.8259 20.5501 21.2864 20.7102 20.2514L23.3456 3.22684Z"
                fill="#E8E7EC" // Default fill color
            />
        </svg>
    );
};
