/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, {useReducer} from "react";

export const Explore = ({property1, className, url = "https://www.binance.com/fr"}) => {
    const [state, dispatch] = useReducer(reducer, {
        property1: property1 || "default",
    });
    const handleClick = () => {
        if (url) {
            window.location.href = url;
        }
    };


    return (
        <div
            className={`w-[183px] flex items-center gap-3 px-[27px] py-0 h-10 [-webkit-backdrop-filter:blur(20px)_brightness(100%)] rounded-sm justify-center backdrop-blur-[20px] backdrop-brightness-[100%] relative cursor-pointer ${
                state.property1 === "variant-2" ? "bg-[#c2aee526]" : "bg-[#87849b26]"
            } ${className}`}
            onMouseLeave={() => {
                dispatch("mouse_leave");
            }}
            onMouseEnter={() => {
                dispatch("mouse_enter");
            }}
            onClick={handleClick}
        >
            <div
                className={`[font-family:'Aeonik-Medium',Helvetica] w-fit tracking-[0] text-lg font-medium text-center whitespace-nowrap leading-[18px] relative cursor-pointer  ${
                    state.property1 === "variant-2" ? "text-[#c2aee5] transition duration-500" : "text-[#f4f4f6]"
                }`}
            >
                Explore
            </div>
        </div>
    );
};

function reducer(state, action) {
    switch (action) {
        case "mouse_enter":
            return {
                ...state,
                property1: "variant-2",
            };

        case "mouse_leave":
            return {
                ...state,
                property1: "default",
            };
    }

    return state;
}

Explore.propTypes = {
    property1: PropTypes.oneOf(["variant-2", "default"]),
};
