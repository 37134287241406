/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, {useReducer} from "react";

export const LaunchAProject = ({property1, className, text = "Launch a project", url = "https://www.google.com"}) => {
    const [state, dispatch] = useReducer(reducer, {
        property1: property1 || "default",
    });
    const handleClick = () => {
        if (url) {
            window.location.href = url;
        }
    };

    return (
        <div
            className={`inline-flex items-center gap-3 px-[22px] py-0 h-10 rounded-sm justify-center relative cursor-pointer select-none ${
                state.property1 === "variant-2" ? "bg-[#c2aee5] transition duration-500" : "bg-[#f4f4f6] transition duration-500"
            } ${className}`}
            onMouseLeave={() => {
                dispatch("mouse_leave");
            }}
            onMouseEnter={() => {
                dispatch("mouse_enter");
            }}
            onClick={handleClick}

        >
            <div
                className="[font-family:'Aeonik-Medium',Helvetica] w-fit tracking-[0] text-lg text-[#070607] font-medium text-center whitespace-nowrap leading-[18px] relative cursor-pointer select-none">
                {text}
            </div>
        </div>
    );
};

function reducer(state, action) {
    switch (action) {
        case "mouse_enter":
            return {
                ...state,
                property1: "variant-2",
            };

        case "mouse_leave":
            return {
                ...state,
                property1: "default",
            };
    }

    return state;
}

LaunchAProject.propTypes = {
    property1: PropTypes.oneOf(["variant-2", "default"]),
    text: PropTypes.string,
};
