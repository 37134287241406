import React, {useState} from "react";
import {useWindowWidth} from "../../breakpoints";
import {PlaceHolderButton} from "../../components/About";
import {Explore} from "../../components/Explore";
import {LaunchAProject} from "../../components/LaunchAProject";
import {PropertyDefaultWrapper} from "../../components/PropertyDefaultWrapper";
import {GitHub} from "../../icons/Gitbook3";
import {Twitter} from "../../icons/Property1Default2";
import {Telegram1} from "../../icons/Telegram1";
import {Telegram2} from "../../icons/Telegram2";
import {Telegram3} from "../../icons/Telegram3";
import {Twitter2} from "../../icons/Xcom2";
import {Burger} from "../../components/Burger/Burger";


export const ElementPhone = () => {
    const screenWidth = useWindowWidth();
    const [isBurgerOpen, setIsBurgerOpen] = useState(false);


    return (
        <div className="w-full flex flex-row justify-center bg-[#060607]">
            <div
                className={`overflow-hidden bg-[#060607] relative ${
                    screenWidth < 810
                        ? "w-[390px]"
                        : screenWidth >= 810 && screenWidth < 1200
                            ? "w-[810px]"
                            : screenWidth >= 1200 && screenWidth < 1440
                                ? "w-[1200px]"
                                : screenWidth >= 1440
                                    ? "w-[1440px]"
                                    : ""
                } ${
                    screenWidth < 810
                        ? "h-[8513px]"
                        : screenWidth >= 810 && screenWidth < 1200
                            ? "h-[6869px]"
                            : screenWidth >= 1200 && screenWidth < 1440
                                ? "h-[6725px]"
                                : screenWidth >= 1440
                                    ? "h-[7002px]"
                                    : ""
                }`}
            >
                <div
                    className={`absolute ${
                        screenWidth < 810
                            ? "w-[390px]"
                            : screenWidth >= 810 && screenWidth < 1200
                                ? "w-[812px]"
                                : screenWidth >= 1200 && screenWidth < 1440
                                    ? "w-[1162px]"
                                    : screenWidth >= 1440
                                        ? "w-[1398px]"
                                        : ""
                    } ${
                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                            ? "left-0"
                            : screenWidth >= 1200 && screenWidth < 1440
                                ? "left-5"
                                : screenWidth >= 1440
                                    ? "left-[22px]"
                                    : ""
                    } ${
                        screenWidth < 810
                            ? "top-[630px]"
                            : screenWidth >= 810 && screenWidth < 1200
                                ? "top-[790px]"
                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                    ? "top-[724px]"
                                    : ""
                    } ${
                        screenWidth < 810
                            ? "h-[6102px]"
                            : screenWidth >= 810 && screenWidth < 1200
                                ? "h-[4801px]"
                                : screenWidth >= 1200 && screenWidth < 1440
                                    ? "h-[4834px]"
                                    : screenWidth >= 1440
                                        ? "h-[4975px]"
                                        : ""
                    }`}
                >
                    <img
                        className={`top-0 absolute ${
                            screenWidth < 810
                                ? "w-[361px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "w-[771px]"
                                    : screenWidth >= 1200 && screenWidth < 1440
                                        ? "w-[553px]"
                                        : screenWidth >= 1440
                                            ? "w-[671px]"
                                            : ""
                        } ${
                            screenWidth < 810
                                ? "left-3.5"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "left-5"
                                    : screenWidth >= 1200 && screenWidth < 1440
                                        ? "left-[304px]"
                                        : screenWidth >= 1440
                                            ? "left-[363px]"
                                            : ""
                        } ${
                            screenWidth < 810
                                ? "h-[6102px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "h-[4801px]"
                                    : screenWidth >= 1200 && screenWidth < 1440
                                        ? "h-[4834px]"
                                        : screenWidth >= 1440
                                            ? "h-[4975px]"
                                            : ""
                        }`}
                        alt="Lines"
                        src={
                            screenWidth < 810
                                ? "/static/img/lines.svg"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "/static/img/lines-4.svg"
                                    : screenWidth >= 1200 && screenWidth < 1440
                                        ? "/static/img/lines-8.svg"
                                        : screenWidth >= 1440
                                            ? "/static/img/lines-12.svg"
                                            : undefined
                        }
                    />
                    <div
                        className={`absolute ${
                            screenWidth < 810
                                ? "w-[390px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "w-[812px]"
                                    : screenWidth >= 1200 && screenWidth < 1440
                                        ? "w-[1162px]"
                                        : screenWidth >= 1440
                                            ? "w-[1397px]"
                                            : ""
                        } ${
                            (screenWidth >= 1200 && screenWidth < 1440) ||
                            (screenWidth >= 810 && screenWidth < 1200) ||
                            screenWidth < 810
                                ? "left-0"
                                : screenWidth >= 1440
                                    ? "left-px"
                                    : ""
                        } ${
                            screenWidth < 810
                                ? "top-[4268px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "top-[2718px]"
                                    : screenWidth >= 1200 && screenWidth < 1440
                                        ? "top-[2651px]"
                                        : screenWidth >= 1440
                                            ? "top-[2792px]"
                                            : ""
                        } ${
                            screenWidth < 810
                                ? "h-[1834px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "h-[2083px]"
                                    : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                        ? "h-[2183px]"
                                        : ""
                        } ${
                            (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                ? "overflow-hidden"
                                : ""
                        } ${
                            (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                ? "rounded-[5px]"
                                : ""
                        }`}
                    >
                        <div
                            className={`left-0 absolute ${
                                screenWidth < 810
                                    ? "w-[390px]"
                                    : screenWidth >= 810 && screenWidth < 1200
                                        ? "w-[810px]"
                                        : screenWidth >= 1200 && screenWidth < 1440
                                            ? "w-[1162px]"
                                            : screenWidth >= 1440
                                                ? "w-[1397px]"
                                                : ""
                            } ${
                                screenWidth < 810
                                    ? "top-[185px]"
                                    : screenWidth >= 810 && screenWidth < 1200
                                        ? "top-[281px]"
                                        : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                            ? "top-[280px]"
                                            : ""
                            } ${screenWidth < 810 ? "bg-[100%_100%]" : ""} ${
                                screenWidth < 810
                                    ? "h-[1649px]"
                                    : screenWidth >= 810 && screenWidth < 1200
                                        ? "h-[1802px]"
                                        : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                            ? "h-[1903px]"
                                            : ""
                            } ${screenWidth < 810 ? "bg-[url(/static/img/light.png)]" : ""}`}
                        >
                            {((screenWidth >= 1200 && screenWidth < 1440) ||
                                screenWidth >= 1440 ||
                                (screenWidth >= 810 && screenWidth < 1200)) && (
                                <img
                                    className={`left-0 top-[254px] absolute ${
                                        screenWidth >= 810 && screenWidth < 1200
                                            ? "w-[810px]"
                                            : screenWidth >= 1200 && screenWidth < 1440
                                                ? "w-[1162px]"
                                                : screenWidth >= 1440
                                                    ? "w-[1397px]"
                                                    : ""
                                    } ${
                                        screenWidth >= 810 && screenWidth < 1200
                                            ? "h-[1548px]"
                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                ? "h-[1649px]"
                                                : ""
                                    }`}
                                    alt="Light"
                                    src={
                                        screenWidth >= 810 && screenWidth < 1200
                                            ? "/static/img/light-1.png"
                                            : screenWidth >= 1200 && screenWidth < 1440
                                                ? "/static/img/light-2.png"
                                                : screenWidth >= 1440
                                                    ? "/static/img/light-3.png"
                                                    : undefined
                                    }
                                />
                            )}

                            <div
                                className={`${
                                    screenWidth >= 810 && screenWidth < 1200
                                        ? "w-[644px]"
                                        : screenWidth >= 1200 && screenWidth < 1440
                                            ? "w-[726px]"
                                            : screenWidth >= 1440
                                                ? "w-[784px]"
                                                : screenWidth < 810
                                                    ? "w-[365px]"
                                                    : ""
                                } ${
                                    screenWidth >= 810 && screenWidth < 1200
                                        ? "left-[98px]"
                                        : screenWidth >= 1200 && screenWidth < 1440
                                            ? "left-[191px]"
                                            : screenWidth >= 1440
                                                ? "left-[250px]"
                                                : screenWidth < 810
                                                    ? "left-[11px]"
                                                    : ""
                                } ${
                                    (screenWidth >= 1200 && screenWidth < 1440) ||
                                    screenWidth >= 1440 ||
                                    (screenWidth >= 810 && screenWidth < 1200)
                                        ? "top-0"
                                        : screenWidth < 810
                                            ? "top-[30px]"
                                            : ""
                                } ${
                                    screenWidth >= 810 && screenWidth < 1200
                                        ? "h-[1621px]"
                                        : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                            ? "h-[1623px]"
                                            : screenWidth < 810
                                                ? "h-[1351px]"
                                                : ""
                                } ${
                                    (screenWidth >= 1200 && screenWidth < 1440) ||
                                    screenWidth >= 1440 ||
                                    (screenWidth >= 810 && screenWidth < 1200)
                                        ? "absolute"
                                        : screenWidth < 810
                                            ? "relative"
                                            : ""
                                }`}
                            >
                                <div
                                    className={`relative ${
                                        screenWidth >= 810 && screenWidth < 1200
                                            ? "h-[1621px]"
                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                ? "h-[1623px]"
                                                : screenWidth < 810
                                                    ? "h-[1351px]"
                                                    : ""
                                    }`}
                                >
                                    <div
                                        className={`left-0 absolute ${
                                            screenWidth >= 810 && screenWidth < 1200
                                                ? "w-[644px]"
                                                : screenWidth >= 1200 && screenWidth < 1440
                                                    ? "w-[726px]"
                                                    : screenWidth >= 1440
                                                        ? "w-[784px]"
                                                        : screenWidth < 810
                                                            ? "w-[365px]"
                                                            : ""
                                        } ${
                                            (screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)
                                                ? "top-[1388px]"
                                                : screenWidth < 810
                                                    ? "top-[1220px]"
                                                    : ""
                                        } ${
                                            screenWidth >= 810 && screenWidth < 1200
                                                ? "h-[233px]"
                                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                    ? "h-[235px]"
                                                    : screenWidth < 810
                                                        ? "h-[131px]"
                                                        : ""
                                        }`}
                                    >
                                        <div
                                            className={`flex flex-col items-start absolute ${
                                                screenWidth < 810
                                                    ? "w-[338px]"
                                                    : (screenWidth >= 1200 && screenWidth < 1440) ||
                                                    screenWidth >= 1440 ||
                                                    (screenWidth >= 810 && screenWidth < 1200)
                                                        ? "w-[336px]"
                                                        : ""
                                            } ${
                                                screenWidth < 810
                                                    ? "left-[27px]"
                                                    : screenWidth >= 810 && screenWidth < 1200
                                                        ? "left-[308px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "left-[390px]"
                                                            : screenWidth >= 1440
                                                                ? "left-[448px]"
                                                                : ""
                                            } ${
                                                screenWidth < 810
                                                    ? "top-0"
                                                    : (screenWidth >= 1200 && screenWidth < 1440) ||
                                                    screenWidth >= 1440 ||
                                                    (screenWidth >= 810 && screenWidth < 1200)
                                                        ? "top-[99px]"
                                                        : ""
                                            } ${
                                                screenWidth < 810
                                                    ? "gap-[15px]"
                                                    : (screenWidth >= 1200 && screenWidth < 1440) ||
                                                    screenWidth >= 1440 ||
                                                    (screenWidth >= 810 && screenWidth < 1200)
                                                        ? "gap-5"
                                                        : ""
                                            }`}
                                        >
                                            <p
                                                className={`[font-family:'Aeonik-Medium',Helvetica] mt-[-1.00px] text-[#f4f4f6] font-medium relative ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) ||
                                                    screenWidth >= 1440 ||
                                                    (screenWidth >= 810 && screenWidth < 1200)
                                                        ? "w-[333.36px]"
                                                        : ""
                                                } ${screenWidth < 810 ? "self-stretch" : ""} ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                                        ? "tracking-[-0.22px]"
                                                        : screenWidth >= 810 && screenWidth < 1200
                                                            ? "tracking-[-0.20px]"
                                                            : ""
                                                } ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                                        ? "text-[22px]"
                                                        : screenWidth >= 810 && screenWidth < 1200
                                                            ? "text-xl"
                                                            : ""
                                                } ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                                        ? "leading-[24.2px]"
                                                        : screenWidth >= 810 && screenWidth < 1200
                                                            ? "leading-[22.0px]"
                                                            : ""
                                                }`}
                                            >
                                                Phase 4: Governance and Growth
                                            </p>
                                            <p
                                                className={`[font-family:'Aeonik-Regular',Helvetica] tracking-[0] text-lg text-[#9897a3] font-normal leading-[23.4px] relative ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) ||
                                                    screenWidth >= 1440 ||
                                                    (screenWidth >= 810 && screenWidth < 1200)
                                                        ? "w-[333.36px]"
                                                        : ""
                                                } ${screenWidth < 810 ? "self-stretch" : ""}`}
                                            >
                                                Implementation of decentralized governance with an active role for $TINC
                                                stakers, continuous
                                                community development.
                                            </p>
                                        </div>
                                        {((screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)) && (
                                            <div
                                                className={`left-0 top-0 h-[227px] absolute ${
                                                    screenWidth >= 810 && screenWidth < 1200
                                                        ? "w-[283px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "w-[358px]"
                                                            : screenWidth >= 1440
                                                                ? "w-[407px]"
                                                                : ""
                                                }`}
                                            >
                                                <div className="w-[227px] left-0 top-0 h-[227px] absolute">
                                                    <div className="h-[227px] rounded-[10000px] relative">
                                                        <div
                                                            className="border-[1.3px] border-solid border-[#87849b] w-[227px] left-0 top-0 rotate-[90.00deg] h-[227px] rounded-[10000px] absolute"/>
                                                        <div
                                                            className="border-[1.3px] border-dashed border-[#87849b] w-[182px] left-[22px] opacity-50 top-[22px] rotate-[90.00deg] h-[182px] rounded-[10000px] absolute"/>
                                                        <div
                                                            className="border-[1.3px] border-solid border-[#87849b] w-12 left-[89px] top-[89px] rotate-[135deg] h-12 rounded-sm absolute"/>
                                                    </div>
                                                </div>
                                                <img
                                                    className={`left-[110px] top-[110px] h-[7px] absolute ${
                                                        screenWidth >= 810 && screenWidth < 1200
                                                            ? "w-[174px]"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "w-[248px]"
                                                                : screenWidth >= 1440
                                                                    ? "w-[297px]"
                                                                    : ""
                                                    }`}
                                                    alt="Line"
                                                    src={
                                                        screenWidth >= 810 && screenWidth < 1200
                                                            ? "/static/img/line-9.png"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "/static/img/line-18.png"
                                                                : screenWidth >= 1440
                                                                    ? "/static/img/line-31.png"
                                                                    : undefined
                                                    }
                                                />
                                            </div>
                                        )}

                                        {screenWidth < 810 && (
                                            <img className="absolute w-[7px] h-[7px] top-[11px] left-0" alt="Line"
                                                 src="/static/img/line-4.png"/>
                                        )}
                                    </div>
                                    <img
                                        className={`w-px absolute ${
                                            (screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)
                                                ? "left-[113px]"
                                                : screenWidth < 810
                                                    ? "left-[3px]"
                                                    : ""
                                        } ${
                                            (screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)
                                                ? "top-[999px]"
                                                : screenWidth < 810
                                                    ? "top-[812px]"
                                                    : ""
                                        } ${
                                            (screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)
                                                ? "h-[502px]"
                                                : screenWidth < 810
                                                    ? "h-[422px]"
                                                    : ""
                                        }`}
                                        alt="Line"
                                        src={
                                            screenWidth >= 810 && screenWidth < 1200
                                                ? "/static/img/line-10.svg"
                                                : screenWidth >= 1200 && screenWidth < 1440
                                                    ? "/static/img/line-19.svg"
                                                    : screenWidth >= 1440
                                                        ? "/static/img/line-28.svg"
                                                        : screenWidth < 810
                                                            ? "/static/img/line-1.svg"
                                                            : undefined
                                        }
                                    />
                                    <div
                                        className={`absolute ${
                                            screenWidth >= 810 && screenWidth < 1200
                                                ? "w-[637px]"
                                                : screenWidth >= 1200 && screenWidth < 1440
                                                    ? "w-[718px]"
                                                    : screenWidth >= 1440
                                                        ? "w-[777px]"
                                                        : screenWidth < 810
                                                            ? "w-[365px]"
                                                            : ""
                                        } ${
                                            (screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)
                                                ? "left-[7px]"
                                                : screenWidth < 810
                                                    ? "left-0"
                                                    : ""
                                        } ${
                                            (screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)
                                                ? "top-[893px]"
                                                : screenWidth < 810
                                                    ? "top-[800px]"
                                                    : ""
                                        } ${
                                            screenWidth >= 810 && screenWidth < 1200
                                                ? "h-[228px]"
                                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                    ? "h-[230px]"
                                                    : screenWidth < 810
                                                        ? "h-[131px]"
                                                        : ""
                                        }`}
                                    >
                                        <div
                                            className={`flex flex-col items-start absolute ${
                                                screenWidth < 810
                                                    ? "w-[338px]"
                                                    : (screenWidth >= 1200 && screenWidth < 1440) ||
                                                    screenWidth >= 1440 ||
                                                    (screenWidth >= 810 && screenWidth < 1200)
                                                        ? "w-[336px]"
                                                        : ""
                                            } ${
                                                screenWidth < 810
                                                    ? "left-[27px]"
                                                    : screenWidth >= 810 && screenWidth < 1200
                                                        ? "left-[301px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "left-[382px]"
                                                            : screenWidth >= 1440
                                                                ? "left-[441px]"
                                                                : ""
                                            } ${
                                                screenWidth < 810
                                                    ? "top-0"
                                                    : (screenWidth >= 1200 && screenWidth < 1440) ||
                                                    screenWidth >= 1440 ||
                                                    (screenWidth >= 810 && screenWidth < 1200)
                                                        ? "top-[94px]"
                                                        : ""
                                            } ${
                                                screenWidth < 810
                                                    ? "gap-[15px]"
                                                    : (screenWidth >= 1200 && screenWidth < 1440) ||
                                                    screenWidth >= 1440 ||
                                                    (screenWidth >= 810 && screenWidth < 1200)
                                                        ? "gap-5"
                                                        : ""
                                            }`}
                                        >
                                            <div
                                                className={`[font-family:'Aeonik-Medium',Helvetica] self-stretch mt-[-1.00px] text-[#f4f4f6] font-medium relative ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                                        ? "tracking-[-0.22px]"
                                                        : screenWidth >= 810 && screenWidth < 1200
                                                            ? "tracking-[-0.20px]"
                                                            : ""
                                                } ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                                        ? "text-[22px]"
                                                        : screenWidth >= 810 && screenWidth < 1200
                                                            ? "text-xl"
                                                            : ""
                                                } ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                                        ? "leading-[24.2px]"
                                                        : screenWidth >= 810 && screenWidth < 1200
                                                            ? "leading-[22.0px]"
                                                            : ""
                                                }`}
                                            >
                                                Phase 3: Expansion
                                            </div>
                                            <p className="[font-family:'Aeonik-Regular',Helvetica] self-stretch tracking-[0] text-lg text-[#9897a3] font-normal leading-[23.4px] relative">
                                                Development of strategic partnerships with key players in AI and
                                                blockchain, integration of
                                                additional tools to enhance user experience.
                                            </p>
                                        </div>
                                        {((screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)) && (
                                            <div
                                                className={`left-0 top-0 h-[212px] absolute ${
                                                    screenWidth >= 810 && screenWidth < 1200
                                                        ? "w-[276px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "w-[351px]"
                                                            : screenWidth >= 1440
                                                                ? "w-[400px]"
                                                                : ""
                                                }`}
                                            >
                                                <div className="w-[212px] left-0 top-0 h-[212px] absolute">
                                                    <img
                                                        className="w-[92px] left-[60px] -top-px h-[46px] absolute"
                                                        alt="Rectangle"
                                                        src={
                                                            screenWidth >= 810 && screenWidth < 1200
                                                                ? "/static/img/rectangle-23201.svg"
                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "/static/img/rectangle-23201-4.svg"
                                                                    : screenWidth >= 1440
                                                                        ? "/static/img/rectangle-23201-8.svg"
                                                                        : undefined
                                                        }
                                                    />
                                                    <img
                                                        className="w-[92px] left-[60px] top-[166px] h-[46px] absolute"
                                                        alt="Rectangle"
                                                        src={
                                                            screenWidth >= 810 && screenWidth < 1200
                                                                ? "/static/img/rectangle-23201-1.svg"
                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "/static/img/rectangle-23201-5.svg"
                                                                    : screenWidth >= 1440
                                                                        ? "/static/img/rectangle-23201-9.svg"
                                                                        : undefined
                                                        }
                                                    />
                                                    <img
                                                        className="w-[46px] left-[166px] top-[60px] h-[92px] absolute"
                                                        alt="Rectangle"
                                                        src={
                                                            screenWidth >= 810 && screenWidth < 1200
                                                                ? "/static/img/rectangle-23201-2.svg"
                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "/static/img/rectangle-23201-6.svg"
                                                                    : screenWidth >= 1440
                                                                        ? "/static/img/rectangle-23201-10.svg"
                                                                        : undefined
                                                        }
                                                    />
                                                    <img
                                                        className="w-[46px] -left-px top-[60px] h-[92px] absolute"
                                                        alt="Rectangle"
                                                        src={
                                                            screenWidth >= 810 && screenWidth < 1200
                                                                ? "/static/img/rectangle-23201-3.svg"
                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "/static/img/rectangle-23201-7.svg"
                                                                    : screenWidth >= 1440
                                                                        ? "/static/img/rectangle-23201-11.svg"
                                                                        : undefined
                                                        }
                                                    />
                                                    <div
                                                        className="border-[1.3px] border-solid border-[#87849b] w-[66px] left-[73px] opacity-50 top-[73px] rotate-[90.00deg] h-[66px] rounded-sm absolute"/>
                                                </div>
                                                <img
                                                    className={`left-[103px] top-[103px] h-[7px] absolute ${
                                                        screenWidth >= 810 && screenWidth < 1200
                                                            ? "w-[174px]"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "w-[248px]"
                                                                : screenWidth >= 1440
                                                                    ? "w-[297px]"
                                                                    : ""
                                                    }`}
                                                    alt="Line"
                                                    src={
                                                        screenWidth >= 810 && screenWidth < 1200
                                                            ? "/static/img/line-11.png"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "/static/img/line-20.png"
                                                                : screenWidth >= 1440
                                                                    ? "/static/img/line-29.png"
                                                                    : undefined
                                                    }
                                                />
                                            </div>
                                        )}

                                        {screenWidth < 810 && (
                                            <img className="absolute w-[7px] h-[7px] top-[9px] left-0" alt="Line"
                                                 src="/static/img/line-4.png"/>
                                        )}
                                    </div>
                                    <img
                                        className={`w-px absolute ${
                                            (screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)
                                                ? "left-[113px]"
                                                : screenWidth < 810
                                                    ? "left-[3px]"
                                                    : ""
                                        } ${
                                            (screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)
                                                ? "top-[499px]"
                                                : screenWidth < 810
                                                    ? "top-[392px]"
                                                    : ""
                                        } ${
                                            (screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)
                                                ? "h-[500px]"
                                                : screenWidth < 810
                                                    ? "h-[420px]"
                                                    : ""
                                        }`}
                                        alt="Line"
                                        src={
                                            screenWidth >= 810 && screenWidth < 1200
                                                ? "/static/img/line-12.svg"
                                                : screenWidth >= 1200 && screenWidth < 1440
                                                    ? "/static/img/line-21.svg"
                                                    : screenWidth >= 1440
                                                        ? "/static/img/line-30.svg"
                                                        : screenWidth < 810
                                                            ? "/static/img/line-3.svg"
                                                            : undefined
                                        }
                                    />
                                    <div
                                        className={`absolute ${
                                            screenWidth >= 810 && screenWidth < 1200
                                                ? "w-[603px]"
                                                : screenWidth >= 1200 && screenWidth < 1440
                                                    ? "w-[684px]"
                                                    : screenWidth >= 1440
                                                        ? "w-[743px]"
                                                        : screenWidth < 810
                                                            ? "w-[365px]"
                                                            : ""
                                        } ${
                                            (screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)
                                                ? "left-[41px]"
                                                : screenWidth < 810
                                                    ? "left-0"
                                                    : ""
                                        } ${
                                            (screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)
                                                ? "top-[452px]"
                                                : screenWidth < 810
                                                    ? "top-[379px]"
                                                    : ""
                                        } ${
                                            screenWidth >= 810 && screenWidth < 1200
                                                ? "h-[145px]"
                                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                    ? "h-[147px]"
                                                    : screenWidth < 810
                                                        ? "h-[108px]"
                                                        : ""
                                        }`}
                                    >
                                        <div
                                            className={`flex flex-col items-start absolute ${
                                                screenWidth < 810
                                                    ? "w-[339px]"
                                                    : (screenWidth >= 1200 && screenWidth < 1440) ||
                                                    screenWidth >= 1440 ||
                                                    (screenWidth >= 810 && screenWidth < 1200)
                                                        ? "w-[336px]"
                                                        : ""
                                            } ${
                                                screenWidth < 810
                                                    ? "left-[26px]"
                                                    : screenWidth >= 810 && screenWidth < 1200
                                                        ? "left-[267px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "left-[348px]"
                                                            : screenWidth >= 1440
                                                                ? "left-[407px]"
                                                                : ""
                                            } ${
                                                screenWidth < 810
                                                    ? "top-0"
                                                    : (screenWidth >= 1200 && screenWidth < 1440) ||
                                                    screenWidth >= 1440 ||
                                                    (screenWidth >= 810 && screenWidth < 1200)
                                                        ? "top-[34px]"
                                                        : ""
                                            } ${
                                                screenWidth < 810
                                                    ? "gap-[15px]"
                                                    : (screenWidth >= 1200 && screenWidth < 1440) ||
                                                    screenWidth >= 1440 ||
                                                    (screenWidth >= 810 && screenWidth < 1200)
                                                        ? "gap-5"
                                                        : ""
                                            }`}
                                        >
                                            <div
                                                className={`[font-family:'Aeonik-Medium',Helvetica] self-stretch mt-[-1.00px] text-[#f4f4f6] font-medium relative ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                                        ? "tracking-[-0.22px]"
                                                        : screenWidth >= 810 && screenWidth < 1200
                                                            ? "tracking-[-0.20px]"
                                                            : ""
                                                } ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                                        ? "text-[22px]"
                                                        : screenWidth >= 810 && screenWidth < 1200
                                                            ? "text-xl"
                                                            : ""
                                                } ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                                        ? "leading-[24.2px]"
                                                        : screenWidth >= 810 && screenWidth < 1200
                                                            ? "leading-[22.0px]"
                                                            : ""
                                                }`}
                                            >
                                                Phase 2: Project Launch
                                            </div>
                                            <p className="[font-family:'Aeonik-Regular',Helvetica] self-stretch tracking-[0] text-lg text-[#9897a3] font-normal leading-[23.4px] relative">
                                                Deployment of the launchpad with the first AI and blockchain projects
                                                integrated into Bittensor.
                                            </p>
                                        </div>
                                        {((screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)) && (
                                            <div
                                                className={`left-0 top-0 h-[95px] absolute ${
                                                    screenWidth >= 810 && screenWidth < 1200
                                                        ? "w-[242px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "w-[317px]"
                                                            : screenWidth >= 1440
                                                                ? "w-[366px]"
                                                                : ""
                                                }`}
                                            >
                                                <div
                                                    className="border-[1.3px] border-solid border-[#87849b] w-36 left-0 top-0 h-[95px] rounded-sm absolute">
                                                    <div
                                                        className="border-[1.3px] border-solid border-[#87849b] -left-px opacity-50 top-[17px] h-[76px] rounded-sm relative"/>
                                                </div>
                                                <img
                                                    className={`left-[68px] top-11 h-[7px] absolute ${
                                                        screenWidth >= 810 && screenWidth < 1200
                                                            ? "w-[174px]"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "w-[248px]"
                                                                : screenWidth >= 1440
                                                                    ? "w-[297px]"
                                                                    : ""
                                                    }`}
                                                    alt="Line"
                                                    src={
                                                        screenWidth >= 810 && screenWidth < 1200
                                                            ? "/static/img/line-13.png"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "/static/img/line-22.png"
                                                                : screenWidth >= 1440
                                                                    ? "/static/img/line-31.png"
                                                                    : undefined
                                                    }
                                                />
                                            </div>
                                        )}

                                        {screenWidth < 810 && (
                                            <img className="absolute w-[7px] h-[7px] top-2.5 left-0" alt="Line"
                                                 src="/static/img/line-4.png"/>
                                        )}
                                    </div>
                                    <img
                                        className={`w-px absolute ${
                                            (screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)
                                                ? "left-[113px]"
                                                : screenWidth < 810
                                                    ? "left-[3px]"
                                                    : ""
                                        } ${
                                            (screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)
                                                ? "top-[39px]"
                                                : screenWidth < 810
                                                    ? "top-3"
                                                    : ""
                                        } ${
                                            (screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)
                                                ? "h-[460px]"
                                                : screenWidth < 810
                                                    ? "h-[379px]"
                                                    : ""
                                        }`}
                                        alt="Line"
                                        src={
                                            screenWidth >= 810 && screenWidth < 1200
                                                ? "/static/img/line-14.svg"
                                                : screenWidth >= 1200 && screenWidth < 1440
                                                    ? "/static/img/line-23.svg"
                                                    : screenWidth >= 1440
                                                        ? "/static/img/line-32.svg"
                                                        : screenWidth < 810
                                                            ? "/static/img/line-5.svg"
                                                            : undefined
                                        }
                                    />
                                    <div
                                        className={`top-0 absolute ${
                                            screenWidth >= 810 && screenWidth < 1200
                                                ? "w-[570px]"
                                                : screenWidth >= 1200 && screenWidth < 1440
                                                    ? "w-[652px]"
                                                    : screenWidth >= 1440
                                                        ? "w-[710px]"
                                                        : screenWidth < 810
                                                            ? "w-[317px]"
                                                            : ""
                                        } ${
                                            (screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)
                                                ? "left-[74px]"
                                                : screenWidth < 810
                                                    ? "left-0"
                                                    : ""
                                        } ${
                                            screenWidth >= 810 && screenWidth < 1200
                                                ? "h-[115px]"
                                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                    ? "h-[117px]"
                                                    : screenWidth < 810
                                                        ? "h-[108px]"
                                                        : ""
                                        }`}
                                    >
                                        <div
                                            className={`flex flex-col items-start absolute ${
                                                screenWidth < 810
                                                    ? "w-[291px]"
                                                    : (screenWidth >= 1200 && screenWidth < 1440) ||
                                                    screenWidth >= 1440 ||
                                                    (screenWidth >= 810 && screenWidth < 1200)
                                                        ? "w-[336px]"
                                                        : ""
                                            } ${
                                                screenWidth < 810
                                                    ? "left-[26px]"
                                                    : screenWidth >= 810 && screenWidth < 1200
                                                        ? "left-[234px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "left-[316px]"
                                                            : screenWidth >= 1440
                                                                ? "left-[374px]"
                                                                : ""
                                            } ${
                                                screenWidth < 810
                                                    ? "top-0"
                                                    : (screenWidth >= 1200 && screenWidth < 1440) ||
                                                    screenWidth >= 1440 ||
                                                    (screenWidth >= 810 && screenWidth < 1200)
                                                        ? "top-[27px]"
                                                        : ""
                                            } ${
                                                screenWidth < 810
                                                    ? "gap-[15px]"
                                                    : (screenWidth >= 1200 && screenWidth < 1440) ||
                                                    screenWidth >= 1440 ||
                                                    (screenWidth >= 810 && screenWidth < 1200)
                                                        ? "gap-5"
                                                        : ""
                                            }`}
                                        >
                                            <div
                                                className={`[font-family:'Aeonik-Medium',Helvetica] self-stretch mt-[-1.00px] text-[#f4f4f6] font-medium relative ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                                        ? "tracking-[-0.22px]"
                                                        : screenWidth >= 810 && screenWidth < 1200
                                                            ? "tracking-[-0.20px]"
                                                            : ""
                                                } ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                                        ? "text-[22px]"
                                                        : screenWidth >= 810 && screenWidth < 1200
                                                            ? "text-xl"
                                                            : ""
                                                } ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                                        ? "leading-[24.2px]"
                                                        : screenWidth >= 810 && screenWidth < 1200
                                                            ? "leading-[22.0px]"
                                                            : ""
                                                }`}
                                            >
                                                Phase 1: Launch
                                            </div>
                                            <p className="[font-family:'Aeonik-Regular',Helvetica] self-stretch tracking-[0] text-lg text-[#9897a3] font-normal leading-[23.4px] relative">
                                                Launch of $TINC, introduction of staking, and community development.
                                            </p>
                                        </div>
                                        {((screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)) && (
                                            <div
                                                className={`left-0 top-0 h-[79px] absolute ${
                                                    screenWidth >= 810 && screenWidth < 1200
                                                        ? "w-[209px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "w-[284px]"
                                                            : screenWidth >= 1440
                                                                ? "w-[333px]"
                                                                : ""
                                                }`}
                                            >
                                                <div
                                                    className="border-[1.3px] border-solid border-[#87849b] w-14 left-3 top-3 rotate-[45.00deg] h-14 rounded-sm absolute"/>
                                                <img
                                                    className={`left-9 top-9 h-[7px] absolute ${
                                                        screenWidth >= 810 && screenWidth < 1200
                                                            ? "w-[174px]"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "w-[248px]"
                                                                : screenWidth >= 1440
                                                                    ? "w-[297px]"
                                                                    : ""
                                                    }`}
                                                    alt="Line"
                                                    src={
                                                        screenWidth >= 810 && screenWidth < 1200
                                                            ? "/static/img/line-15.png"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "/static/img/line-24.png"
                                                                : screenWidth >= 1440
                                                                    ? "/static/img/line-33.png"
                                                                    : undefined
                                                    }
                                                />
                                            </div>
                                        )}

                                        {screenWidth < 810 && (
                                            <img className="absolute w-[7px] h-[7px] top-[9px] left-0" alt="Line"
                                                 src="/static/img/line-6.png"/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`top-0 absolute ${
                                screenWidth >= 810 && screenWidth < 1200
                                    ? "w-[773px]"
                                    : screenWidth >= 1200 && screenWidth < 1440
                                        ? "w-[1100px]"
                                        : screenWidth >= 1440
                                            ? "w-[1332px]"
                                            : ""
                            } ${
                                screenWidth < 810
                                    ? "left-4"
                                    : screenWidth >= 810 && screenWidth < 1200
                                        ? "left-5"
                                        : screenWidth >= 1200 && screenWidth < 1440
                                            ? "left-8"
                                            : screenWidth >= 1440
                                                ? "left-[34px]"
                                                : ""
                            } ${
                                screenWidth < 810
                                    ? "inline-flex"
                                    : (screenWidth >= 1200 && screenWidth < 1440) ||
                                    screenWidth >= 1440 ||
                                    (screenWidth >= 810 && screenWidth < 1200)
                                        ? "flex"
                                        : ""
                            } ${screenWidth < 810 ? "flex-col" : ""} ${
                                screenWidth < 810
                                    ? "items-start"
                                    : (screenWidth >= 1200 && screenWidth < 1440) ||
                                    screenWidth >= 1440 ||
                                    (screenWidth >= 810 && screenWidth < 1200)
                                        ? "items-center"
                                        : ""
                            } ${screenWidth < 810 ? "gap-[15px]" : ""} ${screenWidth < 810 ? "justify-center" : ""}`}
                        >
                            <div
                                className={`flex items-center gap-2.5 relative ${
                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                        ? "w-[271px]"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "w-[386px]"
                                            : screenWidth >= 1440
                                                ? "w-[331px]"
                                                : ""
                                } ${screenWidth < 810 ? "flex-[0_0_auto]" : ""}`}
                            >
                                <div id="Roadmap"
                                     className="[font-family:'Aeonik-Regular',Helvetica] w-fit mt-[-1.00px] tracking-[0] text-lg text-[#9897a3] font-normal leading-[23.4px] whitespace-nowrap relative">
                                    03 / Roadmap
                                </div>
                            </div>
                            <div
                                className={`[font-family:'Aeonik-Medium',Helvetica] w-fit text-[#f4f4f6] font-medium whitespace-nowrap relative ${
                                    (screenWidth >= 1200 && screenWidth < 1440) ||
                                    screenWidth >= 1440 ||
                                    (screenWidth >= 810 && screenWidth < 1200)
                                        ? "mt-[-1.00px]"
                                        : ""
                                } ${
                                    screenWidth < 810
                                        ? "tracking-[-0.32px]"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "tracking-[-0.29px]"
                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                ? "tracking-[-0.48px]"
                                                : ""
                                } ${
                                    screenWidth < 810
                                        ? "text-[32px]"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "text-[29px]"
                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                ? "text-5xl"
                                                : ""
                                } ${
                                    screenWidth < 810
                                        ? "leading-8"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "leading-[29px]"
                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                ? "leading-[48px]"
                                                : ""
                                }`}
                            >
                                What’s next?
                            </div>
                        </div>
                    </div>
                    <div
                        className={`left-0 flex flex-col items-start bg-[#e8e7ec] absolute ${
                            screenWidth < 810
                                ? "w-[390px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "w-[810px]"
                                    : screenWidth >= 1200 && screenWidth < 1440
                                        ? "w-[1160px]"
                                        : screenWidth >= 1440
                                            ? "w-[1397px]"
                                            : ""
                        } ${
                            screenWidth < 810
                                ? "top-[2414px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "top-[1473px]"
                                    : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                        ? "top-[1219px]"
                                        : ""
                        } ${
                            screenWidth < 810
                                ? "gap-[45px]"
                                : (screenWidth >= 1200 && screenWidth < 1440) ||
                                screenWidth >= 1440 ||
                                (screenWidth >= 810 && screenWidth < 1200)
                                    ? "gap-20"
                                    : ""
                        } ${
                            screenWidth < 810
                                ? "pt-[45px] pb-[75px] px-[15px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "px-5 py-20"
                                    : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                        ? "pt-20 pb-[110px] px-[33px]"
                                        : ""
                        } ${(screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 ? "overflow-hidden" : ""} ${
                            (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 ? "rounded-[5px]" : ""
                        }`}
                    >
                        <img
                            className={`top-0 absolute ${
                                screenWidth < 810
                                    ? "w-[361px]"
                                    : screenWidth >= 810 && screenWidth < 1200
                                        ? "w-[771px]"
                                        : screenWidth >= 1200 && screenWidth < 1440
                                            ? "w-[553px]"
                                            : screenWidth >= 1440
                                                ? "w-[671px]"
                                                : ""
                            } ${
                                screenWidth < 810
                                    ? "left-3.5"
                                    : screenWidth >= 810 && screenWidth < 1200
                                        ? "left-5"
                                        : screenWidth >= 1200 && screenWidth < 1440
                                            ? "left-[303px]"
                                            : screenWidth >= 1440
                                                ? "left-[363px]"
                                                : ""
                            } ${
                                screenWidth < 810
                                    ? "h-[1615px]"
                                    : screenWidth >= 810 && screenWidth < 1200
                                        ? "h-[995px]"
                                        : screenWidth >= 1200 && screenWidth < 1440
                                            ? "h-[1160px]"
                                            : screenWidth >= 1440
                                                ? "h-[1300px]"
                                                : ""
                            }`}
                            alt="Lines"
                            src={
                                screenWidth < 810
                                    ? "/static/img/lines-2.svg"
                                    : screenWidth >= 810 && screenWidth < 1200
                                        ? "/static/img/lines-6.svg"
                                        : screenWidth >= 1200 && screenWidth < 1440
                                            ? "/static/img/lines-10.svg"
                                            : screenWidth >= 1440
                                                ? "/static/img/lines-14.svg"
                                                : undefined
                            }
                        />
                        <div
                            className={`w-full flex self-stretch flex-[0_0_auto] relative ${screenWidth < 810 ? "flex-col" : ""} ${
                                screenWidth < 810
                                    ? "items-start"
                                    : (screenWidth >= 1200 && screenWidth < 1440) ||
                                    screenWidth >= 1440 ||
                                    (screenWidth >= 810 && screenWidth < 1200)
                                        ? "items-center"
                                        : ""
                            } ${screenWidth < 810 ? "gap-[15px]" : ""} ${screenWidth < 810 ? "justify-center" : ""}`}
                        >
                            <div
                                className={`flex items-center gap-2.5 relative ${
                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                        ? "w-[271px]"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "w-[383px]"
                                            : screenWidth >= 1440
                                                ? "w-[331px]"
                                                : ""
                                } ${screenWidth < 810 ? "flex-[0_0_auto]" : ""} ${screenWidth >= 1440 ? "justify-center" : ""}`}
                            >
                                <div id="Features"
                                     className={`[font-family:'Aeonik-Regular',Helvetica] mt-[-1.00px] tracking-[0] text-lg text-[#575565] font-normal leading-[23.4px] whitespace-nowrap relative ${
                                         (screenWidth >= 1200 && screenWidth < 1440) ||
                                         (screenWidth >= 810 && screenWidth < 1200) ||
                                         screenWidth < 810
                                             ? "w-fit"
                                             : screenWidth >= 1440
                                                 ? "w-[324.24px]"
                                                 : ""
                                     } ${screenWidth >= 1440 ? "h-[23px]" : ""}`}
                                >
                                    02 / Features
                                </div>
                            </div>
                            <div
                                className={`[font-family:'Aeonik-Medium',Helvetica] w-fit text-black font-medium whitespace-nowrap relative ${
                                    (screenWidth >= 1200 && screenWidth < 1440) ||
                                    screenWidth >= 1440 ||
                                    (screenWidth >= 810 && screenWidth < 1200)
                                        ? "mt-[-1.00px]"
                                        : ""
                                } ${
                                    screenWidth < 810
                                        ? "tracking-[-0.32px]"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "tracking-[-0.29px]"
                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                ? "tracking-[-0.48px]"
                                                : ""
                                } ${
                                    screenWidth < 810
                                        ? "text-[32px]"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "text-[29px]"
                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                ? "text-5xl"
                                                : ""
                                } ${
                                    screenWidth < 810
                                        ? "leading-8"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "leading-[29px]"
                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                ? "leading-[48px]"
                                                : ""
                                }`}
                            >
                                Our key features
                            </div>
                        </div>
                        <div
                            className={`w-full flex self-stretch flex-col items-start flex-[0_0_auto] relative ${
                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                    ? "gap-[7px]"
                                    : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                        ? "gap-2.5"
                                        : ""
                            }`}
                        >
                            <div
                                className={`w-full flex self-stretch flex-[0_0_auto] relative ${screenWidth < 810 ? "flex-col" : ""} ${
                                    screenWidth < 810
                                        ? "items-start"
                                        : (screenWidth >= 1200 && screenWidth < 1440) ||
                                        screenWidth >= 1440 ||
                                        (screenWidth >= 810 && screenWidth < 1200)
                                            ? "items-center"
                                            : ""
                                } ${
                                    screenWidth >= 810 && screenWidth < 1200
                                        ? "gap-[7px]"
                                        : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                            ? "gap-2.5"
                                            : ""
                                } ${screenWidth < 810 ? "overflow-hidden" : ""} ${screenWidth < 810 ? "rounded" : ""} ${
                                    screenWidth < 810 ? "justify-center" : ""
                                }`}
                            >
                                <div
                                    className={`self-stretch overflow-hidden relative ${screenWidth < 810 ? "w-full" : ""} ${
                                        (screenWidth >= 1200 && screenWidth < 1440) ||
                                        screenWidth >= 1440 ||
                                        (screenWidth >= 810 && screenWidth < 1200)
                                            ? "flex"
                                            : ""
                                    } ${
                                        (screenWidth >= 1200 && screenWidth < 1440) ||
                                        screenWidth >= 1440 ||
                                        (screenWidth >= 810 && screenWidth < 1200)
                                            ? "flex-col"
                                            : ""
                                    } ${
                                        (screenWidth >= 1200 && screenWidth < 1440) ||
                                        screenWidth >= 1440 ||
                                        (screenWidth >= 810 && screenWidth < 1200)
                                            ? "items-start"
                                            : ""
                                    } ${
                                        (screenWidth >= 1200 && screenWidth < 1440) ||
                                        screenWidth >= 1440 ||
                                        (screenWidth >= 810 && screenWidth < 1200)
                                            ? "grow"
                                            : ""
                                    } ${
                                        (screenWidth >= 1200 && screenWidth < 1440) ||
                                        screenWidth >= 1440 ||
                                        (screenWidth >= 810 && screenWidth < 1200)
                                            ? "flex-1"
                                            : ""
                                    } ${
                                        (screenWidth >= 1200 && screenWidth < 1440) ||
                                        screenWidth >= 1440 ||
                                        (screenWidth >= 810 && screenWidth < 1200)
                                            ? "p-[23px]"
                                            : ""
                                    } ${screenWidth < 810 ? "h-[296px]" : ""} ${
                                        (screenWidth >= 1200 && screenWidth < 1440) ||
                                        screenWidth >= 1440 ||
                                        (screenWidth >= 810 && screenWidth < 1200)
                                            ? "rounded"
                                            : ""
                                    } ${
                                        (screenWidth >= 1200 && screenWidth < 1440) ||
                                        screenWidth >= 1440 ||
                                        (screenWidth >= 810 && screenWidth < 1200)
                                            ? "justify-between"
                                            : ""
                                    } ${
                                        screenWidth < 810
                                            ? "bg-[#070607]"
                                            : (screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)
                                                ? "bg-[#100f12]"
                                                : ""
                                    }`}
                                >
                                    {screenWidth < 810 && (
                                        <div className="relative w-[459px] h-[296px]">
                                            <img
                                                className="absolute w-[360px] h-[296px] top-0 left-0"
                                                alt="Mask group"
                                                src="/static/img/mask-group.png"
                                            />
                                            <div className="absolute w-[448px] h-[273px] top-[11px] left-[11px]">
                                                <div
                                                    className="flex flex-col w-[220px] h-[273.34px] items-start justify-center gap-[1.41px] absolute top-0 left-0">
                                                    <div
                                                        className="relative self-stretch w-full h-[101.96px] mt-[-0.01px] bg-[#070607] rounded-[2.11px] overflow-hidden">
                                                        <div
                                                            className="relative w-[315px] h-[153px] top-[-26px] -left-12">
                                                            <div
                                                                className="absolute w-[315px] h-[153px] top-0 left-0 opacity-30">
                                                                <div
                                                                    className="relative w-[220px] h-[104px] top-[25px] left-12">
                                                                    <div
                                                                        className="w-[104px] h-[104px] left-[58px] rounded-[51.97px] border-[0.85px] border-dashed border-[#575565] opacity-45 absolute top-0"/>
                                                                    <img
                                                                        className="absolute w-px h-[102px] top-px left-[109px]"
                                                                        alt="Vector"
                                                                        src="/static/img/vector-1114.svg"
                                                                    />
                                                                    <img
                                                                        className="absolute w-[220px] h-px top-[52px] left-0"
                                                                        alt="Vector"
                                                                        src="/static/img/vector-1115.svg"
                                                                    />
                                                                    <img
                                                                        className="absolute w-[220px] h-[102px] top-px left-0"
                                                                        alt="Vector"
                                                                        src="/static/img/vector-1116.svg"
                                                                    />
                                                                    <img
                                                                        className="absolute w-[220px] h-[102px] top-px left-0"
                                                                        alt="Vector"
                                                                        src="/static/img/vector-1117.svg"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <img
                                                                className="absolute w-[42px] h-[42px] top-[76px] left-[57px]"
                                                                alt="Frame"
                                                                src="/static/img/frame-1618869820.svg"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="flex flex-col items-start justify-center gap-[26.07px] p-[10.15px] relative self-stretch w-full flex-[0_0_auto] mb-[-0.01px] bg-[#100f12] rounded-[2.11px] overflow-hidden">
                                                        <div
                                                            className="flex flex-col items-start gap-[10.15px] relative self-stretch w-full flex-[0_0_auto]">
                                                            <div
                                                                className="inline-flex items-start gap-[7.61px] relative flex-[0_0_auto]">
                                                                <div
                                                                    className="relative w-[67.65px] h-[11.54px] bg-[#87849b] rounded-[0.7px] opacity-20"/>
                                                            </div>
                                                            <div
                                                                className="flex flex-col items-start gap-[5.64px] relative self-stretch w-full flex-[0_0_auto]">
                                                                <div
                                                                    className="w-[150.46px] h-[3.46px] relative bg-[#87849b] opacity-20"/>
                                                                <div
                                                                    className="relative w-[179.27px] h-[3.46px] bg-[#87849b] opacity-20"/>
                                                                <div
                                                                    className="relative w-[131.9px] h-[3.46px] bg-[#87849b] opacity-20"/>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="flex flex-col items-start gap-[10.15px] relative self-stretch w-full flex-[0_0_auto]">
                                                            <div
                                                                className="flex items-center justify-between relative self-stretch w-full flex-[0_0_auto]">
                                                                <div
                                                                    className="relative w-fit mt-[-0.51px] [font-family:'Aeonik-Regular',Helvetica] font-normal text-[#87849b] text-[9.1px] tracking-[0] leading-[11.9px] whitespace-nowrap">
                                                                    Total Raised
                                                                </div>
                                                                <div
                                                                    className="inline-flex items-center gap-[6.09px] relative flex-[0_0_auto]">
                                                                    <div
                                                                        className="relative w-fit mt-[-0.51px] [font-family:'Aeonik-Regular',Helvetica] font-normal text-[#87849b] text-[9.1px] tracking-[0] leading-[11.9px] whitespace-nowrap">
                                                                        $200,000
                                                                    </div>
                                                                    <div
                                                                        className="relative w-fit mt-[-0.51px] [font-family:'Aeonik-Regular',Helvetica] font-normal text-[#87849b] text-[9.1px] tracking-[0] leading-[11.9px] whitespace-nowrap">
                                                                        /
                                                                    </div>
                                                                    <div
                                                                        className="relative w-fit mt-[-0.51px] [font-family:'Aeonik-Regular',Helvetica] font-normal text-[#87849b] text-[9.1px] tracking-[0] leading-[11.9px] whitespace-nowrap">
                                                                        $250,000
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="relative w-[199.24px] h-[11.54px] rounded-[0.7px] border-[0.85px] border-solid border-[#87849b4c]">
                                                                <div className="relative w-[159px] h-3 rounded-[0.7px]">
                                                                    <img
                                                                        className="absolute w-[107px] h-2.5 top-px left-[26px]"
                                                                        alt="Vector"
                                                                        src="/static/img/vector.svg"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="flex h-[22.54px] items-center justify-center gap-[6.09px] pt-0 pb-[0.7px] px-[13.7px] relative self-stretch w-full mb-[-1.00px] ml-[-1.00px] mr-[-1.00px] rounded-[1.01px] border-[0.85px] border-solid border-[#c2aee599]">
                                                            <div
                                                                className="relative w-fit [font-family:'Aeonik-Regular',Helvetica] font-normal text-[#c2aee5] text-[9.1px] text-center tracking-[0] leading-[9.1px] whitespace-nowrap">
                                                                Participate now
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="flex flex-col w-[220px] h-[273.34px] items-start justify-center gap-[1.41px] absolute top-0 left-[228px]">
                                                    <div
                                                        className="relative self-stretch w-full h-[101.96px] mt-[-0.01px] bg-[#070607] rounded-[2.11px] overflow-hidden">
                                                        <div
                                                            className="relative w-[315px] h-[153px] top-[-26px] -left-12">
                                                            <div
                                                                className="absolute w-[315px] h-[153px] top-0 left-0 opacity-30">
                                                                <div
                                                                    className="relative w-[162px] h-[104px] top-[25px] left-12">
                                                                    <div
                                                                        className="w-[104px] h-[104px] left-[58px] rounded-[51.97px] border-[0.85px] border-dashed border-[#575565] opacity-45 absolute top-0"/>
                                                                    <img
                                                                        className="absolute w-px h-[102px] top-px left-[109px]"
                                                                        alt="Vector"
                                                                        src="/static/img/vector-1114-1.svg"
                                                                    />
                                                                    <img
                                                                        className="absolute w-[121px] h-px top-[52px] left-0"
                                                                        alt="Vector"
                                                                        src="/static/img/vector-1115-1.svg"
                                                                    />
                                                                    <img
                                                                        className="absolute w-[121px] h-[102px] top-px left-0"
                                                                        alt="Vector"
                                                                        src="/static/img/vector-1116-1.svg"
                                                                    />
                                                                    <img
                                                                        className="absolute w-[121px] h-[102px] top-px left-0"
                                                                        alt="Vector"
                                                                        src="/static/img/vector-1117-1.svg"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <img
                                                                className="absolute w-[42px] h-[42px] top-[76px] left-[57px]"
                                                                alt="Frame"
                                                                src="/static/img/frame-1618869820-1.svg"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="flex flex-col items-start justify-center gap-[26.07px] p-[10.15px] relative self-stretch w-full flex-[0_0_auto] mb-[-0.01px] bg-[#100f12] rounded-[2.11px] overflow-hidden">
                                                        <div
                                                            className="flex flex-col items-start gap-[10.15px] relative self-stretch w-full flex-[0_0_auto]">
                                                            <div
                                                                className="inline-flex items-start gap-[7.61px] relative flex-[0_0_auto]">
                                                                <div
                                                                    className="w-[35.37px] h-[11.54px] rounded-[0.7px] relative bg-[#87849b] opacity-20"/>
                                                            </div>
                                                            <div
                                                                className="flex flex-col items-start gap-[5.64px] relative self-stretch w-full flex-[0_0_auto]">
                                                                <div
                                                                    className="w-[176.31px] h-[3.46px] relative bg-[#87849b] opacity-20"/>
                                                                <div
                                                                    className="relative w-[121.77px] h-[3.46px] bg-[#87849b] opacity-20"/>
                                                                <div
                                                                    className="relative w-[148.62px] h-[3.46px] bg-[#87849b] opacity-20"/>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="flex flex-col items-start gap-[10.15px] relative self-stretch w-full flex-[0_0_auto]">
                                                            <div
                                                                className="flex items-center justify-between relative self-stretch w-full flex-[0_0_auto]">
                                                                <div
                                                                    className="relative w-fit mt-[-0.51px] [font-family:'Aeonik-Regular',Helvetica] font-normal text-[#87849b] text-[9.1px] tracking-[0] leading-[11.9px] whitespace-nowrap">
                                                                    Total Raised
                                                                </div>
                                                                <div
                                                                    className="inline-flex items-center gap-[6.09px] relative flex-[0_0_auto]">
                                                                    <div
                                                                        className="relative w-fit mt-[-0.51px] [font-family:'Aeonik-Regular',Helvetica] font-normal text-[#87849b] text-[9.1px] tracking-[0] leading-[11.9px] whitespace-nowrap">
                                                                        $250,000
                                                                    </div>
                                                                    <div
                                                                        className="relative w-fit mt-[-0.51px] [font-family:'Aeonik-Regular',Helvetica] font-normal text-[#87849b] text-[9.1px] tracking-[0] leading-[11.9px] whitespace-nowrap">
                                                                        /
                                                                    </div>
                                                                    <div
                                                                        className="relative w-fit mt-[-0.51px] [font-family:'Aeonik-Regular',Helvetica] font-normal text-[#87849b] text-[9.1px] tracking-[0] leading-[11.9px] whitespace-nowrap">
                                                                        $500,000
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="relative w-[199.24px] h-[11.54px] rounded-[0.7px] border-[0.85px] border-solid border-[#87849b4c]">
                                                                <div className="relative w-[100px] h-3 rounded-[0.7px]">
                                                                    <img
                                                                        className="absolute w-[51px] h-2.5 top-px left-6"
                                                                        alt="Vector"
                                                                        src="/static/img/vector-1.svg"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="flex h-[22.54px] items-center justify-center gap-[6.09px] pt-0 pb-[0.7px] px-[13.7px] relative self-stretch w-full mb-[-1.00px] ml-[-1.00px] mr-[-1.00px] rounded-[1.01px] border-[0.85px] border-solid border-[#c2aee599]">
                                                            <div
                                                                className="relative w-fit [font-family:'Aeonik-Regular',Helvetica] font-normal text-[#c2aee5] text-[9.1px] text-center tracking-[0] leading-[9.1px] whitespace-nowrap">
                                                                Participate now
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <img
                                                className="absolute w-[360px] h-[181px] top-[115px] left-0"
                                                alt="Mask group"
                                                src="/static/img/mask-group-1.png"
                                            />
                                        </div>
                                    )}

                                    {((screenWidth >= 1200 && screenWidth < 1440) ||
                                        screenWidth >= 1440 ||
                                        (screenWidth >= 810 && screenWidth < 1200)) && (
                                        <>
                                            <div
                                                className="w-full flex self-stretch items-center gap-4 flex-[0_0_auto] relative">
                                                <img
                                                    className={`relative ${
                                                        screenWidth >= 810 && screenWidth < 1200
                                                            ? "w-[30.4px]"
                                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                ? "w-[38px]"
                                                                : ""
                                                    } ${
                                                        screenWidth >= 810 && screenWidth < 1200
                                                            ? "h-[30.4px]"
                                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                ? "h-[38px]"
                                                                : ""
                                                    }`}
                                                    alt="Decentralized"
                                                    src={
                                                        screenWidth >= 810 && screenWidth < 1200
                                                            ? "/static/img/decentralized-launchpad-icon-1.svg"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "/static/img/decentralized-launchpad-icon-2.svg"
                                                                : screenWidth >= 1440
                                                                    ? "/static/img/decentralized-launchpad-icon-3.svg"
                                                                    : undefined
                                                    }
                                                />
                                                <div
                                                    className="inline-flex items-center gap-2.5 flex-[0_0_auto] pt-0 pb-[3px] px-0 justify-center relative">
                                                    <div
                                                        className={`[font-family:'Aeonik-Medium',Helvetica] w-fit mt-[-1.00px] text-[#f4f4f6] relative font-medium whitespace-nowrap ${
                                                            screenWidth >= 810 && screenWidth < 1200
                                                                ? "tracking-[-0.22px]"
                                                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                    ? "tracking-[-0.28px]"
                                                                    : ""
                                                        } ${
                                                            screenWidth >= 810 && screenWidth < 1200
                                                                ? "text-[22px]"
                                                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                    ? "text-[28px]"
                                                                    : ""
                                                        } ${
                                                            screenWidth >= 810 && screenWidth < 1200
                                                                ? "leading-[22px]"
                                                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                    ? "leading-7"
                                                                    : ""
                                                        }`}
                                                    >
                                                        Decentralized launchpad
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="w-full flex self-stretch flex-col items-start gap-[34px] flex-[0_0_auto] justify-center relative">
                                                <p
                                                    className={`[font-family:'Aeonik-Regular',Helvetica] mt-[-1.00px] tracking-[0] text-lg text-[#9997a4] relative font-normal leading-[23.4px] ${
                                                        (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 ? "w-[468.05px]" : ""
                                                    } ${screenWidth >= 810 && screenWidth < 1200 ? "self-stretch" : ""}`}
                                                >
                                                    Access an optimized launchpad for raising funds for your AI and
                                                    blockchain projects
                                                    built on
                                                    Bittensor, with transparent management of investment phases.
                                                </p>
                                                <LaunchAProject className="!h-[40.5px]" property1="default"
                                                                text="Launch app"/>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div
                                    className={`relative ${screenWidth < 810 ? "w-full" : ""} ${screenWidth < 810 ? "flex" : ""} ${
                                        screenWidth < 810 ? "self-stretch" : ""
                                    } ${screenWidth < 810 ? "flex-col" : ""} ${screenWidth < 810 ? "items-start" : ""} ${
                                        (screenWidth >= 1200 && screenWidth < 1440) ||
                                        screenWidth >= 1440 ||
                                        (screenWidth >= 810 && screenWidth < 1200)
                                            ? "grow"
                                            : ""
                                    } ${screenWidth < 810 ? "gap-5" : ""} ${
                                        screenWidth < 810
                                            ? "flex-[0_0_auto]"
                                            : (screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)
                                                ? "flex-1"
                                                : ""
                                    } ${screenWidth < 810 ? "pt-[15px] pb-5 px-[15px]" : ""} ${
                                        screenWidth >= 810 && screenWidth < 1200
                                            ? "h-[296.02px]"
                                            : screenWidth >= 1200 && screenWidth < 1440
                                                ? "h-[370.03px]"
                                                : screenWidth >= 1440
                                                    ? "h-[450px]"
                                                    : ""
                                    } ${
                                        (screenWidth >= 1200 && screenWidth < 1440) ||
                                        screenWidth >= 1440 ||
                                        (screenWidth >= 810 && screenWidth < 1200)
                                            ? "overflow-hidden"
                                            : ""
                                    } ${
                                        screenWidth >= 810 && screenWidth < 1200
                                            ? "rounded-[3.2px]"
                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                ? "rounded"
                                                : ""
                                    } ${screenWidth < 810 ? "justify-center" : ""} ${
                                        screenWidth < 810
                                            ? "bg-[#100f12]"
                                            : (screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)
                                                ? "bg-[#070607]"
                                                : ""
                                    }`}
                                >
                                    {screenWidth < 810 && (
                                        <>
                                            <div
                                                className="flex items-center gap-4 relative self-stretch w-full flex-[0_0_auto]">
                                                <img
                                                    className="relative w-[30.4px] h-[30.4px]"
                                                    alt="Decentralized"
                                                    src="/static/img/decentralized-launchpad-icon.svg"
                                                />
                                                <div
                                                    className="inline-flex items-center justify-center gap-2.5 pt-0 pb-[3px] px-0 relative flex-[0_0_auto]">
                                                    <div
                                                        className="relative w-fit mt-[-1.00px] [font-family:'Aeonik-Medium',Helvetica] font-medium text-[#f4f4f6] text-[22px] tracking-[-0.22px] leading-[22px] whitespace-nowrap">
                                                        Decentralized launchpad
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="flex flex-col items-start justify-center gap-[34px] relative self-stretch w-full flex-[0_0_auto]">
                                                <p className="relative self-stretch mt-[-1.00px] [font-family:'Aeonik-Regular',Helvetica] font-normal text-[#9997a4] text-lg tracking-[0] leading-[23.4px]">
                                                    Access an optimized launchpad for raising funds for your AI and
                                                    blockchain projects
                                                    built on
                                                    Bittensor, with transparent management of investment phases.
                                                </p>
                                                <LaunchAProject className="!h-[40.5px]" property1="default"
                                                                text="Launch app"/>
                                            </div>
                                        </>
                                    )}

                                    {((screenWidth >= 1200 && screenWidth < 1440) ||
                                        screenWidth >= 1440 ||
                                        (screenWidth >= 810 && screenWidth < 1200)) && (
                                        <div
                                            className={`relative ${
                                                screenWidth >= 810 && screenWidth < 1200
                                                    ? "w-[458px]"
                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                        ? "w-[573px]"
                                                        : screenWidth >= 1440
                                                            ? "w-[697px]"
                                                            : ""
                                            } ${
                                                screenWidth >= 810 && screenWidth < 1200
                                                    ? "h-[296px]"
                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                        ? "h-[370px]"
                                                        : screenWidth >= 1440
                                                            ? "h-[450px]"
                                                            : ""
                                            }`}
                                        >
                                            <img
                                                className={`left-0 top-0 absolute ${
                                                    screenWidth >= 810 && screenWidth < 1200
                                                        ? "w-[382px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "w-[542px]"
                                                            : screenWidth >= 1440
                                                                ? "w-[660px]"
                                                                : ""
                                                } ${
                                                    screenWidth >= 810 && screenWidth < 1200
                                                        ? "h-[296px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "h-[370px]"
                                                            : screenWidth >= 1440
                                                                ? "h-[450px]"
                                                                : ""
                                                }`}
                                                alt="Mask group"
                                                src={
                                                    screenWidth >= 810 && screenWidth < 1200
                                                        ? "/static/img/mask-group-2.png"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "/static/img/mask-group-4.png"
                                                            : screenWidth >= 1440
                                                                ? "/static/img/mask-group-6.png"
                                                                : undefined
                                                }
                                            />
                                            <div
                                                className={`absolute ${
                                                    screenWidth >= 810 && screenWidth < 1200
                                                        ? "w-[448px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "w-[560px]"
                                                            : screenWidth >= 1440
                                                                ? "w-[681px]"
                                                                : ""
                                                } ${
                                                    screenWidth >= 810 && screenWidth < 1200
                                                        ? "left-2.5"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "left-[13px]"
                                                            : screenWidth >= 1440
                                                                ? "left-4"
                                                                : ""
                                                } ${
                                                    screenWidth >= 810 && screenWidth < 1200
                                                        ? "top-[11px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "top-3.5"
                                                            : screenWidth >= 1440
                                                                ? "top-[17px]"
                                                                : ""
                                                } ${
                                                    screenWidth >= 810 && screenWidth < 1200
                                                        ? "h-[273px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "h-[342px]"
                                                            : screenWidth >= 1440
                                                                ? "h-[416px]"
                                                                : ""
                                                }`}
                                            >
                                                <div
                                                    className={`left-0 flex flex-col items-start top-0 justify-center absolute ${
                                                        screenWidth >= 810 && screenWidth < 1200
                                                            ? "w-[220px]"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "w-[275px]"
                                                                : screenWidth >= 1440
                                                                    ? "w-[334px]"
                                                                    : ""
                                                    } ${
                                                        screenWidth >= 810 && screenWidth < 1200
                                                            ? "gap-[1.41px]"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "gap-[1.76px]"
                                                                : screenWidth >= 1440
                                                                    ? "gap-[2.14px]"
                                                                    : ""
                                                    } ${
                                                        screenWidth >= 810 && screenWidth < 1200
                                                            ? "h-[273.34px]"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "h-[341.67px]"
                                                                : screenWidth >= 1440
                                                                    ? "h-[415.52px]"
                                                                    : ""
                                                    }`}
                                                >
                                                    <div
                                                        className={`w-full self-stretch overflow-hidden bg-[#070607] relative ${
                                                            (screenWidth >= 1200 && screenWidth < 1440) || (screenWidth >= 810 && screenWidth < 1200)
                                                                ? "mt-[-0.01px]"
                                                                : ""
                                                        } ${
                                                            screenWidth >= 810 && screenWidth < 1200
                                                                ? "h-[101.96px]"
                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "h-[127.45px]"
                                                                    : screenWidth >= 1440
                                                                        ? "h-[155px]"
                                                                        : ""
                                                        } ${
                                                            screenWidth >= 810 && screenWidth < 1200
                                                                ? "rounded-[2.11px]"
                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "rounded-[2.64px]"
                                                                    : screenWidth >= 1440
                                                                        ? "rounded-[3.21px]"
                                                                        : ""
                                                        }`}
                                                    >
                                                        <div
                                                            className={`relative ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "w-[315px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "w-[394px]"
                                                                        : screenWidth >= 1440
                                                                            ? "w-[479px]"
                                                                            : ""
                                                            } ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "-left-12"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[-60px]"
                                                                        : screenWidth >= 1440
                                                                            ? "left-[-73px]"
                                                                            : ""
                                                            } ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "top-[-26px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "-top-8"
                                                                        : screenWidth >= 1440
                                                                            ? "top-[-39px]"
                                                                            : ""
                                                            } ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "h-[153px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "h-48"
                                                                        : screenWidth >= 1440
                                                                            ? "h-[233px]"
                                                                            : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`left-0 opacity-30 top-0 absolute ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "w-[315px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "w-[394px]"
                                                                            : screenWidth >= 1440
                                                                                ? "w-[479px]"
                                                                                : ""
                                                                } ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "h-[153px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "h-48"
                                                                            : screenWidth >= 1440
                                                                                ? "h-[233px]"
                                                                                : ""
                                                                }`}
                                                            >
                                                                <div
                                                                    className={`relative ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "w-[220px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "w-[275px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "w-[334px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "left-12"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "left-[60px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "left-[73px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "top-[25px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "top-8"
                                                                                : screenWidth >= 1440
                                                                                    ? "top-[38px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "h-[104px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "h-32"
                                                                                : screenWidth >= 1440
                                                                                    ? "h-[156px]"
                                                                                    : ""
                                                                    }`}
                                                                >
                                                                    <div
                                                                        className={`border-[#575565] opacity-45 top-0 absolute ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "border-[0.85px] border-dashed"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "border-[1.06px] border-dashed"
                                                                                    : screenWidth >= 1440
                                                                                        ? "border-[1.29px] border-dashed"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "w-[104px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "w-32"
                                                                                    : screenWidth >= 1440
                                                                                        ? "w-[156px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "left-[58px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "left-[73px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "left-[89px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "h-[104px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "h-32"
                                                                                    : screenWidth >= 1440
                                                                                        ? "h-[156px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "rounded-[51.97px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "rounded-[64.24px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "rounded-[78.13px]"
                                                                                        : ""
                                                                        }`}
                                                                    />
                                                                    <img
                                                                        className={`w-px top-px absolute ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "left-[109px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "left-[137px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "left-[166px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "h-[102px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "h-[127px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "h-[155px]"
                                                                                        : ""
                                                                        }`}
                                                                        alt="Vector"
                                                                        src={
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "/static/img/vector-1114-2.svg"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "/static/img/vector-1114-4.svg"
                                                                                    : screenWidth >= 1440
                                                                                        ? "/static/img/vector-1114-7.svg"
                                                                                        : undefined
                                                                        }
                                                                    />
                                                                    <img
                                                                        className={`left-0 h-px absolute ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "w-[220px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "w-[275px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "w-[334px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "top-[52px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "top-16"
                                                                                    : screenWidth >= 1440
                                                                                        ? "top-[77px]"
                                                                                        : ""
                                                                        }`}
                                                                        alt="Vector"
                                                                        src={
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "/static/img/vector-1115-2.svg"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "/static/img/vector-1115-4.svg"
                                                                                    : screenWidth >= 1440
                                                                                        ? "/static/img/vector-1115-6.svg"
                                                                                        : undefined
                                                                        }
                                                                    />
                                                                    <img
                                                                        className={`left-0 top-px absolute ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "w-[220px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "w-[275px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "w-[334px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "h-[102px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "h-[127px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "h-[155px]"
                                                                                        : ""
                                                                        }`}
                                                                        alt="Vector"
                                                                        src={
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "/static/img/vector-1116-2.svg"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "/static/img/vector-1116-4.svg"
                                                                                    : screenWidth >= 1440
                                                                                        ? "/static/img/vector-1116-6.svg"
                                                                                        : undefined
                                                                        }
                                                                    />
                                                                    <img
                                                                        className={`left-0 top-px absolute ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "w-[220px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "w-[275px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "w-[334px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "h-[102px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "h-[127px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "h-[155px]"
                                                                                        : ""
                                                                        }`}
                                                                        alt="Vector"
                                                                        src={
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "/static/img/vector-1117-2.svg"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "/static/img/vector-1117-4.svg"
                                                                                    : screenWidth >= 1440
                                                                                        ? "/static/img/vector-1117-6.svg"
                                                                                        : undefined
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <img
                                                                className={`absolute ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "w-[42px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "w-[52px]"
                                                                            : screenWidth >= 1440
                                                                                ? "w-16"
                                                                                : ""
                                                                } ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "left-[57px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "left-[72px]"
                                                                            : screenWidth >= 1440
                                                                                ? "left-[87px]"
                                                                                : ""
                                                                } ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "top-[76px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "top-[95px]"
                                                                            : screenWidth >= 1440
                                                                                ? "top-[115px]"
                                                                                : ""
                                                                } ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "h-[42px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "h-[52px]"
                                                                            : screenWidth >= 1440
                                                                                ? "h-16"
                                                                                : ""
                                                                }`}
                                                                alt="Frame"
                                                                src={
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "/static/img/frame-1618869820-2.svg"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "/static/img/frame-1618869820-4.svg"
                                                                            : screenWidth >= 1440
                                                                                ? "/static/img/frame-1618869820-6.svg"
                                                                                : undefined
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`w-full flex self-stretch flex-col items-start flex-[0_0_auto] overflow-hidden justify-center bg-[#100f12] relative ${
                                                            screenWidth >= 810 && screenWidth < 1200
                                                                ? "gap-[26.07px]"
                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "gap-[32.58px]"
                                                                    : screenWidth >= 1440
                                                                        ? "gap-[39.63px]"
                                                                        : ""
                                                        } ${
                                                            screenWidth >= 810 && screenWidth < 1200
                                                                ? "p-[10.15px]"
                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "p-[12.68px]"
                                                                    : screenWidth >= 1440
                                                                        ? "p-[15.42px]"
                                                                        : ""
                                                        } ${
                                                            (screenWidth >= 1200 && screenWidth < 1440) || (screenWidth >= 810 && screenWidth < 1200)
                                                                ? "mb-[-0.01px]"
                                                                : ""
                                                        } ${
                                                            screenWidth >= 810 && screenWidth < 1200
                                                                ? "rounded-[2.11px]"
                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "rounded-[2.64px]"
                                                                    : screenWidth >= 1440
                                                                        ? "rounded-[3.21px]"
                                                                        : ""
                                                        }`}
                                                    >
                                                        <div
                                                            className={`w-full flex self-stretch flex-col items-start flex-[0_0_auto] relative ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "gap-[10.15px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "gap-[12.68px]"
                                                                        : screenWidth >= 1440
                                                                            ? "gap-[15.42px]"
                                                                            : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`inline-flex items-start flex-[0_0_auto] relative ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "gap-[7.61px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "gap-[9.51px]"
                                                                            : screenWidth >= 1440
                                                                                ? "gap-[11.57px]"
                                                                                : ""
                                                                }`}
                                                            >
                                                                <div
                                                                    className={`opacity-20 bg-[#87849b] relative ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "w-[67.65px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "w-[84.56px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "w-[102.84px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "h-[11.54px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "h-[14.42px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "h-[17.54px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "rounded-[0.7px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "rounded-[0.88px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "rounded-[1.07px]"
                                                                                    : ""
                                                                    }`}
                                                                />
                                                            </div>
                                                            <div
                                                                className={`w-full flex self-stretch flex-col items-start flex-[0_0_auto] relative ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "gap-[5.64px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "gap-[7.05px]"
                                                                            : screenWidth >= 1440
                                                                                ? "gap-[8.57px]"
                                                                                : ""
                                                                }`}
                                                            >
                                                                <div
                                                                    className={`opacity-20 bg-[#87849b] relative ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "w-[150.46px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "w-[188.07px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "w-[228.72px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "h-[3.46px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "h-[4.33px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "h-[5.26px]"
                                                                                    : ""
                                                                    }`}
                                                                />
                                                                <div
                                                                    className={`opacity-20 bg-[#87849b] relative ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "w-[179.27px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "w-[224.09px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "w-[272.52px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "h-[3.46px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "h-[4.33px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "h-[5.26px]"
                                                                                    : ""
                                                                    }`}
                                                                />
                                                                <div
                                                                    className={`opacity-20 bg-[#87849b] relative ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "w-[131.9px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "w-[164.87px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "w-[200.5px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "h-[3.46px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "h-[4.33px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "h-[5.26px]"
                                                                                    : ""
                                                                    }`}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`w-full flex self-stretch flex-col items-start flex-[0_0_auto] relative ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "gap-[10.15px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "gap-[12.68px]"
                                                                        : screenWidth >= 1440
                                                                            ? "gap-[15.42px]"
                                                                            : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className="w-full flex self-stretch items-center flex-[0_0_auto] justify-between relative">
                                                                <div
                                                                    className={`[font-family:'Aeonik-Regular',Helvetica] w-fit tracking-[0] text-[#87849b] relative font-normal whitespace-nowrap ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "mt-[-0.51px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "mt-[-0.63px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "mt-[-0.77px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "text-[9.1px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "text-[11.4px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "text-[13.9px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "leading-[11.9px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "leading-[14.8px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "leading-[18.0px]"
                                                                                    : ""
                                                                    }`}
                                                                >
                                                                    Total Raised
                                                                </div>
                                                                <div
                                                                    className={`inline-flex items-center flex-[0_0_auto] relative ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "gap-[6.09px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "gap-[7.61px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "gap-[9.25px]"
                                                                                    : ""
                                                                    }`}
                                                                >
                                                                    <div
                                                                        className={`[font-family:'Aeonik-Regular',Helvetica] w-fit tracking-[0] text-[#87849b] relative font-normal whitespace-nowrap ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "mt-[-0.51px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "mt-[-0.63px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "mt-[-0.77px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "text-[9.1px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "text-[11.4px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "text-[13.9px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "leading-[11.9px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "leading-[14.8px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "leading-[18.0px]"
                                                                                        : ""
                                                                        }`}
                                                                    >
                                                                        $200,000
                                                                    </div>
                                                                    <div
                                                                        className={`[font-family:'Aeonik-Regular',Helvetica] w-fit tracking-[0] text-[#87849b] relative font-normal whitespace-nowrap ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "mt-[-0.51px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "mt-[-0.63px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "mt-[-0.77px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "text-[9.1px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "text-[11.4px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "text-[13.9px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "leading-[11.9px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "leading-[14.8px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "leading-[18.0px]"
                                                                                        : ""
                                                                        }`}
                                                                    >
                                                                        /
                                                                    </div>
                                                                    <div
                                                                        className={`[font-family:'Aeonik-Regular',Helvetica] w-fit tracking-[0] text-[#87849b] relative font-normal whitespace-nowrap ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "mt-[-0.51px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "mt-[-0.63px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "mt-[-0.77px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "text-[9.1px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "text-[11.4px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "text-[13.9px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "leading-[11.9px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "leading-[14.8px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "leading-[18.0px]"
                                                                                        : ""
                                                                        }`}
                                                                    >
                                                                        $250,000
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={`border-[#87849b4c] relative ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "border-[0.85px] border-solid"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "border-[1.06px] border-solid"
                                                                            : screenWidth >= 1440
                                                                                ? "border-[1.29px] border-solid"
                                                                                : ""
                                                                } ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "w-[199.24px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "w-[249.06px]"
                                                                            : screenWidth >= 1440
                                                                                ? "w-[302.88px]"
                                                                                : ""
                                                                } ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "h-[11.54px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "h-[14.42px]"
                                                                            : screenWidth >= 1440
                                                                                ? "h-[17.54px]"
                                                                                : ""
                                                                } ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "rounded-[0.7px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "rounded-[0.88px]"
                                                                            : screenWidth >= 1440
                                                                                ? "rounded-[1.07px]"
                                                                                : ""
                                                                }`}
                                                            >
                                                                <div
                                                                    className={`relative ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "w-[159px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "w-[199px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "w-[242px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "h-3"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "h-3.5"
                                                                                : screenWidth >= 1440
                                                                                    ? "h-[18px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "rounded-[0.7px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "rounded-[0.88px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "rounded-[1.07px]"
                                                                                    : ""
                                                                    }`}
                                                                >
                                                                    <img
                                                                        className={`top-px absolute ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "w-[107px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "w-[134px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "w-[163px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "left-[26px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "left-[33px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "left-10"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "h-2.5"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "h-[13px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "h-[15px]"
                                                                                        : ""
                                                                        }`}
                                                                        alt="Vector"
                                                                        src={
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "/static/img/vector-4.svg"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "/static/img/vector-8.svg"
                                                                                    : screenWidth >= 1440
                                                                                        ? "/static/img/vector-12.svg"
                                                                                        : undefined
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`border-[#c2aee599] w-full flex self-stretch items-center justify-center relative ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "border-[0.85px] border-solid"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "border-[1.06px] border-solid"
                                                                        : screenWidth >= 1440
                                                                            ? "border-[1.29px] border-solid"
                                                                            : ""
                                                            } ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "mr-[-1.00px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "mr-[-0.53px]"
                                                                        : screenWidth >= 1440
                                                                            ? "mr-[-0.64px]"
                                                                            : ""
                                                            } ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "gap-[6.09px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "gap-[7.61px]"
                                                                        : screenWidth >= 1440
                                                                            ? "gap-[9.25px]"
                                                                            : ""
                                                            } ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "ml-[-1.00px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "ml-[-0.53px]"
                                                                        : screenWidth >= 1440
                                                                            ? "ml-[-0.64px]"
                                                                            : ""
                                                            } ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "pt-0 pb-[0.7px] px-[13.7px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "pt-0 pb-[0.88px] px-[17.12px]"
                                                                        : screenWidth >= 1440
                                                                            ? "pt-0 pb-[1.07px] px-[20.82px]"
                                                                            : ""
                                                            } ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "h-[22.54px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "h-[26.74px]"
                                                                        : screenWidth >= 1440
                                                                            ? "h-[32.52px]"
                                                                            : ""
                                                            } ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "rounded-[1.01px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "rounded-[1.27px]"
                                                                        : screenWidth >= 1440
                                                                            ? "rounded-[1.54px]"
                                                                            : ""
                                                            } ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "mb-[-1.00px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "mb-[-0.53px]"
                                                                        : screenWidth >= 1440
                                                                            ? "mb-[-0.64px]"
                                                                            : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`[font-family:'Aeonik-Regular',Helvetica] w-fit tracking-[0] text-[#c2aee5] relative font-normal text-center whitespace-nowrap ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "text-[9.1px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "text-[11.4px]"
                                                                            : screenWidth >= 1440
                                                                                ? "text-[13.9px]"
                                                                                : ""
                                                                } ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "leading-[9.1px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "leading-[11.4px]"
                                                                            : screenWidth >= 1440
                                                                                ? "leading-[13.9px]"
                                                                                : ""
                                                                }`}
                                                            >
                                                                Participate now
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`flex flex-col items-start top-0 justify-center absolute ${
                                                        screenWidth >= 810 && screenWidth < 1200
                                                            ? "w-[220px]"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "w-[275px]"
                                                                : screenWidth >= 1440
                                                                    ? "w-[334px]"
                                                                    : ""
                                                    } ${
                                                        screenWidth >= 810 && screenWidth < 1200
                                                            ? "left-[228px]"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "left-[285px]"
                                                                : screenWidth >= 1440
                                                                    ? "left-[347px]"
                                                                    : ""
                                                    } ${
                                                        screenWidth >= 810 && screenWidth < 1200
                                                            ? "gap-[1.41px]"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "gap-[1.76px]"
                                                                : screenWidth >= 1440
                                                                    ? "gap-[2.14px]"
                                                                    : ""
                                                    } ${
                                                        screenWidth >= 810 && screenWidth < 1200
                                                            ? "h-[273.34px]"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "h-[341.67px]"
                                                                : screenWidth >= 1440
                                                                    ? "h-[415.52px]"
                                                                    : ""
                                                    }`}
                                                >
                                                    <div
                                                        className={`w-full self-stretch overflow-hidden bg-[#070607] relative ${
                                                            (screenWidth >= 1200 && screenWidth < 1440) || (screenWidth >= 810 && screenWidth < 1200)
                                                                ? "mt-[-0.01px]"
                                                                : ""
                                                        } ${
                                                            screenWidth >= 810 && screenWidth < 1200
                                                                ? "h-[101.96px]"
                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "h-[127.45px]"
                                                                    : screenWidth >= 1440
                                                                        ? "h-[155px]"
                                                                        : ""
                                                        } ${
                                                            screenWidth >= 810 && screenWidth < 1200
                                                                ? "rounded-[2.11px]"
                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "rounded-[2.64px]"
                                                                    : screenWidth >= 1440
                                                                        ? "rounded-[3.21px]"
                                                                        : ""
                                                        }`}
                                                    >
                                                        <div
                                                            className={`relative ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "w-[315px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "w-[394px]"
                                                                        : screenWidth >= 1440
                                                                            ? "w-[479px]"
                                                                            : ""
                                                            } ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "-left-12"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[-60px]"
                                                                        : screenWidth >= 1440
                                                                            ? "left-[-73px]"
                                                                            : ""
                                                            } ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "top-[-26px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "-top-8"
                                                                        : screenWidth >= 1440
                                                                            ? "top-[-39px]"
                                                                            : ""
                                                            } ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "h-[153px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "h-48"
                                                                        : screenWidth >= 1440
                                                                            ? "h-[233px]"
                                                                            : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`left-0 opacity-30 top-0 absolute ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "w-[315px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "w-[394px]"
                                                                            : screenWidth >= 1440
                                                                                ? "w-[479px]"
                                                                                : ""
                                                                } ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "h-[153px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "h-48"
                                                                            : screenWidth >= 1440
                                                                                ? "h-[233px]"
                                                                                : ""
                                                                }`}
                                                            >
                                                                <div
                                                                    className={`relative ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "w-[162px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "w-[243px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "w-[297px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "left-12"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "left-[60px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "left-[73px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "top-[25px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "top-8"
                                                                                : screenWidth >= 1440
                                                                                    ? "top-[38px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "h-[104px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "h-32"
                                                                                : screenWidth >= 1440
                                                                                    ? "h-[156px]"
                                                                                    : ""
                                                                    }`}
                                                                >
                                                                    <div
                                                                        className={`border-[#575565] opacity-45 top-0 absolute ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "border-[0.85px] border-dashed"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "border-[1.06px] border-dashed"
                                                                                    : screenWidth >= 1440
                                                                                        ? "border-[1.29px] border-dashed"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "w-[104px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "w-32"
                                                                                    : screenWidth >= 1440
                                                                                        ? "w-[156px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "left-[58px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "left-[73px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "left-[89px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "h-[104px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "h-32"
                                                                                    : screenWidth >= 1440
                                                                                        ? "h-[156px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "rounded-[51.97px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "rounded-[64.24px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "rounded-[78.13px]"
                                                                                        : ""
                                                                        }`}
                                                                    />
                                                                    <img
                                                                        className={`w-px top-px absolute ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "left-[109px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "left-[137px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "left-[166px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "h-[102px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "h-[127px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "h-[155px]"
                                                                                        : ""
                                                                        }`}
                                                                        alt="Vector"
                                                                        src={
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "/static/img/vector-1114-3.svg"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "/static/img/vector-1114-5.svg"
                                                                                    : screenWidth >= 1440
                                                                                        ? "/static/img/vector-1114-7.svg"
                                                                                        : undefined
                                                                        }
                                                                    />
                                                                    <img
                                                                        className={`left-0 h-px absolute ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "w-[143px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "w-[243px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "w-[297px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "top-[52px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "top-16"
                                                                                    : screenWidth >= 1440
                                                                                        ? "top-[77px]"
                                                                                        : ""
                                                                        }`}
                                                                        alt="Vector"
                                                                        src={
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "/static/img/vector-1115-3.svg"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "/static/img/vector-1115-5.svg"
                                                                                    : screenWidth >= 1440
                                                                                        ? "/static/img/vector-1115-7.svg"
                                                                                        : undefined
                                                                        }
                                                                    />
                                                                    <img
                                                                        className={`left-0 top-px absolute ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "w-[143px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "w-[243px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "w-[297px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "h-[102px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "h-[127px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "h-[155px]"
                                                                                        : ""
                                                                        }`}
                                                                        alt="Vector"
                                                                        src={
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "/static/img/vector-1116-3.svg"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "/static/img/vector-1116-5.svg"
                                                                                    : screenWidth >= 1440
                                                                                        ? "/static/img/vector-1116-7.svg"
                                                                                        : undefined
                                                                        }
                                                                    />
                                                                    <img
                                                                        className={`left-0 top-px absolute ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "w-[143px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "w-[243px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "w-[297px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "h-[102px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "h-[127px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "h-[155px]"
                                                                                        : ""
                                                                        }`}
                                                                        alt="Vector"
                                                                        src={
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "/static/img/vector-1117-3.svg"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "/static/img/vector-1117-5.svg"
                                                                                    : screenWidth >= 1440
                                                                                        ? "/static/img/vector-1117-7.svg"
                                                                                        : undefined
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <img
                                                                className={`absolute ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "w-[42px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "w-[52px]"
                                                                            : screenWidth >= 1440
                                                                                ? "w-16"
                                                                                : ""
                                                                } ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "left-[57px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "left-[72px]"
                                                                            : screenWidth >= 1440
                                                                                ? "left-[87px]"
                                                                                : ""
                                                                } ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "top-[76px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "top-[95px]"
                                                                            : screenWidth >= 1440
                                                                                ? "top-[115px]"
                                                                                : ""
                                                                } ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "h-[42px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "h-[52px]"
                                                                            : screenWidth >= 1440
                                                                                ? "h-16"
                                                                                : ""
                                                                }`}
                                                                alt="Frame"
                                                                src={
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "/static/img/frame-1618869820-3.svg"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "/static/img/frame-1618869820-5.svg"
                                                                            : screenWidth >= 1440
                                                                                ? "/static/img/frame-1618869820-7.svg"
                                                                                : undefined
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`w-full flex self-stretch flex-col items-start flex-[0_0_auto] overflow-hidden justify-center bg-[#100f12] relative ${
                                                            screenWidth >= 810 && screenWidth < 1200
                                                                ? "gap-[26.07px]"
                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "gap-[32.58px]"
                                                                    : screenWidth >= 1440
                                                                        ? "gap-[39.63px]"
                                                                        : ""
                                                        } ${
                                                            screenWidth >= 810 && screenWidth < 1200
                                                                ? "p-[10.15px]"
                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "p-[12.68px]"
                                                                    : screenWidth >= 1440
                                                                        ? "p-[15.42px]"
                                                                        : ""
                                                        } ${
                                                            (screenWidth >= 1200 && screenWidth < 1440) || (screenWidth >= 810 && screenWidth < 1200)
                                                                ? "mb-[-0.01px]"
                                                                : ""
                                                        } ${
                                                            screenWidth >= 810 && screenWidth < 1200
                                                                ? "rounded-[2.11px]"
                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "rounded-[2.64px]"
                                                                    : screenWidth >= 1440
                                                                        ? "rounded-[3.21px]"
                                                                        : ""
                                                        }`}
                                                    >
                                                        <div
                                                            className={`w-full flex self-stretch flex-col items-start flex-[0_0_auto] relative ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "gap-[10.15px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "gap-[12.68px]"
                                                                        : screenWidth >= 1440
                                                                            ? "gap-[15.42px]"
                                                                            : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`inline-flex items-start flex-[0_0_auto] relative ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "gap-[7.61px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "gap-[9.51px]"
                                                                            : screenWidth >= 1440
                                                                                ? "gap-[11.57px]"
                                                                                : ""
                                                                }`}
                                                            >
                                                                <div
                                                                    className={`opacity-20 bg-[#87849b] relative ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "w-[35.37px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "w-[44.21px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "w-[53.77px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "h-[11.54px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "h-[14.42px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "h-[17.54px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "rounded-[0.7px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "rounded-[0.88px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "rounded-[1.07px]"
                                                                                    : ""
                                                                    }`}
                                                                />
                                                            </div>
                                                            <div
                                                                className={`w-full flex self-stretch flex-col items-start flex-[0_0_auto] relative ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "gap-[5.64px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "gap-[7.05px]"
                                                                            : screenWidth >= 1440
                                                                                ? "gap-[8.57px]"
                                                                                : ""
                                                                }`}
                                                            >
                                                                <div
                                                                    className={`opacity-20 bg-[#87849b] relative ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "w-[176.31px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "w-[220.38px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "w-[268.02px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "h-[3.46px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "h-[4.33px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "h-[5.26px]"
                                                                                    : ""
                                                                    }`}
                                                                />
                                                                <div
                                                                    className={`opacity-20 bg-[#87849b] relative ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "w-[121.77px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "w-[152.22px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "w-[185.12px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "h-[3.46px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "h-[4.33px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "h-[5.26px]"
                                                                                    : ""
                                                                    }`}
                                                                />
                                                                <div
                                                                    className={`opacity-20 bg-[#87849b] relative ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "w-[148.62px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "w-[185.78px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "w-[225.93px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "h-[3.46px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "h-[4.33px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "h-[5.26px]"
                                                                                    : ""
                                                                    }`}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`w-full flex self-stretch flex-col items-start flex-[0_0_auto] relative ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "gap-[10.15px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "gap-[12.68px]"
                                                                        : screenWidth >= 1440
                                                                            ? "gap-[15.42px]"
                                                                            : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className="w-full flex self-stretch items-center flex-[0_0_auto] justify-between relative">
                                                                <div
                                                                    className={`[font-family:'Aeonik-Regular',Helvetica] w-fit tracking-[0] text-[#87849b] relative font-normal whitespace-nowrap ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "mt-[-0.51px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "mt-[-0.63px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "mt-[-0.77px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "text-[9.1px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "text-[11.4px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "text-[13.9px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "leading-[11.9px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "leading-[14.8px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "leading-[18.0px]"
                                                                                    : ""
                                                                    }`}
                                                                >
                                                                    Total Raised
                                                                </div>
                                                                <div
                                                                    className={`inline-flex items-center flex-[0_0_auto] relative ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "gap-[6.09px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "gap-[7.61px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "gap-[9.25px]"
                                                                                    : ""
                                                                    }`}
                                                                >
                                                                    <div
                                                                        className={`[font-family:'Aeonik-Regular',Helvetica] w-fit tracking-[0] text-[#87849b] relative font-normal whitespace-nowrap ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "mt-[-0.51px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "mt-[-0.63px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "mt-[-0.77px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "text-[9.1px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "text-[11.4px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "text-[13.9px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "leading-[11.9px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "leading-[14.8px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "leading-[18.0px]"
                                                                                        : ""
                                                                        }`}
                                                                    >
                                                                        $250,000
                                                                    </div>
                                                                    <div
                                                                        className={`[font-family:'Aeonik-Regular',Helvetica] w-fit tracking-[0] text-[#87849b] relative font-normal whitespace-nowrap ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "mt-[-0.51px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "mt-[-0.63px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "mt-[-0.77px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "text-[9.1px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "text-[11.4px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "text-[13.9px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "leading-[11.9px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "leading-[14.8px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "leading-[18.0px]"
                                                                                        : ""
                                                                        }`}
                                                                    >
                                                                        /
                                                                    </div>
                                                                    <div
                                                                        className={`[font-family:'Aeonik-Regular',Helvetica] w-fit tracking-[0] text-[#87849b] relative font-normal whitespace-nowrap ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "mt-[-0.51px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "mt-[-0.63px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "mt-[-0.77px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "text-[9.1px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "text-[11.4px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "text-[13.9px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "leading-[11.9px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "leading-[14.8px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "leading-[18.0px]"
                                                                                        : ""
                                                                        }`}
                                                                    >
                                                                        $500,000
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={`border-[#87849b4c] relative ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "border-[0.85px] border-solid"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "border-[1.06px] border-solid"
                                                                            : screenWidth >= 1440
                                                                                ? "border-[1.29px] border-solid"
                                                                                : ""
                                                                } ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "w-[199.24px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "w-[249.06px]"
                                                                            : screenWidth >= 1440
                                                                                ? "w-[302.88px]"
                                                                                : ""
                                                                } ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "h-[11.54px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "h-[14.42px]"
                                                                            : screenWidth >= 1440
                                                                                ? "h-[17.54px]"
                                                                                : ""
                                                                } ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "rounded-[0.7px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "rounded-[0.88px]"
                                                                            : screenWidth >= 1440
                                                                                ? "rounded-[1.07px]"
                                                                                : ""
                                                                }`}
                                                            >
                                                                <div
                                                                    className={`relative ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "w-[100px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "w-[125px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "w-[152px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "h-3"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "h-3.5"
                                                                                : screenWidth >= 1440
                                                                                    ? "h-[18px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth >= 810 && screenWidth < 1200
                                                                            ? "rounded-[0.7px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "rounded-[0.88px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "rounded-[1.07px]"
                                                                                    : ""
                                                                    }`}
                                                                >
                                                                    <img
                                                                        className={`top-px absolute ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "w-[51px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "w-16"
                                                                                    : screenWidth >= 1440
                                                                                        ? "w-[78px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "left-6"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "left-[30px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "left-[37px]"
                                                                                        : ""
                                                                        } ${
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "h-2.5"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "h-[13px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "h-[15px]"
                                                                                        : ""
                                                                        }`}
                                                                        alt="Vector"
                                                                        src={
                                                                            screenWidth >= 810 && screenWidth < 1200
                                                                                ? "/static/img/vector-5.svg"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "/static/img/vector-9.svg"
                                                                                    : screenWidth >= 1440
                                                                                        ? "/static/img/vector-13.svg"
                                                                                        : undefined
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`border-[#c2aee599] w-full flex self-stretch items-center justify-center relative ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "border-[0.85px] border-solid"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "border-[1.06px] border-solid"
                                                                        : screenWidth >= 1440
                                                                            ? "border-[1.29px] border-solid"
                                                                            : ""
                                                            } ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "mr-[-1.00px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "mr-[-0.53px]"
                                                                        : screenWidth >= 1440
                                                                            ? "mr-[-0.64px]"
                                                                            : ""
                                                            } ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "gap-[6.09px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "gap-[7.61px]"
                                                                        : screenWidth >= 1440
                                                                            ? "gap-[9.25px]"
                                                                            : ""
                                                            } ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "ml-[-1.00px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "ml-[-0.53px]"
                                                                        : screenWidth >= 1440
                                                                            ? "ml-[-0.64px]"
                                                                            : ""
                                                            } ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "pt-0 pb-[0.7px] px-[13.7px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "pt-0 pb-[0.88px] px-[17.12px]"
                                                                        : screenWidth >= 1440
                                                                            ? "pt-0 pb-[1.07px] px-[20.82px]"
                                                                            : ""
                                                            } ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "h-[22.54px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "h-[26.74px]"
                                                                        : screenWidth >= 1440
                                                                            ? "h-[32.52px]"
                                                                            : ""
                                                            } ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "rounded-[1.01px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "rounded-[1.27px]"
                                                                        : screenWidth >= 1440
                                                                            ? "rounded-[1.54px]"
                                                                            : ""
                                                            } ${
                                                                screenWidth >= 810 && screenWidth < 1200
                                                                    ? "mb-[-1.00px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "mb-[-0.53px]"
                                                                        : screenWidth >= 1440
                                                                            ? "mb-[-0.64px]"
                                                                            : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`[font-family:'Aeonik-Regular',Helvetica] w-fit tracking-[0] text-[#c2aee5] relative font-normal text-center whitespace-nowrap ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "text-[9.1px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "text-[11.4px]"
                                                                            : screenWidth >= 1440
                                                                                ? "text-[13.9px]"
                                                                                : ""
                                                                } ${
                                                                    screenWidth >= 810 && screenWidth < 1200
                                                                        ? "leading-[9.1px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "leading-[11.4px]"
                                                                            : screenWidth >= 1440
                                                                                ? "leading-[13.9px]"
                                                                                : ""
                                                                }`}
                                                            >
                                                                Participate now
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <img
                                                className={`left-0 absolute ${
                                                    screenWidth >= 810 && screenWidth < 1200
                                                        ? "w-[382px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "w-[542px]"
                                                            : screenWidth >= 1440
                                                                ? "w-[660px]"
                                                                : ""
                                                } ${
                                                    screenWidth >= 810 && screenWidth < 1200
                                                        ? "top-[115px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "top-[143px]"
                                                            : screenWidth >= 1440
                                                                ? "top-[174px]"
                                                                : ""
                                                } ${
                                                    screenWidth >= 810 && screenWidth < 1200
                                                        ? "h-[181px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "h-[227px]"
                                                            : screenWidth >= 1440
                                                                ? "h-[276px]"
                                                                : ""
                                                }`}
                                                alt="Mask group"
                                                src={
                                                    screenWidth >= 810 && screenWidth < 1200
                                                        ? "/static/img/mask-group-3.png"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "/static/img/mask-group-5.png"
                                                            : screenWidth >= 1440
                                                                ? "/static/img/mask-group-7.png"
                                                                : undefined
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className={`w-full flex self-stretch items-start flex-[0_0_auto] relative ${
                                    screenWidth < 810 ? "flex-col" : ""
                                } ${
                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                        ? "gap-2.5"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "gap-[7px]"
                                            : ""
                                }`}
                            >
                                <div
                                    className={`flex flex-col relative ${screenWidth < 810 ? "w-full" : ""} ${
                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810 ? "self-stretch" : ""
                                    } ${
                                        (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                            ? "items-start"
                                            : screenWidth >= 810 && screenWidth < 1200
                                                ? "items-center"
                                                : ""
                                    } ${
                                        (screenWidth >= 1200 && screenWidth < 1440) ||
                                        screenWidth >= 1440 ||
                                        (screenWidth >= 810 && screenWidth < 1200)
                                            ? "grow"
                                            : ""
                                    } ${
                                        screenWidth < 810
                                            ? "flex-[0_0_auto]"
                                            : (screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)
                                                ? "flex-1"
                                                : ""
                                    } ${
                                        (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                            ? "justify-center"
                                            : ""
                                    }`}
                                >
                                    <div
                                        className={`w-full self-stretch overflow-hidden bg-[#100f12] relative ${
                                            screenWidth < 810
                                                ? "h-[207px]"
                                                : screenWidth >= 810 && screenWidth < 1200
                                                    ? "h-[199.4px]"
                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                        ? "h-[277.11px]"
                                                        : screenWidth >= 1440
                                                            ? "h-[337px]"
                                                            : ""
                                        } ${
                                            (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                                ? "rounded-[4px_4px_0px_0px]"
                                                : screenWidth >= 810 && screenWidth < 1200
                                                    ? "rounded-[2.88px_2.88px_0px_0px]"
                                                    : ""
                                        }`}
                                    >
                                        <div
                                            className={`relative ${
                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                    ? "w-[231px]"
                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                        ? "w-[321px]"
                                                        : screenWidth >= 1440
                                                            ? "w-[390px]"
                                                            : ""
                                            } ${
                                                screenWidth < 810
                                                    ? "left-[65px]"
                                                    : screenWidth >= 810 && screenWidth < 1200
                                                        ? "left-[75px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "left-[109px]"
                                                            : screenWidth >= 1440
                                                                ? "left-[134px]"
                                                                : ""
                                            } ${
                                                screenWidth < 810
                                                    ? "top-[43px]"
                                                    : screenWidth >= 810 && screenWidth < 1200
                                                        ? "top-[39px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "top-[55px]"
                                                            : screenWidth >= 1440
                                                                ? "top-[66px]"
                                                                : ""
                                            } ${
                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                    ? "h-[121px]"
                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                        ? "h-[168px]"
                                                        : screenWidth >= 1440
                                                            ? "h-[204px]"
                                                            : ""
                                            }`}
                                        >
                                            <div
                                                className={`relative ${
                                                    (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                        ? "h-[121px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "h-[168px]"
                                                            : screenWidth >= 1440
                                                                ? "h-[204px]"
                                                                : ""
                                                }`}
                                            >
                                                <div
                                                    className={`left-0 top-0 absolute ${
                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                            ? "w-[121px]"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "w-[168px]"
                                                                : screenWidth >= 1440
                                                                    ? "w-[204px]"
                                                                    : ""
                                                    } ${
                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                            ? "h-[121px]"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "h-[168px]"
                                                                : screenWidth >= 1440
                                                                    ? "h-[204px]"
                                                                    : ""
                                                    }`}
                                                >
                                                    <div
                                                        className={`relative ${
                                                            (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                ? "h-[121px]"
                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "h-[168px]"
                                                                    : screenWidth >= 1440
                                                                        ? "h-[204px]"
                                                                        : ""
                                                        }`}
                                                    >
                                                        <div
                                                            className={`left-0 top-0 absolute ${
                                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                    ? "w-[121px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "w-[168px]"
                                                                        : screenWidth >= 1440
                                                                            ? "w-[204px]"
                                                                            : ""
                                                            } ${
                                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                    ? "h-[121px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "h-[168px]"
                                                                        : screenWidth >= 1440
                                                                            ? "h-[204px]"
                                                                            : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`relative ${
                                                                    (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                        ? "w-[122px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "w-[169px]"
                                                                            : screenWidth >= 1440
                                                                                ? "w-[206px]"
                                                                                : ""
                                                                } ${screenWidth >= 1440 ? "-left-px" : ""} ${screenWidth >= 1440 ? "-top-px" : ""} ${
                                                                    (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                        ? "h-[122px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "h-[169px]"
                                                                            : screenWidth >= 1440
                                                                                ? "h-[206px]"
                                                                                : ""
                                                                }`}
                                                            >
                                                                <div
                                                                    className={`bg-[100%_100%] absolute ${
                                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                            ? "w-[121px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "w-[168px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "w-[204px]"
                                                                                    : ""
                                                                    } ${
                                                                        (screenWidth >= 1200 && screenWidth < 1440) ||
                                                                        (screenWidth >= 810 && screenWidth < 1200) ||
                                                                        screenWidth < 810
                                                                            ? "left-0"
                                                                            : screenWidth >= 1440
                                                                                ? "left-px"
                                                                                : ""
                                                                    } ${
                                                                        (screenWidth >= 1200 && screenWidth < 1440) ||
                                                                        (screenWidth >= 810 && screenWidth < 1200) ||
                                                                        screenWidth < 810
                                                                            ? "top-0"
                                                                            : screenWidth >= 1440
                                                                                ? "top-px"
                                                                                : ""
                                                                    } ${
                                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                            ? "h-[121px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "h-[168px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "h-[204px]"
                                                                                    : ""
                                                                    } ${
                                                                        screenWidth < 810
                                                                            ? "bg-[url(/static/img/union.svg)]"
                                                                            : screenWidth >= 810 && screenWidth < 1200
                                                                                ? "bg-[url(/static/img/union-5.svg)]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "bg-[url(/static/img/union-10.svg)]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "bg-[url(/static/img/union-15.svg)]"
                                                                                        : ""
                                                                    }`}
                                                                >
                                                                    <div
                                                                        className={`border-[#87849b] opacity-50 relative ${
                                                                            (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                                ? "border-[0.71px] border-solid"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "border-[0.99px] border-solid"
                                                                                    : screenWidth >= 1440
                                                                                        ? "border-[1.2px] border-solid"
                                                                                        : ""
                                                                        } ${
                                                                            (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                                ? "w-[101px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "w-[139px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "w-[168px]"
                                                                                        : ""
                                                                        } ${
                                                                            (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                                ? "left-2.5"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "left-3.5"
                                                                                    : screenWidth >= 1440
                                                                                        ? "left-[18px]"
                                                                                        : ""
                                                                        } ${
                                                                            (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                                ? "top-2.5"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "top-3.5"
                                                                                    : screenWidth >= 1440
                                                                                        ? "top-[18px]"
                                                                                        : ""
                                                                        } ${
                                                                            (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                                ? "h-[101px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "h-[139px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "h-[168px]"
                                                                                        : ""
                                                                        } ${
                                                                            (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                                ? "rounded-[50.34px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "rounded-[69.57px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "rounded-[83.99px]"
                                                                                        : ""
                                                                        }`}
                                                                    />
                                                                </div>
                                                                <img
                                                                    className={`left-0 top-0 absolute ${
                                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                            ? "w-[122px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "w-[169px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "w-[206px]"
                                                                                    : ""
                                                                    } ${
                                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                            ? "h-[122px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "h-[169px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "h-[206px]"
                                                                                    : ""
                                                                    }`}
                                                                    alt="Ellipse"
                                                                    src={
                                                                        screenWidth < 810
                                                                            ? "/static/img/ellipse-2568.svg"
                                                                            : screenWidth >= 810 && screenWidth < 1200
                                                                                ? "/static/img/ellipse-2568-3.svg"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "/static/img/ellipse-2568-6.svg"
                                                                                    : screenWidth >= 1440
                                                                                        ? "/static/img/ellipse-2568-9.svg"
                                                                                        : undefined
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <img
                                                            className={`absolute ${
                                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                    ? "w-[69px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "w-24"
                                                                        : screenWidth >= 1440
                                                                            ? "w-[117px]"
                                                                            : ""
                                                            } ${
                                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                    ? "left-[26px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-9"
                                                                        : screenWidth >= 1440
                                                                            ? "left-11"
                                                                            : ""
                                                            } ${
                                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                    ? "top-[29px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "top-10"
                                                                        : screenWidth >= 1440
                                                                            ? "top-12"
                                                                            : ""
                                                            } ${
                                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                    ? "h-16"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "h-[89px]"
                                                                        : screenWidth >= 1440
                                                                            ? "h-[108px]"
                                                                            : ""
                                                            }`}
                                                            alt="Img"
                                                            src={
                                                                screenWidth < 810
                                                                    ? "/static/img/image-2.svg"
                                                                    : screenWidth >= 810 && screenWidth < 1200
                                                                        ? "/static/img/4-4.svg"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "/static/img/8.svg"
                                                                            : screenWidth >= 1440
                                                                                ? "/static/img/12.svg"
                                                                                : undefined
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className={`top-0 absolute ${
                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                            ? "w-[121px]"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "w-[168px]"
                                                                : screenWidth >= 1440
                                                                    ? "w-[204px]"
                                                                    : ""
                                                    } ${
                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                            ? "left-[110px]"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "left-[153px]"
                                                                : screenWidth >= 1440
                                                                    ? "left-[186px]"
                                                                    : ""
                                                    } ${
                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                            ? "h-[121px]"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "h-[168px]"
                                                                : screenWidth >= 1440
                                                                    ? "h-[204px]"
                                                                    : ""
                                                    }`}
                                                >
                                                    <div
                                                        className={`relative ${
                                                            (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                ? "h-[121px]"
                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "h-[168px]"
                                                                    : screenWidth >= 1440
                                                                        ? "h-[204px]"
                                                                        : ""
                                                        }`}
                                                    >
                                                        <div
                                                            className={`left-0 top-0 rotate-[-180.00deg] absolute ${
                                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                    ? "w-[121px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "w-[168px]"
                                                                        : screenWidth >= 1440
                                                                            ? "w-[204px]"
                                                                            : ""
                                                            } ${
                                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                    ? "h-[121px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "h-[168px]"
                                                                        : screenWidth >= 1440
                                                                            ? "h-[204px]"
                                                                            : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`relative ${
                                                                    (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                        ? "w-[122px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "w-[169px]"
                                                                            : screenWidth >= 1440
                                                                                ? "w-[206px]"
                                                                                : ""
                                                                } ${screenWidth >= 1440 ? "-left-px" : ""} ${screenWidth >= 1440 ? "-top-px" : ""} ${
                                                                    (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                        ? "h-[122px]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "h-[169px]"
                                                                            : screenWidth >= 1440
                                                                                ? "h-[206px]"
                                                                                : ""
                                                                }`}
                                                            >
                                                                <div
                                                                    className={`absolute ${
                                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                            ? "w-[121px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "w-[168px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "w-[204px]"
                                                                                    : ""
                                                                    } ${
                                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                            ? "left-0"
                                                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                                ? "left-px"
                                                                                : ""
                                                                    } ${
                                                                        (screenWidth >= 1200 && screenWidth < 1440) ||
                                                                        (screenWidth >= 810 && screenWidth < 1200) ||
                                                                        screenWidth < 810
                                                                            ? "top-0"
                                                                            : screenWidth >= 1440
                                                                                ? "top-px"
                                                                                : ""
                                                                    } ${
                                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                            ? "h-[121px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "h-[168px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "h-[204px]"
                                                                                    : ""
                                                                    }`}
                                                                >
                                                                    <div
                                                                        className={`relative ${
                                                                            (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                                ? "h-[121px]"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "h-[168px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "h-[204px]"
                                                                                        : ""
                                                                        }`}
                                                                    >
                                                                        <img
                                                                            className={`left-0 top-0 rotate-[180.00deg] absolute ${
                                                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                                    ? "w-[121px]"
                                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                                        ? "w-[168px]"
                                                                                        : screenWidth >= 1440
                                                                                            ? "w-[204px]"
                                                                                            : ""
                                                                            } ${
                                                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                                    ? "h-[121px]"
                                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                                        ? "h-[168px]"
                                                                                        : screenWidth >= 1440
                                                                                            ? "h-[204px]"
                                                                                            : ""
                                                                            }`}
                                                                            alt="Union"
                                                                            src={
                                                                                screenWidth < 810
                                                                                    ? "/static/img/union-1.svg"
                                                                                    : screenWidth >= 810 && screenWidth < 1200
                                                                                        ? "/static/img/union-6.svg"
                                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                                            ? "/static/img/union-11.svg"
                                                                                            : screenWidth >= 1440
                                                                                                ? "/static/img/union-16.svg"
                                                                                                : undefined
                                                                            }
                                                                        />
                                                                        <div
                                                                            className={`border-[#c2aee5] opacity-50 absolute ${
                                                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                                    ? "border-[0.71px] border-solid"
                                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                                        ? "border-[0.99px] border-solid"
                                                                                        : screenWidth >= 1440
                                                                                            ? "border-[1.2px] border-solid"
                                                                                            : ""
                                                                            } ${
                                                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                                    ? "w-[101px]"
                                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                                        ? "w-[139px]"
                                                                                        : screenWidth >= 1440
                                                                                            ? "w-[168px]"
                                                                                            : ""
                                                                            } ${
                                                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                                    ? "left-2.5"
                                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                                        ? "left-3.5"
                                                                                        : screenWidth >= 1440
                                                                                            ? "left-[18px]"
                                                                                            : ""
                                                                            } ${
                                                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                                    ? "top-2.5"
                                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                                        ? "top-3.5"
                                                                                        : screenWidth >= 1440
                                                                                            ? "top-[18px]"
                                                                                            : ""
                                                                            } ${
                                                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                                    ? "h-[101px]"
                                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                                        ? "h-[139px]"
                                                                                        : screenWidth >= 1440
                                                                                            ? "h-[168px]"
                                                                                            : ""
                                                                            } ${
                                                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                                    ? "rounded-[50.34px]"
                                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                                        ? "rounded-[69.57px]"
                                                                                        : screenWidth >= 1440
                                                                                            ? "rounded-[83.99px]"
                                                                                            : ""
                                                                            }`}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <img
                                                                    className={`left-0 top-0 rotate-[180.00deg] absolute ${
                                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                            ? "w-[122px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "w-[169px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "w-[206px]"
                                                                                    : ""
                                                                    } ${
                                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                            ? "h-[122px]"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "h-[169px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "h-[206px]"
                                                                                    : ""
                                                                    }`}
                                                                    alt="Ellipse"
                                                                    src={
                                                                        screenWidth < 810
                                                                            ? "/static/img/ellipse-2568-1.svg"
                                                                            : screenWidth >= 810 && screenWidth < 1200
                                                                                ? "/static/img/ellipse-2568-4.svg"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "/static/img/ellipse-2568-7.svg"
                                                                                    : screenWidth >= 1440
                                                                                        ? "/static/img/ellipse-2568-10.svg"
                                                                                        : undefined
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <img
                                                            className={`absolute ${
                                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                    ? "w-[67px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "w-[93px]"
                                                                        : screenWidth >= 1440
                                                                            ? "w-[113px]"
                                                                            : ""
                                                            } ${
                                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                    ? "left-7"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[38px]"
                                                                        : screenWidth >= 1440
                                                                            ? "left-[46px]"
                                                                            : ""
                                                            } ${
                                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                    ? "top-[29px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "top-10"
                                                                        : screenWidth >= 1440
                                                                            ? "top-12"
                                                                            : ""
                                                            } ${
                                                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                    ? "h-16"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "h-[89px]"
                                                                        : screenWidth >= 1440
                                                                            ? "h-[108px]"
                                                                            : ""
                                                            }`}
                                                            alt="Img"
                                                            src={
                                                                screenWidth < 810
                                                                    ? "/static/img/1.svg"
                                                                    : screenWidth >= 810 && screenWidth < 1200
                                                                        ? "/static/img/5-2.svg"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "/static/img/9-2.svg"
                                                                            : screenWidth >= 1440
                                                                                ? "/static/img/13.svg"
                                                                                : undefined
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <img
                                        className="w-full self-stretch object-cover h-px relative"
                                        alt="Line"
                                        src={
                                            screenWidth < 810
                                                ? "/static/img/line-7.svg"
                                                : screenWidth >= 810 && screenWidth < 1200
                                                    ? "/static/img/line-16.svg"
                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                        ? "/static/img/line-26.svg"
                                                        : screenWidth >= 1440
                                                            ? "/static/img/line-34.svg"
                                                            : undefined
                                        }
                                    />
                                    <div
                                        className={`w-full flex self-stretch flex-col items-start overflow-hidden rounded-[0px_0px_4px_4px] bg-[#100f12] relative ${
                                            screenWidth >= 810 && screenWidth < 1200 ? "grow" : ""
                                        } ${
                                            screenWidth < 810
                                                ? "gap-5"
                                                : (screenWidth >= 1200 && screenWidth < 1440) ||
                                                screenWidth >= 1440 ||
                                                (screenWidth >= 810 && screenWidth < 1200)
                                                    ? "gap-[25px]"
                                                    : ""
                                        } ${
                                            (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                                ? "flex-[0_0_auto]"
                                                : screenWidth >= 810 && screenWidth < 1200
                                                    ? "flex-1"
                                                    : ""
                                        } ${
                                            screenWidth < 810
                                                ? "pt-[15px] pb-5 px-[15px]"
                                                : (screenWidth >= 1200 && screenWidth < 1440) ||
                                                screenWidth >= 1440 ||
                                                (screenWidth >= 810 && screenWidth < 1200)
                                                    ? "pt-[23px] pb-[30px] px-[23px]"
                                                    : ""
                                        }`}
                                    >
                                        <div
                                            className="w-full flex self-stretch items-center gap-4 flex-[0_0_auto] relative">
                                            <div
                                                className={`relative ${
                                                    (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                        ? "w-8"
                                                        : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                            ? "w-10"
                                                            : ""
                                                } ${
                                                    (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                        ? "mt-[-0.80px]"
                                                        : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                            ? "mt-[-1.00px]"
                                                            : ""
                                                } ${
                                                    (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                        ? "ml-[-0.80px]"
                                                        : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                            ? "ml-[-1.00px]"
                                                            : ""
                                                } ${
                                                    (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                        ? "h-8"
                                                        : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                            ? "h-10"
                                                            : ""
                                                } ${
                                                    (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                        ? "mb-[-0.80px]"
                                                        : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                            ? "mb-[-1.00px]"
                                                            : ""
                                                }`}
                                            >
                                                <div
                                                    className={`relative ${
                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                            ? "w-[26px]"
                                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                ? "w-8"
                                                                : ""
                                                    } ${
                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                            ? "left-0.5"
                                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                ? "left-[3px]"
                                                                : ""
                                                    } ${
                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                            ? "top-0.5"
                                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                ? "top-[3px]"
                                                                : ""
                                                    } ${
                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                            ? "h-[26px]"
                                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                ? "h-8"
                                                                : ""
                                                    }`}
                                                >
                                                    <img
                                                        className={`absolute ${
                                                            (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                ? "w-[18px]"
                                                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                    ? "w-[23px]"
                                                                    : ""
                                                        } ${
                                                            (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                ? "left-2"
                                                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                    ? "left-[9px]"
                                                                    : ""
                                                        } ${
                                                            (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                ? "top-2"
                                                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                    ? "top-[9px]"
                                                                    : ""
                                                        } ${
                                                            (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                ? "h-[18px]"
                                                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                    ? "h-[23px]"
                                                                    : ""
                                                        }`}
                                                        alt="Polygon"
                                                        src={
                                                            screenWidth < 810
                                                                ? "/static/img/polygon-42.svg"
                                                                : screenWidth >= 810 && screenWidth < 1200
                                                                    ? "/static/img/polygon-42-1.svg"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "/static/img/polygon-42-2.svg"
                                                                        : screenWidth >= 1440
                                                                            ? "/static/img/polygon-42-3.svg"
                                                                            : undefined
                                                        }
                                                    />
                                                    <img
                                                        className={`left-0 top-0 absolute ${
                                                            (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                ? "w-[18px]"
                                                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                    ? "w-[23px]"
                                                                    : ""
                                                        } ${
                                                            (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                ? "h-[18px]"
                                                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                    ? "h-[23px]"
                                                                    : ""
                                                        }`}
                                                        alt="Subtract"
                                                        src={
                                                            screenWidth < 810
                                                                ? "/static/img/subtract.svg"
                                                                : screenWidth >= 810 && screenWidth < 1200
                                                                    ? "/static/img/subtract-1.svg"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "/static/img/subtract-2.svg"
                                                                        : screenWidth >= 1440
                                                                            ? "/static/img/subtract-3.svg"
                                                                            : undefined
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="inline-flex items-center gap-2.5 flex-[0_0_auto] pt-0 pb-[3px] px-0 justify-center relative">
                                                <div
                                                    className={`[font-family:'Aeonik-Medium',Helvetica] w-fit mt-[-1.00px] text-[#f4f4f6] font-medium whitespace-nowrap relative ${
                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                            ? "tracking-[-0.22px]"
                                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                ? "tracking-[-0.28px]"
                                                                : ""
                                                    } ${
                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                            ? "text-[22px]"
                                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                ? "text-[28px]"
                                                                : ""
                                                    } ${
                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                            ? "leading-[22px]"
                                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                ? "leading-7"
                                                                : ""
                                                    }`}
                                                >
                                                    Dual staking rewards
                                                </div>
                                            </div>
                                        </div>
                                        <p
                                            className={`[font-family:'Aeonik-Regular',Helvetica] tracking-[0] text-lg text-[#9997a4] font-normal leading-[23.4px] relative ${
                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 ? "w-[468.05px]" : ""
                                            } ${(screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810 ? "self-stretch" : ""}`}
                                        >
                                            Stake your $TINC and earn rewards in both $TINC and $wTAO. Rewards can be
                                            claimed at any time in
                                            real-time, giving you flexibility and control.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={`flex flex-col items-start overflow-hidden rounded justify-center relative ${
                                        screenWidth < 810 ? "w-full" : ""
                                    } ${screenWidth < 810 ? "self-stretch" : ""} ${
                                        (screenWidth >= 1200 && screenWidth < 1440) ||
                                        screenWidth >= 1440 ||
                                        (screenWidth >= 810 && screenWidth < 1200)
                                            ? "grow"
                                            : ""
                                    } ${
                                        screenWidth < 810
                                            ? "flex-[0_0_auto]"
                                            : (screenWidth >= 1200 && screenWidth < 1440) ||
                                            screenWidth >= 1440 ||
                                            (screenWidth >= 810 && screenWidth < 1200)
                                                ? "flex-1"
                                                : ""
                                    }`}
                                >
                                    <div
                                        className={`w-full self-stretch overflow-hidden bg-[#100f12] relative ${
                                            screenWidth < 810
                                                ? "h-[207px]"
                                                : screenWidth >= 810 && screenWidth < 1200
                                                    ? "h-[199.4px]"
                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                        ? "h-[277.11px]"
                                                        : screenWidth >= 1440
                                                            ? "h-[337px]"
                                                            : ""
                                        } ${
                                            (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                                ? "rounded-[4px_4px_0px_0px]"
                                                : screenWidth >= 810 && screenWidth < 1200
                                                    ? "rounded-[2.88px_2.88px_0px_0px]"
                                                    : ""
                                        }`}
                                    >
                                        <div
                                            className={`${
                                                screenWidth >= 1200 && screenWidth < 1440
                                                    ? "w-[301px]"
                                                    : screenWidth >= 1440
                                                        ? "w-[366px]"
                                                        : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                            ? "w-[312px]"
                                                            : ""
                                            } ${
                                                screenWidth >= 1200 && screenWidth < 1440
                                                    ? "left-[187px]"
                                                    : screenWidth >= 1440
                                                        ? "left-[228px]"
                                                        : screenWidth < 810
                                                            ? "left-6"
                                                            : screenWidth >= 810 && screenWidth < 1200
                                                                ? "left-[35px]"
                                                                : ""
                                            } ${
                                                screenWidth >= 1200 && screenWidth < 1440
                                                    ? "top-[55px]"
                                                    : screenWidth >= 1440
                                                        ? "top-[66px]"
                                                        : screenWidth < 810
                                                            ? "top-[43px]"
                                                            : screenWidth >= 810 && screenWidth < 1200
                                                                ? "top-[39px]"
                                                                : ""
                                            } ${
                                                screenWidth >= 1200 && screenWidth < 1440
                                                    ? "h-[168px]"
                                                    : screenWidth >= 1440
                                                        ? "h-[204px]"
                                                        : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                            ? "h-[121px]"
                                                            : ""
                                            } ${
                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                    ? "absolute"
                                                    : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                        ? "relative"
                                                        : ""
                                            }`}
                                        >
                                            <div
                                                className={`top-0 absolute ${
                                                    screenWidth >= 1200 && screenWidth < 1440
                                                        ? "w-[168px]"
                                                        : screenWidth >= 1440
                                                            ? "w-[205px]"
                                                            : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                ? "w-[216px]"
                                                                : ""
                                                } ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                        ? "left-0"
                                                        : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                            ? "left-24"
                                                            : ""
                                                } ${
                                                    screenWidth >= 1200 && screenWidth < 1440
                                                        ? "h-[168px]"
                                                        : screenWidth >= 1440
                                                            ? "h-[204px]"
                                                            : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                ? "h-[121px]"
                                                                : ""
                                                }`}
                                            >
                                                {((screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440) && (
                                                    <div
                                                        className={`-left-px -top-px relative ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "w-[170px]"
                                                                : screenWidth >= 1440
                                                                    ? "w-[206px]"
                                                                    : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "h-[170px]"
                                                                : screenWidth >= 1440
                                                                    ? "h-[206px]"
                                                                    : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "rounded-[1.64px]"
                                                                : screenWidth >= 1440
                                                                    ? "rounded-sm"
                                                                    : ""
                                                        }`}
                                                    >
                                                        <div
                                                            className={`left-px top-px absolute ${
                                                                screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "w-[168px]"
                                                                    : screenWidth >= 1440
                                                                        ? "w-[204px]"
                                                                        : ""
                                                            } ${
                                                                screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "h-[168px]"
                                                                    : screenWidth >= 1440
                                                                        ? "h-[204px]"
                                                                        : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`relative ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "h-[168px]"
                                                                        : screenWidth >= 1440
                                                                            ? "h-[204px]"
                                                                            : ""
                                                                }`}
                                                            >
                                                                <div
                                                                    className={`left-0 top-0 rotate-[-180.00deg] absolute ${
                                                                        screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "w-[168px]"
                                                                            : screenWidth >= 1440
                                                                                ? "w-[204px]"
                                                                                : ""
                                                                    } ${
                                                                        screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "h-[168px]"
                                                                            : screenWidth >= 1440
                                                                                ? "h-[204px]"
                                                                                : ""
                                                                    }`}
                                                                >
                                                                    <div
                                                                        className={`relative ${
                                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "w-[169px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "w-[206px]"
                                                                                    : ""
                                                                        } ${screenWidth >= 1440 ? "-left-px" : ""} ${
                                                                            screenWidth >= 1440 ? "-top-px" : ""
                                                                        } ${
                                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "h-[169px]"
                                                                                : screenWidth >= 1440
                                                                                    ? "h-[206px]"
                                                                                    : ""
                                                                        }`}
                                                                    >
                                                                        <div
                                                                            className={`absolute ${
                                                                                screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "w-[137px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "w-[167px]"
                                                                                        : ""
                                                                            } ${
                                                                                screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "left-4"
                                                                                    : screenWidth >= 1440
                                                                                        ? "left-[19px]"
                                                                                        : ""
                                                                            } ${
                                                                                screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "top-4"
                                                                                    : screenWidth >= 1440
                                                                                        ? "top-[19px]"
                                                                                        : ""
                                                                            } ${
                                                                                screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "h-[137px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "h-[167px]"
                                                                                        : ""
                                                                            }`}
                                                                        >
                                                                            <div
                                                                                className={`border-[#c2aee5] -left-px opacity-50 -top-px relative ${
                                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                                        ? "border-[0.99px] border-solid"
                                                                                        : screenWidth >= 1440
                                                                                            ? "border-[1.2px] border-solid"
                                                                                            : ""
                                                                                } ${
                                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                                        ? "w-[139px]"
                                                                                        : screenWidth >= 1440
                                                                                            ? "w-[168px]"
                                                                                            : ""
                                                                                } ${
                                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                                        ? "h-[139px]"
                                                                                        : screenWidth >= 1440
                                                                                            ? "h-[168px]"
                                                                                            : ""
                                                                                } ${
                                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                                        ? "rounded-[69.57px]"
                                                                                        : screenWidth >= 1440
                                                                                            ? "rounded-[83.99px]"
                                                                                            : ""
                                                                                }`}
                                                                            />
                                                                        </div>
                                                                        <img
                                                                            className={`left-0 top-0 rotate-[180.00deg] absolute ${
                                                                                screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "w-[169px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "w-[206px]"
                                                                                        : ""
                                                                            } ${
                                                                                screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "h-[169px]"
                                                                                    : screenWidth >= 1440
                                                                                        ? "h-[206px]"
                                                                                        : ""
                                                                            }`}
                                                                            alt="Ellipse"
                                                                            src={
                                                                                screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "/static/img/ellipse-2568-8.svg"
                                                                                    : screenWidth >= 1440
                                                                                        ? "/static/img/ellipse-2568-11.svg"
                                                                                        : undefined
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <img
                                                                    className={`absolute ${
                                                                        screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "w-[93px]"
                                                                            : screenWidth >= 1440
                                                                                ? "w-[113px]"
                                                                                : ""
                                                                    } ${
                                                                        screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "left-[39px]"
                                                                            : screenWidth >= 1440
                                                                                ? "left-[47px]"
                                                                                : ""
                                                                    } ${
                                                                        screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "top-10"
                                                                            : screenWidth >= 1440
                                                                                ? "top-12"
                                                                                : ""
                                                                    } ${
                                                                        screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "h-[89px]"
                                                                            : screenWidth >= 1440
                                                                                ? "h-[108px]"
                                                                                : ""
                                                                    }`}
                                                                    alt="Img"
                                                                    src={
                                                                        screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "/static/img/10-2.svg"
                                                                            : screenWidth >= 1440
                                                                                ? "/static/img/14.svg"
                                                                                : undefined
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`border-[#c2aee5] left-0 opacity-50 top-0 absolute ${
                                                                screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "border-[0.99px] border-solid"
                                                                    : screenWidth >= 1440
                                                                        ? "border-[1.2px] border-solid"
                                                                        : ""
                                                            } ${
                                                                screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "w-[170px]"
                                                                    : screenWidth >= 1440
                                                                        ? "w-[206px]"
                                                                        : ""
                                                            } ${
                                                                screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "h-[170px]"
                                                                    : screenWidth >= 1440
                                                                        ? "h-[206px]"
                                                                        : ""
                                                            } ${
                                                                screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "rounded-[1.64px]"
                                                                    : screenWidth >= 1440
                                                                        ? "rounded-sm"
                                                                        : ""
                                                            }`}
                                                        />
                                                    </div>
                                                )}

                                                {((screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810) && (
                                                    <>
                                                        <div className="w-[121px] left-0 top-0 h-[121px] absolute">
                                                            <div
                                                                className="w-[123px] -left-px -top-px h-[123px] rounded-[1.18px] relative">
                                                                <div
                                                                    className="w-[121px] left-px top-px h-[121px] absolute">
                                                                    <div className="h-[121px] relative">
                                                                        <div
                                                                            className="w-[121px] left-0 top-0 rotate-[-180.00deg] h-[121px] absolute">
                                                                            <div
                                                                                className="w-[122px] h-[122px] relative">
                                                                                <div
                                                                                    className="w-[99px] left-[11px] top-[11px] h-[99px] absolute">
                                                                                    <div
                                                                                        className="border-[0.71px] border-solid border-[#c2aee5] w-[101px] -left-px opacity-50 -top-px h-[101px] rounded-[50.34px] relative"/>
                                                                                </div>
                                                                                <img
                                                                                    className="w-[122px] left-0 top-0 rotate-[180.00deg] h-[122px] absolute"
                                                                                    alt="Ellipse"
                                                                                    src={
                                                                                        screenWidth < 810
                                                                                            ? "/static/img/ellipse-2568-2.svg"
                                                                                            : screenWidth >= 810 && screenWidth < 1200
                                                                                                ? "/static/img/ellipse-2568-5.svg"
                                                                                                : undefined
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <img
                                                                            className="w-[67px] left-7 top-[29px] h-16 absolute"
                                                                            alt="Img"
                                                                            src={
                                                                                screenWidth < 810
                                                                                    ? "/static/img/2-4.svg"
                                                                                    : screenWidth >= 810 && screenWidth < 1200
                                                                                        ? "/static/img/6-3.svg"
                                                                                        : undefined
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="border-[0.71px] border-solid border-[#c2aee5] w-[123px] left-0 opacity-50 top-0 h-[123px] rounded-[1.18px] absolute"/>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="w-24 left-[121px] opacity-[0.56] top-[13px] h-24 absolute">
                                                            <div
                                                                className="w-[98px] -left-px -top-px h-[98px] relative">
                                                                <div
                                                                    className="w-[68px] left-[15px] top-[15px] h-[68px] absolute">
                                                                    <div className="h-[68px]">
                                                                        <div
                                                                            className="border-[0.71px] border-solid border-[#87849b] w-[70px] -left-px opacity-50 -top-px h-[70px] rounded-[34.77px] relative"/>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="border-[0.71px] border-solid border-[#87849b] w-[70px] left-3.5 opacity-50 top-3.5 rotate-45 h-[70px] rounded-[1.18px] absolute"/>
                                                                <img
                                                                    className="w-9 left-[31px] top-[30px] h-[39px] absolute"
                                                                    alt="Union"
                                                                    src={
                                                                        screenWidth < 810
                                                                            ? "/static/img/union-3.svg"
                                                                            : screenWidth >= 810 && screenWidth < 1200
                                                                                ? "/static/img/union-8.svg"
                                                                                : undefined
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div
                                                className={`opacity-[0.56] absolute ${
                                                    screenWidth >= 1200 && screenWidth < 1440
                                                        ? "w-[133px]"
                                                        : screenWidth >= 1440
                                                            ? "w-[161px]"
                                                            : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                ? "w-24"
                                                                : ""
                                                } ${
                                                    screenWidth >= 1200 && screenWidth < 1440
                                                        ? "left-[168px]"
                                                        : screenWidth >= 1440
                                                            ? "left-[204px]"
                                                            : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                ? "left-0"
                                                                : ""
                                                } ${
                                                    screenWidth >= 1200 && screenWidth < 1440
                                                        ? "top-[18px]"
                                                        : screenWidth >= 1440
                                                            ? "top-[21px]"
                                                            : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                ? "top-[13px]"
                                                                : ""
                                                } ${
                                                    screenWidth >= 1200 && screenWidth < 1440
                                                        ? "h-[133px]"
                                                        : screenWidth >= 1440
                                                            ? "h-[161px]"
                                                            : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                ? "h-24"
                                                                : ""
                                                }`}
                                            >
                                                <div
                                                    className={`-left-px -top-px relative ${
                                                        screenWidth >= 1200 && screenWidth < 1440
                                                            ? "w-[136px]"
                                                            : screenWidth >= 1440
                                                                ? "w-[163px]"
                                                                : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                    ? "w-[98px]"
                                                                    : ""
                                                    } ${
                                                        screenWidth >= 1200 && screenWidth < 1440
                                                            ? "h-[136px]"
                                                            : screenWidth >= 1440
                                                                ? "h-[163px]"
                                                                : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                    ? "h-[98px]"
                                                                    : ""
                                                    }`}
                                                >
                                                    <div
                                                        className={`absolute ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "w-[94px]"
                                                                : screenWidth >= 1440
                                                                    ? "w-[114px]"
                                                                    : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                        ? "w-[68px]"
                                                                        : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "left-[21px]"
                                                                : screenWidth >= 1440
                                                                    ? "left-6"
                                                                    : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                        ? "left-[15px]"
                                                                        : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "top-[21px]"
                                                                : screenWidth >= 1440
                                                                    ? "top-6"
                                                                    : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                        ? "top-[15px]"
                                                                        : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "h-[94px]"
                                                                : screenWidth >= 1440
                                                                    ? "h-[114px]"
                                                                    : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                        ? "h-[68px]"
                                                                        : ""
                                                        }`}
                                                    >
                                                        <div
                                                            className={`${
                                                                screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "h-[94px]"
                                                                    : screenWidth >= 1440
                                                                        ? "h-[114px]"
                                                                        : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                            ? "h-[68px]"
                                                                            : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`border-[#87849b] -left-px opacity-50 -top-px relative ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "border-[0.99px] border-solid"
                                                                        : screenWidth >= 1440
                                                                            ? "border-[1.2px] border-solid"
                                                                            : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                                ? "border-[0.71px] border-solid"
                                                                                : ""
                                                                } ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "w-24"
                                                                        : screenWidth >= 1440
                                                                            ? "w-[115px]"
                                                                            : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                                ? "w-[70px]"
                                                                                : ""
                                                                } ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "h-24"
                                                                        : screenWidth >= 1440
                                                                            ? "h-[115px]"
                                                                            : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                                ? "h-[70px]"
                                                                                : ""
                                                                } ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "rounded-[47.93px]"
                                                                        : screenWidth >= 1440
                                                                            ? "rounded-[57.67px]"
                                                                            : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                                ? "rounded-[34.77px]"
                                                                                : ""
                                                                }`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`border-[#87849b] opacity-50 rotate-45 absolute ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "border-[0.99px] border-solid"
                                                                : screenWidth >= 1440
                                                                    ? "border-[1.2px] border-solid"
                                                                    : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                        ? "border-[0.71px] border-solid"
                                                                        : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "w-24"
                                                                : screenWidth >= 1440
                                                                    ? "w-[115px]"
                                                                    : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                        ? "w-[70px]"
                                                                        : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "left-5"
                                                                : screenWidth >= 1440
                                                                    ? "left-6"
                                                                    : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                        ? "left-3.5"
                                                                        : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "top-5"
                                                                : screenWidth >= 1440
                                                                    ? "top-6"
                                                                    : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                        ? "top-3.5"
                                                                        : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "h-24"
                                                                : screenWidth >= 1440
                                                                    ? "h-[115px]"
                                                                    : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                        ? "h-[70px]"
                                                                        : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "rounded-[1.64px]"
                                                                : screenWidth >= 1440
                                                                    ? "rounded-sm"
                                                                    : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                        ? "rounded-[1.18px]"
                                                                        : ""
                                                        }`}
                                                    />
                                                    <img
                                                        className={`absolute ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "w-[50px]"
                                                                : screenWidth >= 1440
                                                                    ? "w-[61px]"
                                                                    : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                        ? "w-9"
                                                                        : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "left-[42px]"
                                                                : screenWidth >= 1440
                                                                    ? "left-[50px]"
                                                                    : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                        ? "left-8"
                                                                        : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "top-[41px]"
                                                                : screenWidth >= 1440
                                                                    ? "top-[49px]"
                                                                    : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                        ? "top-[30px]"
                                                                        : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "h-[54px]"
                                                                : screenWidth >= 1440
                                                                    ? "h-[66px]"
                                                                    : (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                                        ? "h-[39px]"
                                                                        : ""
                                                        }`}
                                                        alt="Union"
                                                        src={
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "/static/img/union-13.svg"
                                                                : screenWidth >= 1440
                                                                    ? "/static/img/union-18.svg"
                                                                    : screenWidth < 810
                                                                        ? "/static/img/union-2.svg"
                                                                        : screenWidth >= 810 && screenWidth < 1200
                                                                            ? "/static/img/union-7.svg"
                                                                            : undefined
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {((screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440) && (
                                            <div
                                                className={`opacity-[0.56] absolute ${
                                                    screenWidth >= 1200 && screenWidth < 1440
                                                        ? "w-[133px]"
                                                        : screenWidth >= 1440
                                                            ? "w-[161px]"
                                                            : ""
                                                } ${
                                                    screenWidth >= 1200 && screenWidth < 1440
                                                        ? "left-[54px]"
                                                        : screenWidth >= 1440
                                                            ? "left-[67px]"
                                                            : ""
                                                } ${
                                                    screenWidth >= 1200 && screenWidth < 1440
                                                        ? "top-[72px]"
                                                        : screenWidth >= 1440
                                                            ? "top-[88px]"
                                                            : ""
                                                } ${
                                                    screenWidth >= 1200 && screenWidth < 1440
                                                        ? "h-[133px]"
                                                        : screenWidth >= 1440
                                                            ? "h-[161px]"
                                                            : ""
                                                }`}
                                            >
                                                <div
                                                    className={`-left-px -top-px relative ${
                                                        screenWidth >= 1200 && screenWidth < 1440
                                                            ? "w-[136px]"
                                                            : screenWidth >= 1440
                                                                ? "w-[163px]"
                                                                : ""
                                                    } ${
                                                        screenWidth >= 1200 && screenWidth < 1440
                                                            ? "h-[136px]"
                                                            : screenWidth >= 1440
                                                                ? "h-[163px]"
                                                                : ""
                                                    }`}
                                                >
                                                    <div
                                                        className={`absolute ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "w-[94px]"
                                                                : screenWidth >= 1440
                                                                    ? "w-[114px]"
                                                                    : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "left-[21px]"
                                                                : screenWidth >= 1440
                                                                    ? "left-6"
                                                                    : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "top-[21px]"
                                                                : screenWidth >= 1440
                                                                    ? "top-6"
                                                                    : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "h-[94px]"
                                                                : screenWidth >= 1440
                                                                    ? "h-[114px]"
                                                                    : ""
                                                        }`}
                                                    >
                                                        <div
                                                            className={`${
                                                                screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "h-[94px]"
                                                                    : screenWidth >= 1440
                                                                        ? "h-[114px]"
                                                                        : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`border-[#87849b] -left-px opacity-50 -top-px relative ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "border-[0.99px] border-solid"
                                                                        : screenWidth >= 1440
                                                                            ? "border-[1.2px] border-solid"
                                                                            : ""
                                                                } ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "w-24"
                                                                        : screenWidth >= 1440
                                                                            ? "w-[115px]"
                                                                            : ""
                                                                } ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "h-24"
                                                                        : screenWidth >= 1440
                                                                            ? "h-[115px]"
                                                                            : ""
                                                                } ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "rounded-[47.93px]"
                                                                        : screenWidth >= 1440
                                                                            ? "rounded-[57.67px]"
                                                                            : ""
                                                                }`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`border-[#87849b] opacity-50 rotate-45 absolute ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "border-[0.99px] border-solid"
                                                                : screenWidth >= 1440
                                                                    ? "border-[1.2px] border-solid"
                                                                    : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "w-24"
                                                                : screenWidth >= 1440
                                                                    ? "w-[115px]"
                                                                    : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440 ? "left-5" : screenWidth >= 1440 ? "left-6" : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440 ? "top-5" : screenWidth >= 1440 ? "top-6" : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "h-24"
                                                                : screenWidth >= 1440
                                                                    ? "h-[115px]"
                                                                    : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "rounded-[1.64px]"
                                                                : screenWidth >= 1440
                                                                    ? "rounded-sm"
                                                                    : ""
                                                        }`}
                                                    />
                                                    <img
                                                        className={`absolute ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "w-[50px]"
                                                                : screenWidth >= 1440
                                                                    ? "w-[61px]"
                                                                    : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "left-11"
                                                                : screenWidth >= 1440
                                                                    ? "left-[52px]"
                                                                    : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "top-[41px]"
                                                                : screenWidth >= 1440
                                                                    ? "top-[49px]"
                                                                    : ""
                                                        } ${
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "h-[54px]"
                                                                : screenWidth >= 1440
                                                                    ? "h-[66px]"
                                                                    : ""
                                                        }`}
                                                        alt="Union"
                                                        src={
                                                            screenWidth >= 1200 && screenWidth < 1440
                                                                ? "/static/img/union-12.svg"
                                                                : screenWidth >= 1440
                                                                    ? "/static/img/union-17.svg"
                                                                    : undefined
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <img
                                        className="w-full self-stretch object-cover h-px relative"
                                        alt="Line"
                                        src={
                                            screenWidth < 810
                                                ? "/static/img/line-8.svg"
                                                : screenWidth >= 810 && screenWidth < 1200
                                                    ? "/static/img/line-17.svg"
                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                        ? "/static/img/line-26.svg"
                                                        : screenWidth >= 1440
                                                            ? "/static/img/line-35.svg"
                                                            : undefined
                                        }
                                    />
                                    <div
                                        className={`w-full flex self-stretch flex-col items-start flex-[0_0_auto] overflow-hidden rounded-[0px_0px_4px_4px] bg-[#100f12] relative ${
                                            screenWidth < 810
                                                ? "gap-5"
                                                : (screenWidth >= 1200 && screenWidth < 1440) ||
                                                screenWidth >= 1440 ||
                                                (screenWidth >= 810 && screenWidth < 1200)
                                                    ? "gap-[25px]"
                                                    : ""
                                        } ${
                                            screenWidth < 810
                                                ? "pt-[15px] pb-5 px-[15px]"
                                                : (screenWidth >= 1200 && screenWidth < 1440) ||
                                                screenWidth >= 1440 ||
                                                (screenWidth >= 810 && screenWidth < 1200)
                                                    ? "pt-[23px] pb-[30px] px-[23px]"
                                                    : ""
                                        }`}
                                    >
                                        <div
                                            className="w-full flex self-stretch items-center gap-4 flex-[0_0_auto] relative">
                                            <img
                                                className={`relative ${
                                                    (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                        ? "w-[30.4px]"
                                                        : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                            ? "w-[38px]"
                                                            : ""
                                                } ${
                                                    (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                        ? "h-[30.4px]"
                                                        : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                            ? "h-[38px]"
                                                            : ""
                                                }`}
                                                alt="Wtao project"
                                                src={
                                                    screenWidth < 810
                                                        ? "/static/img/wtao-project-management-icon.svg"
                                                        : screenWidth >= 810 && screenWidth < 1200
                                                            ? "/static/img/wtao-project-management-icon-1.svg"
                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                ? "/static/img/wtao-project-management-icon-2.svg"
                                                                : screenWidth >= 1440
                                                                    ? "/static/img/wtao-project-management-icon-3.svg"
                                                                    : undefined
                                                }
                                            />
                                            <div
                                                className={`inline-flex items-center gap-2.5 flex-[0_0_auto] pt-0 pb-[3px] px-0 justify-center relative ${
                                                    screenWidth < 810 ? "mr-[-2.40px]" : ""
                                                }`}
                                            >
                                                <div
                                                    className={`[font-family:'Aeonik-Medium',Helvetica] w-fit mt-[-1.00px] text-[#f4f4f6] font-medium whitespace-nowrap relative ${
                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                            ? "tracking-[-0.22px]"
                                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                ? "tracking-[-0.28px]"
                                                                : ""
                                                    } ${
                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                            ? "text-[22px]"
                                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                ? "text-[28px]"
                                                                : ""
                                                    } ${
                                                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                                            ? "leading-[22px]"
                                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                                ? "leading-7"
                                                                : ""
                                                    }`}
                                                >
                                                    $wTAO project management
                                                </div>
                                            </div>
                                        </div>
                                        <p
                                            className={`[font-family:'Aeonik-Regular',Helvetica] tracking-[0] text-lg text-[#9997a4] font-normal leading-[23.4px] relative ${
                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 ? "w-[548.55px]" : ""
                                            } ${(screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810 ? "self-stretch" : ""} ${
                                                screenWidth >= 1200 && screenWidth < 1440 ? "mr-[-52.55px]" : ""
                                            }`}
                                        >
                                            Funds are raised in $wTAO and automatically transferred to the project&#39;s
                                            predefined address.
                                            Leverage the power and security of the Bittensor ecosystem to finance
                                            innovative projects.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`flex flex-col items-start absolute ${
                            screenWidth < 810
                                ? "w-[361px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "w-[770px]"
                                    : screenWidth >= 1200 && screenWidth < 1440
                                        ? "w-[1096px]"
                                        : screenWidth >= 1440
                                            ? "w-[1332px]"
                                            : ""
                        } ${
                            screenWidth < 810
                                ? "left-[15px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "left-5"
                                    : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                        ? "left-8"
                                        : ""
                        } ${
                            screenWidth < 810
                                ? "top-[239px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "top-[250px]"
                                    : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                        ? "top-[338px]"
                                        : ""
                        } ${
                            screenWidth < 810
                                ? "gap-[45px]"
                                : (screenWidth >= 1200 && screenWidth < 1440) ||
                                screenWidth >= 1440 ||
                                (screenWidth >= 810 && screenWidth < 1200)
                                    ? "gap-20"
                                    : ""
                        }`}
                    >
                        <div
                            className={`w-full flex self-stretch flex-[0_0_auto] relative ${screenWidth < 810 ? "flex-col" : ""} ${
                                screenWidth < 810
                                    ? "items-start"
                                    : (screenWidth >= 1200 && screenWidth < 1440) ||
                                    screenWidth >= 1440 ||
                                    (screenWidth >= 810 && screenWidth < 1200)
                                        ? "items-center"
                                        : ""
                            } ${screenWidth < 810 ? "gap-[15px]" : ""} ${screenWidth < 810 ? "justify-center" : ""}`}
                        >
                            <div
                                className={`flex items-center gap-2.5 relative ${
                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                        ? "w-[271px]"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "w-[388px]"
                                            : screenWidth >= 1440
                                                ? "w-[331px]"
                                                : ""
                                } ${screenWidth < 810 ? "flex-[0_0_auto]" : ""}`}
                            >
                                <div id="About"
                                     className="[font-family:'Aeonik-Regular',Helvetica] w-fit mt-[-1.00px] tracking-[0] text-lg text-[#9897a3] font-normal leading-[23.4px] whitespace-nowrap relative">
                                    01 / About
                                </div>
                            </div>
                            <p
                                className={`[font-family:'Aeonik-Medium',Helvetica] text-transparent font-medium relative ${
                                    (screenWidth >= 1200 && screenWidth < 1440) ||
                                    screenWidth >= 1440 ||
                                    (screenWidth >= 810 && screenWidth < 1200)
                                        ? "w-fit"
                                        : ""
                                } ${screenWidth < 810 ? "self-stretch" : ""} ${
                                    screenWidth < 810
                                        ? "tracking-[-0.32px]"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "tracking-[-0.29px]"
                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                ? "tracking-[-0.48px]"
                                                : ""
                                } ${
                                    (screenWidth >= 1200 && screenWidth < 1440) ||
                                    screenWidth >= 1440 ||
                                    (screenWidth >= 810 && screenWidth < 1200)
                                        ? "mt-[-1.00px]"
                                        : ""
                                } ${
                                    screenWidth < 810
                                        ? "text-[32px]"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "text-[29px]"
                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                ? "text-5xl"
                                                : ""
                                } ${
                                    screenWidth < 810
                                        ? "leading-8"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "leading-[29px]"
                                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                ? "leading-[48px]"
                                                : ""
                                } ${
                                    (screenWidth >= 1200 && screenWidth < 1440) ||
                                    screenWidth >= 1440 ||
                                    (screenWidth >= 810 && screenWidth < 1200)
                                        ? "whitespace-nowrap"
                                        : ""
                                }`}
                            >
                <span
                    className={`text-[#f4f4f6] ${
                        screenWidth < 810
                            ? "tracking-[-0.10px]"
                            : screenWidth >= 810 && screenWidth < 1200
                                ? "tracking-[-0.08px]"
                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                    ? "tracking-[-0.23px]"
                                    : ""
                    }`}
                >
                  Why choose
                </span>
                                <span
                                    className={`text-[#e8e7ec] ${
                                        screenWidth < 810
                                            ? "tracking-[-0.10px]"
                                            : screenWidth >= 810 && screenWidth < 1200
                                                ? "tracking-[-0.08px]"
                                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                    ? "tracking-[-0.23px]"
                                                    : ""
                                    }`}
                                >
                  {" "}
                                    TAO Incubator?
                </span>
                            </p>
                        </div>
                        <div
                            className={`w-full flex self-stretch flex-[0_0_auto] relative ${
                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810 ? "flex-col" : ""
                            } ${
                                (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                    ? "items-center"
                                    : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                        ? "items-start"
                                        : ""
                            } ${
                                screenWidth < 810
                                    ? "gap-[50px]"
                                    : screenWidth >= 810 && screenWidth < 1200
                                        ? "gap-[49px]"
                                        : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                            ? "gap-2.5"
                                            : ""
                            } ${(screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 ? "justify-center" : ""}`}
                        >
                            {((screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810) && (
                                <>
                                    <div
                                        className={`flex flex-col items-start gap-[15px] relative ${screenWidth < 810 ? "w-full" : ""} ${
                                            screenWidth < 810 ? "self-stretch" : ""
                                        } ${(screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 ? "grow" : ""} ${
                                            screenWidth < 810
                                                ? "flex-[0_0_auto]"
                                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                    ? "flex-1"
                                                    : ""
                                        }`}
                                    >
                                        <div
                                            className={`w-full self-stretch overflow-hidden bg-[#87849b12] relative ${
                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                    ? "h-[275.23px]"
                                                    : screenWidth >= 1440
                                                        ? "h-[334.5px]"
                                                        : ""
                                            } ${
                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                    ? "rounded-[3.29px]"
                                                    : screenWidth >= 1440
                                                        ? "rounded"
                                                        : ""
                                            } ${screenWidth < 810 ? "[-webkit-backdrop-filter:blur(30.4px)_brightness(100%)]" : ""} ${
                                                screenWidth < 810 ? "backdrop-blur-[30.4px] backdrop-brightness-[100%]" : ""
                                            }`}
                                        >
                                            <div
                                                className={`relative ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                        ? "w-[181px]"
                                                        : screenWidth >= 1440
                                                            ? "w-[220px]"
                                                            : ""
                                                } ${
                                                    screenWidth < 810
                                                        ? "left-[90px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "left-[42px]"
                                                            : screenWidth >= 1440
                                                                ? "left-[53px]"
                                                                : ""
                                                } ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                        ? "top-[47px]"
                                                        : screenWidth >= 1440
                                                            ? "top-[57px]"
                                                            : ""
                                                } ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                        ? "h-[181px]"
                                                        : screenWidth >= 1440
                                                            ? "h-[220px]"
                                                            : ""
                                                }`}
                                            >
                                                <div
                                                    className={`relative ${
                                                        (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                            ? "h-[181px]"
                                                            : screenWidth >= 1440
                                                                ? "h-[220px]"
                                                                : ""
                                                    }`}
                                                >
                                                    <div
                                                        className={`left-0 top-0 absolute ${
                                                            (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                ? "w-[181px]"
                                                                : screenWidth >= 1440
                                                                    ? "w-[220px]"
                                                                    : ""
                                                        } ${
                                                            (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                ? "h-[181px]"
                                                                : screenWidth >= 1440
                                                                    ? "h-[220px]"
                                                                    : ""
                                                        }`}
                                                    >
                                                        <div
                                                            className={`-left-px -top-px relative ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "w-[183px]"
                                                                    : screenWidth >= 1440
                                                                        ? "w-[221px]"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "h-[183px]"
                                                                    : screenWidth >= 1440
                                                                        ? "h-[221px]"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "rounded-[91.58px]"
                                                                    : screenWidth >= 1440
                                                                        ? "rounded-[110.69px]"
                                                                        : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`left-px top-px bg-[100%_100%] absolute ${
                                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                        ? "w-[181px]"
                                                                        : screenWidth >= 1440
                                                                            ? "w-[220px]"
                                                                            : ""
                                                                } ${
                                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                        ? "h-[181px]"
                                                                        : screenWidth >= 1440
                                                                            ? "h-[220px]"
                                                                            : ""
                                                                } ${
                                                                    screenWidth < 810
                                                                        ? "bg-[url(/static/img/union-4.svg)]"
                                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                                            ? "bg-[url(/static/img/union-14.svg)]"
                                                                            : screenWidth >= 1440
                                                                                ? "bg-[url(/static/img/union-19.svg)]"
                                                                                : ""
                                                                }`}
                                                            >
                                                                <div
                                                                    className={`border-[#c2aee5] opacity-50 relative ${
                                                                        (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                            ? "border-[0.99px] border-solid"
                                                                            : screenWidth >= 1440
                                                                                ? "border-[1.2px] border-solid"
                                                                                : ""
                                                                    } ${
                                                                        (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                            ? "w-[150px]"
                                                                            : screenWidth >= 1440
                                                                                ? "w-[181px]"
                                                                                : ""
                                                                    } ${
                                                                        (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                            ? "left-4"
                                                                            : screenWidth >= 1440
                                                                                ? "left-5"
                                                                                : ""
                                                                    } ${
                                                                        (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                            ? "top-4"
                                                                            : screenWidth >= 1440
                                                                                ? "top-5"
                                                                                : ""
                                                                    } ${
                                                                        (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                            ? "h-[150px]"
                                                                            : screenWidth >= 1440
                                                                                ? "h-[181px]"
                                                                                : ""
                                                                    } ${
                                                                        (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                            ? "rounded-[74.91px]"
                                                                            : screenWidth >= 1440
                                                                                ? "rounded-[90.43px]"
                                                                                : ""
                                                                    }`}
                                                                />
                                                            </div>
                                                            <div
                                                                className={`border-[#87849b] left-0 opacity-30 top-0 absolute ${
                                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                        ? "border-[0.99px] border-solid"
                                                                        : screenWidth >= 1440
                                                                            ? "border-[1.2px] border-solid"
                                                                            : ""
                                                                } ${
                                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                        ? "w-[183px]"
                                                                        : screenWidth >= 1440
                                                                            ? "w-[221px]"
                                                                            : ""
                                                                } ${
                                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                        ? "h-[183px]"
                                                                        : screenWidth >= 1440
                                                                            ? "h-[221px]"
                                                                            : ""
                                                                } ${
                                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                        ? "rounded-[91.58px]"
                                                                        : screenWidth >= 1440
                                                                            ? "rounded-[110.69px]"
                                                                            : ""
                                                                }`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <img
                                                        className={`absolute ${
                                                            (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                ? "w-[103px]"
                                                                : screenWidth >= 1440
                                                                    ? "w-[125px]"
                                                                    : ""
                                                        } ${
                                                            (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                ? "left-[39px]"
                                                                : screenWidth >= 1440
                                                                    ? "left-12"
                                                                    : ""
                                                        } ${
                                                            (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                ? "top-[43px]"
                                                                : screenWidth >= 1440
                                                                    ? "top-[53px]"
                                                                    : ""
                                                        } ${
                                                            (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                ? "h-[95px]"
                                                                : screenWidth >= 1440
                                                                    ? "h-[115px]"
                                                                    : ""
                                                        }`}
                                                        alt="Img"
                                                        src={
                                                            screenWidth < 810
                                                                ? "/static/img/image.svg"
                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "/static/img/11.svg"
                                                                    : screenWidth >= 1440
                                                                        ? "/static/img/15-2.svg"
                                                                        : undefined
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="w-full flex self-stretch flex-col items-start gap-[15px] flex-[0_0_auto] px-2.5 py-0 justify-center relative">
                                            <div
                                                className="[font-family:'Aeonik-Medium',Helvetica] w-fit mt-[-1.00px] tracking-[-0.22px] text-[22px] text-[#f4f4f6] relative font-medium whitespace-nowrap leading-[24.2px]">
                                                Priority access
                                            </div>
                                            <p className="[font-family:'Aeonik-Regular',Helvetica] self-stretch tracking-[0] text-lg text-[#9897a3] relative font-normal leading-[23.4px]">
                                                $TINC stakers receive exclusive early access to fundraising rounds,
                                                securing unique
                                                opportunities to acquire tokens ahead of the crowd
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={`flex flex-col items-start gap-[15px] relative ${screenWidth < 810 ? "w-full" : ""} ${
                                            screenWidth < 810 ? "self-stretch" : ""
                                        } ${(screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 ? "grow" : ""} ${
                                            screenWidth < 810
                                                ? "flex-[0_0_auto]"
                                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                    ? "flex-1"
                                                    : ""
                                        }`}
                                    >
                                        <div
                                            className={`w-full self-stretch overflow-hidden bg-[#87849b12] relative ${
                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                    ? "h-[275.23px]"
                                                    : screenWidth >= 1440
                                                        ? "h-[334.5px]"
                                                        : ""
                                            } ${
                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                    ? "rounded-[3.29px]"
                                                    : screenWidth >= 1440
                                                        ? "rounded"
                                                        : ""
                                            } ${screenWidth < 810 ? "[-webkit-backdrop-filter:blur(30.4px)_brightness(100%)]" : ""} ${
                                                screenWidth < 810 ? "backdrop-blur-[30.4px] backdrop-brightness-[100%]" : ""
                                            }`}
                                        >
                                            <div
                                                className={`relative ${
                                                    screenWidth < 810
                                                        ? "w-[382px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "w-[271px]"
                                                            : screenWidth >= 1440
                                                                ? "w-[326px]"
                                                                : ""
                                                } ${
                                                    screenWidth < 810 ? "-left-2.5" : screenWidth >= 1200 && screenWidth < 1440 ? "-left-0.5" : ""
                                                } ${
                                                    screenWidth < 810
                                                        ? "top-[62px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "top-[63px]"
                                                            : screenWidth >= 1440
                                                                ? "top-[75px]"
                                                                : ""
                                                } ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                        ? "h-[150px]"
                                                        : screenWidth >= 1440
                                                            ? "h-[184px]"
                                                            : ""
                                                }`}
                                            >
                                                {((screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810) && (
                                                    <div
                                                        className={`h-[151px] relative ${
                                                            screenWidth < 810
                                                                ? "w-[361px]"
                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "w-[266px]"
                                                                    : ""
                                                        } ${
                                                            screenWidth < 810
                                                                ? "left-2.5"
                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                    ? "left-0.5"
                                                                    : ""
                                                        }`}
                                                    >
                                                        <img
                                                            className={`left-0 top-[75px] h-px absolute ${
                                                                screenWidth < 810
                                                                    ? "w-[180px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "w-[134px]"
                                                                        : ""
                                                            }`}
                                                            alt="Vector"
                                                            src={
                                                                screenWidth < 810
                                                                    ? "/static/img/vector-1094-1.svg"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "/static/img/vector-1094-5.svg"
                                                                        : undefined
                                                            }
                                                        />
                                                        <img
                                                            className={`top-[75px] h-px absolute ${
                                                                screenWidth < 810
                                                                    ? "w-[181px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "w-[133px]"
                                                                        : ""
                                                            } ${
                                                                screenWidth < 810
                                                                    ? "left-[180px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[134px]"
                                                                        : ""
                                                            }`}
                                                            alt="Vector"
                                                            src={
                                                                screenWidth < 810
                                                                    ? "/static/img/vector-1095-1.svg"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "/static/img/vector-1095-3.svg"
                                                                        : undefined
                                                            }
                                                        />
                                                        <div
                                                            className={`w-[7px] top-[72px] h-[7px] bg-[#c2aee5] absolute ${
                                                                screenWidth < 810
                                                                    ? "left-44"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[130px]"
                                                                        : ""
                                                            }`}
                                                        />
                                                        <img
                                                            className={`w-[109px] top-2 h-[134px] absolute ${
                                                                screenWidth < 810
                                                                    ? "left-[125px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[78px]"
                                                                        : ""
                                                            }`}
                                                            alt="Vector"
                                                            src={
                                                                screenWidth < 810
                                                                    ? "/static/img/vector-2.svg"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "/static/img/vector-10.svg"
                                                                        : undefined
                                                            }
                                                        />
                                                        <img
                                                            className={`w-[119px] top-0 h-[151px] absolute ${
                                                                screenWidth < 810
                                                                    ? "left-[120px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[73px]"
                                                                        : ""
                                                            }`}
                                                            alt="Rectangle"
                                                            src={
                                                                screenWidth < 810
                                                                    ? "/static/img/rectangle-23207.svg"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "/static/img/rectangle-23207-2.svg"
                                                                        : undefined
                                                            }
                                                        />
                                                    </div>
                                                )}

                                                {screenWidth >= 1440 && (
                                                    <>
                                                        <img
                                                            className="w-[163px] h-px top-[91px] left-0 absolute object-cover"
                                                            alt="Vector"
                                                            src="/static/img/vector-1094-7.svg"
                                                        />
                                                        <img
                                                            className="absolute w-[163px] h-px top-[91px] left-[163px] object-cover"
                                                            alt="Vector"
                                                            src="/static/img/vector-1095-4.svg"
                                                        />
                                                        <div
                                                            className="absolute w-[9px] h-[9px] top-[87px] left-[158px] bg-[#c2aee5]"/>
                                                        <img
                                                            className="absolute w-[133px] h-[163px] top-2.5 left-[97px]"
                                                            alt="Vector"
                                                            src="/static/img/vector-14.svg"
                                                        />
                                                        <img
                                                            className="absolute w-36 h-[184px] top-0 left-[91px]"
                                                            alt="Rectangle"
                                                            src="/static/img/rectangle-23207-3.svg"
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className="w-full flex self-stretch flex-col items-start gap-[15px] flex-[0_0_auto] px-2.5 py-0 justify-center relative">
                                            <div
                                                className="[font-family:'Aeonik-Medium',Helvetica] w-fit mt-[-1.00px] tracking-[-0.22px] text-[22px] text-[#f4f4f6] relative font-medium whitespace-nowrap leading-[24.2px]">
                                                Smart contract security
                                            </div>
                                            <p className="[font-family:'Aeonik-Regular',Helvetica] self-stretch tracking-[0] text-lg text-[#9897a3] relative font-normal leading-[23.4px]">
                                                Fundraising and token distributions are fully secured by audited smart
                                                contracts,
                                                ensuring
                                                transparency and automation.
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={`flex flex-col items-start gap-[15px] relative ${screenWidth < 810 ? "w-full" : ""} ${
                                            screenWidth < 810 ? "self-stretch" : ""
                                        } ${(screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 ? "grow" : ""} ${
                                            screenWidth < 810
                                                ? "flex-[0_0_auto]"
                                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                    ? "flex-1"
                                                    : ""
                                        }`}
                                    >
                                        <div
                                            className={`w-full self-stretch overflow-hidden bg-[#87849b12] relative ${
                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                    ? "h-[275.23px]"
                                                    : screenWidth >= 1440
                                                        ? "h-[334.5px]"
                                                        : ""
                                            } ${
                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                    ? "rounded-[3.29px]"
                                                    : screenWidth >= 1440
                                                        ? "rounded"
                                                        : ""
                                            } ${screenWidth < 810 ? "[-webkit-backdrop-filter:blur(30.4px)_brightness(100%)]" : ""} ${
                                                screenWidth < 810 ? "backdrop-blur-[30.4px] backdrop-brightness-[100%]" : ""
                                            }`}
                                        >
                                            <div
                                                className={`relative ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                        ? "w-[178px]"
                                                        : screenWidth >= 1440
                                                            ? "w-[216px]"
                                                            : ""
                                                } ${
                                                    screenWidth < 810
                                                        ? "left-[92px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "left-[50px]"
                                                            : screenWidth >= 1440
                                                                ? "left-[63px]"
                                                                : ""
                                                } ${
                                                    screenWidth < 810
                                                        ? "top-[52px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "top-[51px]"
                                                            : screenWidth >= 1440
                                                                ? "top-[62px]"
                                                                : ""
                                                } ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                        ? "h-[172px]"
                                                        : screenWidth >= 1440
                                                            ? "h-[209px]"
                                                            : ""
                                                }`}
                                            >
                                                <div
                                                    className={`relative ${
                                                        (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                            ? "h-[172px]"
                                                            : screenWidth >= 1440
                                                                ? "h-[209px]"
                                                                : ""
                                                    }`}
                                                >
                                                    <div
                                                        className={`left-[3px] opacity-80 top-0.5 absolute ${
                                                            (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                ? "w-[172px]"
                                                                : screenWidth >= 1440
                                                                    ? "w-[209px]"
                                                                    : ""
                                                        } ${
                                                            (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                ? "h-[169px]"
                                                                : screenWidth >= 1440
                                                                    ? "h-[206px]"
                                                                    : ""
                                                        }`}
                                                    >
                                                        <img
                                                            className={`-top-px absolute ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "w-[173px]"
                                                                    : screenWidth >= 1440
                                                                        ? "w-[211px]"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "left-0"
                                                                    : screenWidth >= 1440
                                                                        ? "-left-px"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "h-[171px]"
                                                                    : screenWidth >= 1440
                                                                        ? "h-52"
                                                                        : ""
                                                            }`}
                                                            alt="Group"
                                                            src={
                                                                screenWidth < 810
                                                                    ? "/static/img/group-1321316129.png"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "/static/img/group-1321316129-2.png"
                                                                        : screenWidth >= 1440
                                                                            ? "/static/img/group-1321316129-3.png"
                                                                            : undefined
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={`left-0 top-0 absolute ${
                                                            (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                ? "w-[178px]"
                                                                : screenWidth >= 1440
                                                                    ? "w-[216px]"
                                                                    : ""
                                                        } ${
                                                            (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                ? "h-[172px]"
                                                                : screenWidth >= 1440
                                                                    ? "h-[209px]"
                                                                    : ""
                                                        }`}
                                                    >
                                                        <div
                                                            className={`h-1 absolute ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "w-[7px]"
                                                                    : screenWidth >= 1440
                                                                        ? "w-2"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "left-[29px]"
                                                                    : screenWidth >= 1440
                                                                        ? "left-[35px]"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "top-7"
                                                                    : screenWidth >= 1440
                                                                        ? "top-[34px]"
                                                                        : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`w-1 top-0 h-1 bg-[#c2aee5] absolute ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[3px]"
                                                                        : screenWidth >= 1440 || screenWidth < 810
                                                                            ? "left-0.5"
                                                                            : ""
                                                                }`}
                                                            >
                                                                {screenWidth >= 1200 && screenWidth < 1440 && (
                                                                    <img className="absolute w-px h-1 top-0 left-[3px]"
                                                                         alt="Img"
                                                                         src="/static/img/61.svg"/>
                                                                )}
                                                            </div>
                                                            <img
                                                                className="w-px left-0 top-0 h-1 absolute"
                                                                alt="Img"
                                                                src={
                                                                    screenWidth < 810
                                                                        ? "/static/img/image-3.svg"
                                                                        : screenWidth >= 1440
                                                                            ? "/static/img/90.svg"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "/static/img/60.svg"
                                                                                : undefined
                                                                }
                                                            />
                                                            {(screenWidth >= 1440 || screenWidth < 810) && (
                                                                <img
                                                                    className={`w-px top-0 h-1 absolute ${
                                                                        screenWidth < 810 ? "left-1.5" : screenWidth >= 1440 ? "left-[7px]" : ""
                                                                    }`}
                                                                    alt="Img"
                                                                    src={
                                                                        screenWidth < 810 ? "/static/img/1-4.svg" : screenWidth >= 1440 ? "/static/img/91.svg" : undefined
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                        <img
                                                            className={`h-1 absolute ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "w-[7px]"
                                                                    : screenWidth >= 1440
                                                                        ? "w-2"
                                                                        : ""
                                                            } ${
                                                                screenWidth < 810
                                                                    ? "left-[59px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[60px]"
                                                                        : screenWidth >= 1440
                                                                            ? "left-[71px]"
                                                                            : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "top-[23px]"
                                                                    : screenWidth >= 1440
                                                                        ? "top-7"
                                                                        : ""
                                                            }`}
                                                            alt="Vector"
                                                            src={
                                                                screenWidth < 810
                                                                    ? "/static/img/vector-3.svg"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "/static/img/vector-11.svg"
                                                                        : screenWidth >= 1440
                                                                            ? "/static/img/vector-15.svg"
                                                                            : undefined
                                                            }
                                                        />
                                                        <div
                                                            className={`top-0 h-1 absolute ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "w-[7px]"
                                                                    : screenWidth >= 1440
                                                                        ? "w-2"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "left-[83px]"
                                                                    : screenWidth >= 1440
                                                                        ? "left-[101px]"
                                                                        : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`w-1 top-0 h-1 bg-[#c2aee5] absolute ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[3px]"
                                                                        : screenWidth >= 1440 || screenWidth < 810
                                                                            ? "left-0.5"
                                                                            : ""
                                                                }`}
                                                            >
                                                                {screenWidth >= 1200 && screenWidth < 1440 && (
                                                                    <img className="absolute w-px h-1 top-0 left-[3px]"
                                                                         alt="Img"
                                                                         src="/static/img/63.svg"/>
                                                                )}
                                                            </div>
                                                            <img
                                                                className="w-px left-0 top-0 h-1 absolute"
                                                                alt="Img"
                                                                src={
                                                                    screenWidth < 810
                                                                        ? "/static/img/2-5.svg"
                                                                        : screenWidth >= 1440
                                                                            ? "/static/img/92.svg"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "/static/img/62.svg"
                                                                                : undefined
                                                                }
                                                            />
                                                            {(screenWidth >= 1440 || screenWidth < 810) && (
                                                                <img
                                                                    className={`w-px top-0 h-1 absolute ${
                                                                        screenWidth < 810 ? "left-1.5" : screenWidth >= 1440 ? "left-[7px]" : ""
                                                                    }`}
                                                                    alt="Img"
                                                                    src={
                                                                        screenWidth < 810 ? "/static/img/3-4.svg" : screenWidth >= 1440 ? "/static/img/93.svg" : undefined
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`h-1 absolute ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "w-[7px]"
                                                                    : screenWidth >= 1440
                                                                        ? "w-2"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "left-[113px]"
                                                                    : screenWidth >= 1440
                                                                        ? "left-[137px]"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "top-[15px]"
                                                                    : screenWidth >= 1440
                                                                        ? "top-[19px]"
                                                                        : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`w-1 top-0 h-1 bg-[#c2aee5] absolute ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[3px]"
                                                                        : screenWidth >= 1440 || screenWidth < 810
                                                                            ? "left-0.5"
                                                                            : ""
                                                                }`}
                                                            >
                                                                {screenWidth >= 1200 && screenWidth < 1440 && (
                                                                    <img className="absolute w-px h-1 top-0 left-[3px]"
                                                                         alt="Img"
                                                                         src="/static/img/65.svg"/>
                                                                )}
                                                            </div>
                                                            <img
                                                                className="w-px left-0 top-0 h-1 absolute"
                                                                alt="Img"
                                                                src={
                                                                    screenWidth < 810
                                                                        ? "/static/img/4-5.svg"
                                                                        : screenWidth >= 1440
                                                                            ? "/static/img/94.svg"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "/static/img/64.svg"
                                                                                : undefined
                                                                }
                                                            />
                                                            {(screenWidth >= 1440 || screenWidth < 810) && (
                                                                <img
                                                                    className={`w-px top-0 h-1 absolute ${
                                                                        screenWidth < 810 ? "left-1.5" : screenWidth >= 1440 ? "left-[7px]" : ""
                                                                    }`}
                                                                    alt="Img"
                                                                    src={
                                                                        screenWidth < 810 ? "/static/img/5.svg" : screenWidth >= 1440 ? "/static/img/95.svg" : undefined
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`h-1 absolute ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "w-[7px]"
                                                                    : screenWidth >= 1440
                                                                        ? "w-2"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "left-[125px]"
                                                                    : screenWidth >= 1440
                                                                        ? "left-[151px]"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "top-[35px]"
                                                                    : screenWidth >= 1440
                                                                        ? "top-[43px]"
                                                                        : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`w-1 top-0 h-1 bg-[#c2aee5] absolute ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[3px]"
                                                                        : screenWidth >= 1440 || screenWidth < 810
                                                                            ? "left-0.5"
                                                                            : ""
                                                                }`}
                                                            >
                                                                {screenWidth >= 1200 && screenWidth < 1440 && (
                                                                    <img className="absolute w-px h-1 top-0 left-[3px]"
                                                                         alt="Img"
                                                                         src="/static/img/67.svg"/>
                                                                )}
                                                            </div>
                                                            <img
                                                                className="w-px left-0 top-0 h-1 absolute"
                                                                alt="Img"
                                                                src={
                                                                    screenWidth < 810
                                                                        ? "/static/img/6.svg"
                                                                        : screenWidth >= 1440
                                                                            ? "/static/img/96.svg"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "/static/img/66.svg"
                                                                                : undefined
                                                                }
                                                            />
                                                            {(screenWidth >= 1440 || screenWidth < 810) && (
                                                                <img
                                                                    className={`w-px top-0 h-1 absolute ${
                                                                        screenWidth < 810 ? "left-1.5" : screenWidth >= 1440 ? "left-[7px]" : ""
                                                                    }`}
                                                                    alt="Img"
                                                                    src={
                                                                        screenWidth < 810 ? "/static/img/7.svg" : screenWidth >= 1440 ? "/static/img/97.svg" : undefined
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`h-1 absolute ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "w-[7px]"
                                                                    : screenWidth >= 1440
                                                                        ? "w-2"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "left-[143px]"
                                                                    : screenWidth >= 1440
                                                                        ? "left-[174px]"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "top-[27px]"
                                                                    : screenWidth >= 1440
                                                                        ? "top-8"
                                                                        : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`w-1 top-0 h-1 bg-[#c2aee5] absolute ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[3px]"
                                                                        : screenWidth >= 1440 || screenWidth < 810
                                                                            ? "left-0.5"
                                                                            : ""
                                                                }`}
                                                            >
                                                                {screenWidth >= 1200 && screenWidth < 1440 && (
                                                                    <img className="absolute w-px h-1 top-0 left-[3px]"
                                                                         alt="Img"
                                                                         src="/static/img/69.svg"/>
                                                                )}
                                                            </div>
                                                            <img
                                                                className="w-px left-0 top-0 h-1 absolute"
                                                                alt="Img"
                                                                src={
                                                                    screenWidth < 810
                                                                        ? "/static/img/8-2.svg"
                                                                        : screenWidth >= 1440
                                                                            ? "/static/img/98.svg"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "/static/img/68.svg"
                                                                                : undefined
                                                                }
                                                            />
                                                            {(screenWidth >= 1440 || screenWidth < 810) && (
                                                                <img
                                                                    className={`w-px top-0 h-1 absolute ${
                                                                        screenWidth < 810 ? "left-1.5" : screenWidth >= 1440 ? "left-[7px]" : ""
                                                                    }`}
                                                                    alt="Img"
                                                                    src={
                                                                        screenWidth < 810 ? "/static/img/9.svg" : screenWidth >= 1440 ? "/static/img/99.svg" : undefined
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`h-1 absolute ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "w-[7px]"
                                                                    : screenWidth >= 1440
                                                                        ? "w-2"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "left-[156px]"
                                                                    : screenWidth >= 1440
                                                                        ? "left-[189px]"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "top-24"
                                                                    : screenWidth >= 1440
                                                                        ? "top-[117px]"
                                                                        : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`w-1 top-0 h-1 bg-[#c2aee5] absolute ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[3px]"
                                                                        : screenWidth >= 1440 || screenWidth < 810
                                                                            ? "left-0.5"
                                                                            : ""
                                                                }`}
                                                            >
                                                                {screenWidth >= 1200 && screenWidth < 1440 && (
                                                                    <img className="absolute w-px h-1 top-0 left-[3px]"
                                                                         alt="Img"
                                                                         src="/static/img/71.svg"/>
                                                                )}
                                                            </div>
                                                            <img
                                                                className="w-px left-0 top-0 h-1 absolute"
                                                                alt="Img"
                                                                src={
                                                                    screenWidth < 810
                                                                        ? "/static/img/10.svg"
                                                                        : screenWidth >= 1440
                                                                            ? "/static/img/100.svg"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "/static/img/70.svg"
                                                                                : undefined
                                                                }
                                                            />
                                                            {(screenWidth >= 1440 || screenWidth < 810) && (
                                                                <img
                                                                    className={`w-px top-0 h-1 absolute ${
                                                                        screenWidth < 810 ? "left-1.5" : screenWidth >= 1440 ? "left-[7px]" : ""
                                                                    }`}
                                                                    alt="Img"
                                                                    src={
                                                                        screenWidth < 810
                                                                            ? "/static/img/11-2.svg"
                                                                            : screenWidth >= 1440
                                                                                ? "/static/img/101.svg"
                                                                                : undefined
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`h-1 absolute ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "w-[7px]"
                                                                    : screenWidth >= 1440
                                                                        ? "w-2"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "left-[171px]"
                                                                    : screenWidth >= 1440
                                                                        ? "left-52"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "top-[72px]"
                                                                    : screenWidth >= 1440
                                                                        ? "top-[88px]"
                                                                        : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`w-1 top-0 h-1 bg-[#c2aee5] absolute ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[3px]"
                                                                        : screenWidth >= 1440 || screenWidth < 810
                                                                            ? "left-0.5"
                                                                            : ""
                                                                }`}
                                                            >
                                                                {screenWidth >= 1200 && screenWidth < 1440 && (
                                                                    <img className="absolute w-px h-1 top-0 left-[3px]"
                                                                         alt="Img"
                                                                         src="/static/img/73.svg"/>
                                                                )}
                                                            </div>
                                                            <img
                                                                className="w-px left-0 top-0 h-1 absolute"
                                                                alt="Img"
                                                                src={
                                                                    screenWidth < 810
                                                                        ? "/static/img/12-2.svg"
                                                                        : screenWidth >= 1440
                                                                            ? "/static/img/102.svg"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "/static/img/72.svg"
                                                                                : undefined
                                                                }
                                                            />
                                                            {(screenWidth >= 1440 || screenWidth < 810) && (
                                                                <img
                                                                    className={`w-px top-0 h-1 absolute ${
                                                                        screenWidth < 810 ? "left-1.5" : screenWidth >= 1440 ? "left-[7px]" : ""
                                                                    }`}
                                                                    alt="Img"
                                                                    src={
                                                                        screenWidth < 810
                                                                            ? "/static/img/13-2.svg"
                                                                            : screenWidth >= 1440
                                                                                ? "/static/img/103.svg"
                                                                                : undefined
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`h-1 absolute ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "w-[7px]"
                                                                    : screenWidth >= 1440
                                                                        ? "w-2"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "left-[141px]"
                                                                    : screenWidth >= 1440
                                                                        ? "left-[171px]"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "top-[131px]"
                                                                    : screenWidth >= 1440
                                                                        ? "top-[159px]"
                                                                        : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`w-1 top-0 h-1 bg-[#c2aee5] absolute ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[3px]"
                                                                        : screenWidth >= 1440 || screenWidth < 810
                                                                            ? "left-0.5"
                                                                            : ""
                                                                }`}
                                                            >
                                                                {screenWidth >= 1200 && screenWidth < 1440 && (
                                                                    <img className="absolute w-px h-1 top-0 left-[3px]"
                                                                         alt="Img"
                                                                         src="/static/img/75.svg"/>
                                                                )}
                                                            </div>
                                                            <img
                                                                className="w-px left-0 top-0 h-1 absolute"
                                                                alt="Img"
                                                                src={
                                                                    screenWidth < 810
                                                                        ? "/static/img/14-2.svg"
                                                                        : screenWidth >= 1440
                                                                            ? "/static/img/104.svg"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "/static/img/74.svg"
                                                                                : undefined
                                                                }
                                                            />
                                                            {(screenWidth >= 1440 || screenWidth < 810) && (
                                                                <img
                                                                    className={`w-px top-0 h-1 absolute ${
                                                                        screenWidth < 810 ? "left-1.5" : screenWidth >= 1440 ? "left-[7px]" : ""
                                                                    }`}
                                                                    alt="Img"
                                                                    src={
                                                                        screenWidth < 810 ? "/static/img/15.svg" : screenWidth >= 1440 ? "/static/img/105.svg" : undefined
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`h-1 absolute ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "w-[7px]"
                                                                    : screenWidth >= 1440
                                                                        ? "w-2"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "left-[101px]"
                                                                    : screenWidth >= 1440
                                                                        ? "left-[123px]"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "top-[153px]"
                                                                    : screenWidth >= 1440
                                                                        ? "top-[186px]"
                                                                        : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`w-1 top-0 h-1 bg-[#c2aee5] absolute ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[3px]"
                                                                        : screenWidth >= 1440 || screenWidth < 810
                                                                            ? "left-0.5"
                                                                            : ""
                                                                }`}
                                                            >
                                                                {screenWidth >= 1200 && screenWidth < 1440 && (
                                                                    <img className="absolute w-px h-1 top-0 left-[3px]"
                                                                         alt="Img"
                                                                         src="/static/img/77.svg"/>
                                                                )}
                                                            </div>
                                                            <img
                                                                className="w-px left-0 top-0 h-1 absolute"
                                                                alt="Img"
                                                                src={
                                                                    screenWidth < 810
                                                                        ? "/static/img/16.svg"
                                                                        : screenWidth >= 1440
                                                                            ? "/static/img/106.svg"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "/static/img/76.svg"
                                                                                : undefined
                                                                }
                                                            />
                                                            {(screenWidth >= 1440 || screenWidth < 810) && (
                                                                <img
                                                                    className={`w-px top-0 h-1 absolute ${
                                                                        screenWidth < 810 ? "left-1.5" : screenWidth >= 1440 ? "left-[7px]" : ""
                                                                    }`}
                                                                    alt="Img"
                                                                    src={
                                                                        screenWidth < 810 ? "/static/img/17.svg" : screenWidth >= 1440 ? "/static/img/107.svg" : undefined
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`h-1 absolute ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "w-[7px]"
                                                                    : screenWidth >= 1440
                                                                        ? "w-2"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "left-[89px]"
                                                                    : screenWidth >= 1440
                                                                        ? "left-[108px]"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "top-[168px]"
                                                                    : screenWidth >= 1440
                                                                        ? "top-[204px]"
                                                                        : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`w-1 top-0 h-1 bg-[#c2aee5] absolute ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[3px]"
                                                                        : screenWidth >= 1440 || screenWidth < 810
                                                                            ? "left-0.5"
                                                                            : ""
                                                                }`}
                                                            >
                                                                {screenWidth >= 1200 && screenWidth < 1440 && (
                                                                    <img className="absolute w-px h-1 top-0 left-[3px]"
                                                                         alt="Img"
                                                                         src="/static/img/79.svg"/>
                                                                )}
                                                            </div>
                                                            <img
                                                                className="w-px left-0 top-0 h-1 absolute"
                                                                alt="Img"
                                                                src={
                                                                    screenWidth < 810
                                                                        ? "/static/img/18.svg"
                                                                        : screenWidth >= 1440
                                                                            ? "/static/img/108.svg"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "/static/img/78.svg"
                                                                                : undefined
                                                                }
                                                            />
                                                            {(screenWidth >= 1440 || screenWidth < 810) && (
                                                                <img
                                                                    className={`w-px top-0 h-1 absolute ${
                                                                        screenWidth < 810 ? "left-1.5" : screenWidth >= 1440 ? "left-[7px]" : ""
                                                                    }`}
                                                                    alt="Img"
                                                                    src={
                                                                        screenWidth < 810 ? "/static/img/19.svg" : screenWidth >= 1440 ? "/static/img/109.svg" : undefined
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`h-1 absolute ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "w-[7px]"
                                                                    : screenWidth >= 1440
                                                                        ? "w-2"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "left-[30px]"
                                                                    : screenWidth >= 1440
                                                                        ? "left-[37px]"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "top-[140px]"
                                                                    : screenWidth >= 1440
                                                                        ? "top-[170px]"
                                                                        : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`w-1 top-0 h-1 bg-[#c2aee5] absolute ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[3px]"
                                                                        : screenWidth >= 1440 || screenWidth < 810
                                                                            ? "left-0.5"
                                                                            : ""
                                                                }`}
                                                            >
                                                                {screenWidth >= 1200 && screenWidth < 1440 && (
                                                                    <img className="absolute w-px h-1 top-0 left-[3px]"
                                                                         alt="Img"
                                                                         src="/static/img/81.svg"/>
                                                                )}
                                                            </div>
                                                            <img
                                                                className="w-px left-0 top-0 h-1 absolute"
                                                                alt="Img"
                                                                src={
                                                                    screenWidth < 810
                                                                        ? "/static/img/20.svg"
                                                                        : screenWidth >= 1440
                                                                            ? "/static/img/110.svg"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "/static/img/80.svg"
                                                                                : undefined
                                                                }
                                                            />
                                                            {(screenWidth >= 1440 || screenWidth < 810) && (
                                                                <img
                                                                    className={`w-px top-0 h-1 absolute ${
                                                                        screenWidth < 810 ? "left-1.5" : screenWidth >= 1440 ? "left-[7px]" : ""
                                                                    }`}
                                                                    alt="Img"
                                                                    src={
                                                                        screenWidth < 810 ? "/static/img/21.svg" : screenWidth >= 1440 ? "/static/img/111.svg" : undefined
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`left-0 h-1 absolute ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "w-[7px]"
                                                                    : screenWidth >= 1440
                                                                        ? "w-2"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "top-24"
                                                                    : screenWidth >= 1440
                                                                        ? "top-[117px]"
                                                                        : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`w-1 top-0 h-1 bg-[#c2aee5] absolute ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[3px]"
                                                                        : screenWidth >= 1440 || screenWidth < 810
                                                                            ? "left-0.5"
                                                                            : ""
                                                                }`}
                                                            >
                                                                {screenWidth >= 1200 && screenWidth < 1440 && (
                                                                    <img className="absolute w-px h-1 top-0 left-[3px]"
                                                                         alt="Img"
                                                                         src="/static/img/83.svg"/>
                                                                )}
                                                            </div>
                                                            <img
                                                                className="w-px left-0 top-0 h-1 absolute"
                                                                alt="Img"
                                                                src={
                                                                    screenWidth < 810
                                                                        ? "/static/img/22.svg"
                                                                        : screenWidth >= 1440
                                                                            ? "/static/img/112.svg"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "/static/img/82.svg"
                                                                                : undefined
                                                                }
                                                            />
                                                            {(screenWidth >= 1440 || screenWidth < 810) && (
                                                                <img
                                                                    className={`w-px top-0 h-1 absolute ${
                                                                        screenWidth < 810 ? "left-1.5" : screenWidth >= 1440 ? "left-[7px]" : ""
                                                                    }`}
                                                                    alt="Img"
                                                                    src={
                                                                        screenWidth < 810 ? "/static/img/23.svg" : screenWidth >= 1440 ? "/static/img/113.svg" : undefined
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`h-1 absolute ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "w-[7px]"
                                                                    : screenWidth >= 1440
                                                                        ? "w-2"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "left-[22px]"
                                                                    : screenWidth >= 1440
                                                                        ? "left-[27px]"
                                                                        : ""
                                                            } ${
                                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                                    ? "top-[74px]"
                                                                    : screenWidth >= 1440
                                                                        ? "top-[90px]"
                                                                        : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`w-1 top-0 h-1 bg-[#c2aee5] absolute ${
                                                                    screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "left-[3px]"
                                                                        : screenWidth >= 1440 || screenWidth < 810
                                                                            ? "left-0.5"
                                                                            : ""
                                                                }`}
                                                            >
                                                                {screenWidth >= 1200 && screenWidth < 1440 && (
                                                                    <img className="absolute w-px h-1 top-0 left-[3px]"
                                                                         alt="Img"
                                                                         src="/static/img/85.svg"/>
                                                                )}
                                                            </div>
                                                            <img
                                                                className="w-px left-0 top-0 h-1 absolute"
                                                                alt="Img"
                                                                src={
                                                                    screenWidth < 810
                                                                        ? "/static/img/24.svg"
                                                                        : screenWidth >= 1440
                                                                            ? "/static/img/114.svg"
                                                                            : screenWidth >= 1200 && screenWidth < 1440
                                                                                ? "/static/img/84.svg"
                                                                                : undefined
                                                                }
                                                            />
                                                            {(screenWidth >= 1440 || screenWidth < 810) && (
                                                                <img
                                                                    className={`w-px top-0 h-1 absolute ${
                                                                        screenWidth < 810 ? "left-1.5" : screenWidth >= 1440 ? "left-[7px]" : ""
                                                                    }`}
                                                                    alt="Img"
                                                                    src={
                                                                        screenWidth < 810 ? "/static/img/25.svg" : screenWidth >= 1440 ? "/static/img/115.svg" : undefined
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="w-full flex self-stretch flex-col items-start gap-[15px] flex-[0_0_auto] px-2.5 py-0 justify-center relative">
                                            <div
                                                className="[font-family:'Aeonik-Medium',Helvetica] w-fit mt-[-1.00px] tracking-[-0.22px] text-[22px] text-[#f4f4f6] relative font-medium whitespace-nowrap leading-[24.2px]">
                                                Bittensor ecosystem
                                            </div>
                                            <p className="[font-family:'Aeonik-Regular',Helvetica] self-stretch tracking-[0] text-lg text-[#9897a3] relative font-normal leading-[23.4px]">
                                                Tao Incubator connects projects to Bittensor&#39;s decentralized
                                                intelligence,
                                                offering a
                                                secure
                                                and advanced space for innovation.
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={`flex flex-col items-start gap-[15px] relative ${screenWidth < 810 ? "w-full" : ""} ${
                                            screenWidth < 810 ? "self-stretch" : ""
                                        } ${(screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 ? "grow" : ""} ${
                                            screenWidth < 810
                                                ? "flex-[0_0_auto]"
                                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                                    ? "flex-1"
                                                    : ""
                                        }`}
                                    >
                                        <div
                                            className={`w-full self-stretch overflow-hidden bg-[#87849b12] relative ${
                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                    ? "h-[275.23px]"
                                                    : screenWidth >= 1440
                                                        ? "h-[334.5px]"
                                                        : ""
                                            } ${
                                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                    ? "rounded-[3.29px]"
                                                    : screenWidth >= 1440
                                                        ? "rounded"
                                                        : ""
                                            } ${screenWidth < 810 ? "[-webkit-backdrop-filter:blur(30.4px)_brightness(100%)]" : ""} ${
                                                screenWidth < 810 ? "backdrop-blur-[30.4px] backdrop-brightness-[100%]" : ""
                                            }`}
                                        >
                                            <div
                                                className={`relative ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                        ? "w-[130px]"
                                                        : screenWidth >= 1440
                                                            ? "w-[158px]"
                                                            : ""
                                                } ${
                                                    screenWidth < 810
                                                        ? "left-[116px]"
                                                        : screenWidth >= 1200 && screenWidth < 1440
                                                            ? "left-[69px]"
                                                            : screenWidth >= 1440
                                                                ? "left-[84px]"
                                                                : ""
                                                } ${(screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810 ? "top-[-26px]" : ""} ${
                                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                                        ? "h-[234px]"
                                                        : screenWidth >= 1440
                                                            ? "h-[253px]"
                                                            : ""
                                                }`}
                                            >
                                                {((screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810) && (
                                                    <div className="top-[26px] h-52 relative">
                                                        <img
                                                            className={`w-px left-3.5 top-0 absolute ${
                                                                screenWidth < 810
                                                                    ? "h-[189px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "h-[188px]"
                                                                        : ""
                                                            }`}
                                                            alt="Vector"
                                                            src={
                                                                screenWidth < 810
                                                                    ? "/static/img/vector-1094-2.svg"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "/static/img/vector-1094-6.svg"
                                                                        : undefined
                                                            }
                                                        />
                                                        <div className="w-[130px] left-0 top-[169px] h-[39px] absolute">
                                                            <div
                                                                className="w-[132px] -left-px -top-px h-[41px] rounded-[0.82px] relative">
                                                                <div
                                                                    className="w-[9px] left-[11px] top-[18px] h-[5px] absolute">
                                                                    <div
                                                                        className="w-[5px] left-0.5 top-0 h-[5px] bg-[#87849b] absolute"/>
                                                                    <img
                                                                        className="w-px left-0 top-0 h-[5px] absolute"
                                                                        alt="Img"
                                                                        src={
                                                                            screenWidth < 810
                                                                                ? "/static/img/26.svg"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "/static/img/86.svg"
                                                                                    : undefined
                                                                        }
                                                                    />
                                                                    <img
                                                                        className="w-px left-2 top-0 h-[5px] absolute"
                                                                        alt="Img"
                                                                        src={
                                                                            screenWidth < 810
                                                                                ? "/static/img/27.svg"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "/static/img/87.svg"
                                                                                    : undefined
                                                                        }
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="border-[0.82px] border-dashed border-[#87849b] w-[132px] left-0 opacity-70 top-0 h-[41px] rounded-[0.82px] absolute"/>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`w-[130px] left-0 h-[39px] absolute ${
                                                                screenWidth < 810
                                                                    ? "top-[68px]"
                                                                    : screenWidth >= 1200 && screenWidth < 1440
                                                                        ? "top-[67px]"
                                                                        : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className="w-[132px] -left-px -top-px h-[41px] rounded-[0.82px] relative">
                                                                <div
                                                                    className="w-[9px] left-[11px] top-[18px] h-[5px] absolute">
                                                                    <div
                                                                        className="w-[5px] left-0.5 top-0 h-[5px] bg-[#c2aee5] absolute"/>
                                                                    <img
                                                                        className="w-px left-0 top-0 h-[5px] absolute"
                                                                        alt="Img"
                                                                        src={
                                                                            screenWidth < 810
                                                                                ? "/static/img/28.svg"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "/static/img/88.svg"
                                                                                    : undefined
                                                                        }
                                                                    />
                                                                    <img
                                                                        className="w-px left-2 top-0 h-[5px] absolute"
                                                                        alt="Img"
                                                                        src={
                                                                            screenWidth < 810
                                                                                ? "/static/img/29.svg"
                                                                                : screenWidth >= 1200 && screenWidth < 1440
                                                                                    ? "/static/img/89.svg"
                                                                                    : undefined
                                                                        }
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="border-[0.82px] border-solid border-[#c2aee5] w-[132px] left-0 top-0 h-[41px] rounded-[0.82px] absolute"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {screenWidth >= 1440 && (
                                                    <>
                                                        <img
                                                            className="w-px h-[229px] top-0 left-[17px] absolute object-cover"
                                                            alt="Vector"
                                                            src="/static/img/vector-1094-8.svg"
                                                        />
                                                        <div className="absolute w-[158px] h-[47px] top-[205px] left-0">
                                                            <div
                                                                className="relative w-40 h-[49px] -top-px -left-px rounded-[1px]">
                                                                <div
                                                                    className="absolute w-[11px] h-1.5 top-[22px] left-[13px]">
                                                                    <div
                                                                        className="w-1.5 h-1.5 bg-[#87849b] absolute top-0 left-0.5"/>
                                                                    <img className="absolute w-px h-1.5 top-0 left-0"
                                                                         alt="Img"
                                                                         src="/static/img/116.svg"/>
                                                                    <img className="absolute w-px h-1.5 top-0 left-2.5"
                                                                         alt="Img"
                                                                         src="/static/img/117.svg"/>
                                                                </div>
                                                                <div
                                                                    className="absolute w-40 h-[49px] top-0 left-0 rounded-[1px] border border-dashed border-[#87849b] opacity-70"/>
                                                            </div>
                                                        </div>
                                                        <div className="absolute w-[158px] h-[47px] top-[82px] left-0">
                                                            <div
                                                                className="relative w-40 h-[49px] -top-px -left-px rounded-[1px]">
                                                                <div
                                                                    className="absolute w-[11px] h-1.5 top-[22px] left-[13px]">
                                                                    <div
                                                                        className="w-1.5 h-1.5 bg-[#c2aee5] absolute top-0 left-0.5"/>
                                                                    <img className="absolute w-px h-1.5 top-0 left-0"
                                                                         alt="Img"
                                                                         src="/static/img/118.svg"/>
                                                                    <img className="absolute w-px h-1.5 top-0 left-2.5"
                                                                         alt="Img"
                                                                         src="/static/img/119.svg"/>
                                                                </div>
                                                                <div
                                                                    className="absolute w-40 h-[49px] top-0 left-0 rounded-[1px] border border-solid border-[#c2aee5]"/>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className="w-full flex self-stretch flex-col items-start gap-[15px] flex-[0_0_auto] px-2.5 py-0 justify-center relative">
                                            <div
                                                className="[font-family:'Aeonik-Medium',Helvetica] w-fit mt-[-1.00px] tracking-[-0.22px] text-[22px] text-[#f4f4f6] relative font-medium whitespace-nowrap leading-[24.2px]">
                                                Structured phases
                                            </div>
                                            <p className="[font-family:'Aeonik-Regular',Helvetica] self-stretch tracking-[0] text-lg text-[#9897a3] relative font-normal leading-[23.4px]">
                                                Two investment phases: a priority round for $TINC holders and a public
                                                round, with all
                                                investments tracked for fair distribution.
                                            </p>
                                        </div>
                                    </div>
                                </>
                            )}

                            {screenWidth >= 810 && screenWidth < 1200 && (
                                <>
                                    <div
                                        className="flex items-start gap-[7px] relative self-stretch w-full flex-[0_0_auto]">
                                        <div className="flex flex-col items-start gap-[15px] relative flex-1 grow">
                                            <div
                                                className="h-[275.23px] bg-[#87849b12] rounded-[3.29px] relative self-stretch w-full overflow-hidden">
                                                <div className="relative w-[181px] h-[181px] top-[47px] left-[100px]">
                                                    <div className="relative h-[181px]">
                                                        <div className="absolute w-[181px] h-[181px] top-0 left-0">
                                                            <div
                                                                className="relative w-[183px] h-[183px] -top-px -left-px rounded-[91.58px]">
                                                                <div
                                                                    className="absolute w-[181px] h-[181px] top-px left-px bg-[url(/static/img/union-9.svg)] bg-[100%_100%]">
                                                                    <div
                                                                        className="relative w-[150px] h-[150px] top-4 left-4 rounded-[74.91px] border-[0.99px] border-solid border-[#c2aee5] opacity-50"/>
                                                                </div>
                                                                <div
                                                                    className="absolute w-[183px] h-[183px] top-0 left-0 rounded-[91.58px] border-[0.99px] border-solid border-[#87849b] opacity-30"/>
                                                            </div>
                                                        </div>
                                                        <img
                                                            className="absolute w-[103px] h-[95px] top-[43px] left-[39px]"
                                                            alt="Img"
                                                            src="/static/img/7-4.svg"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="justify-center gap-[15px] px-2.5 py-0 relative self-stretch w-full flex-[0_0_auto] flex flex-col items-start">
                                                <div
                                                    className="relative w-fit mt-[-1.00px] [font-family:'Aeonik-Medium',Helvetica] font-medium text-[#f4f4f6] text-xl tracking-[-0.20px] leading-[22.0px] whitespace-nowrap">
                                                    Priority access
                                                </div>
                                                <p className="relative self-stretch [font-family:'Aeonik-Regular',Helvetica] font-normal text-[#9897a3] text-lg tracking-[0] leading-[23.4px]">
                                                    $TINC stakers receive exclusive early access to fundraising rounds,
                                                    securing
                                                    unique
                                                    opportunities to acquire tokens ahead of the crowd
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-start gap-[15px] relative flex-1 grow">
                                            <div
                                                className="h-[275.23px] bg-[#87849b12] rounded-[3.29px] relative self-stretch w-full overflow-hidden">
                                                <div className="relative w-[382px] h-[150px] top-[63px]">
                                                    <div className="relative h-[151px]">
                                                        <img
                                                            className="absolute w-[190px] h-px top-[75px] left-0"
                                                            alt="Vector"
                                                            src="/static/img/vector-1094-3.svg"
                                                        />
                                                        <img
                                                            className="absolute w-[191px] h-px top-[75px] left-[190px]"
                                                            alt="Vector"
                                                            src="/static/img/vector-1095-2.svg"
                                                        />
                                                        <div
                                                            className="h-[7px] top-[72px] left-[187px] bg-[#c2aee5] absolute w-[7px]"/>
                                                        <img
                                                            className="absolute w-[109px] h-[134px] top-2 left-[135px]"
                                                            alt="Vector"
                                                            src="/static/img/vector-6.svg"
                                                        />
                                                        <img
                                                            className="absolute w-[119px] h-[151px] top-0 left-[130px]"
                                                            alt="Rectangle"
                                                            src="/static/img/rectangle-23207-1.svg"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="justify-center gap-[15px] px-2.5 py-0 relative self-stretch w-full flex-[0_0_auto] flex flex-col items-start">
                                                <div
                                                    className="relative w-fit mt-[-1.00px] [font-family:'Aeonik-Medium',Helvetica] font-medium text-[#f4f4f6] text-xl tracking-[-0.20px] leading-[22.0px] whitespace-nowrap">
                                                    Smart contract security
                                                </div>
                                                <p className="relative self-stretch [font-family:'Aeonik-Regular',Helvetica] font-normal text-[#9897a3] text-lg tracking-[0] leading-[23.4px]">
                                                    Fundraising and token distributions are fully secured by audited
                                                    smart contracts,
                                                    ensuring
                                                    transparency and automation.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="flex items-start gap-[7px] relative self-stretch w-full flex-[0_0_auto]">
                                        <div className="flex flex-col items-start gap-[15px] relative flex-1 grow">
                                            <div
                                                className="h-[275.23px] bg-[#87849b12] rounded-[3.29px] relative self-stretch w-full overflow-hidden">
                                                <div className="relative w-[178px] h-[172px] top-[52px] left-[102px]">
                                                    <div className="relative h-[172px]">
                                                        <div
                                                            className="absolute w-[172px] h-[169px] top-0.5 left-[3px] opacity-80">
                                                            <img
                                                                className="absolute w-[173px] h-[171px] -top-px left-0"
                                                                alt="Group"
                                                                src="/static/img/group-1321316129-1.png"
                                                            />
                                                        </div>
                                                        <div className="absolute w-[178px] h-[172px] top-0 left-0">
                                                            <div className="absolute w-[7px] h-1 top-7 left-[29px]">
                                                                <div
                                                                    className="w-1 h-1 bg-[#c2aee5] absolute top-0 left-0.5"/>
                                                                <img className="absolute w-px h-1 top-0 left-0"
                                                                     alt="Img"
                                                                     src="/static/img/30.svg"/>
                                                                <img className="absolute w-px h-1 top-0 left-1.5"
                                                                     alt="Img"
                                                                     src="/static/img/31.svg"/>
                                                            </div>
                                                            <img
                                                                className="absolute w-[7px] h-1 top-[23px] left-[59px]"
                                                                alt="Vector"
                                                                src="/static/img/vector-7.svg"
                                                            />
                                                            <div className="absolute w-[7px] h-1 top-0 left-[83px]">
                                                                <div
                                                                    className="w-1 h-1 bg-[#c2aee5] absolute top-0 left-0.5"/>
                                                                <img className="absolute w-px h-1 top-0 left-0"
                                                                     alt="Img"
                                                                     src="/static/img/32.svg"/>
                                                                <img className="absolute w-px h-1 top-0 left-1.5"
                                                                     alt="Img"
                                                                     src="/static/img/33.svg"/>
                                                            </div>
                                                            <div
                                                                className="absolute w-[7px] h-1 top-[15px] left-[113px]">
                                                                <div
                                                                    className="w-1 h-1 bg-[#c2aee5] absolute top-0 left-0.5"/>
                                                                <img className="absolute w-px h-1 top-0 left-0"
                                                                     alt="Img"
                                                                     src="/static/img/34.svg"/>
                                                                <img className="absolute w-px h-1 top-0 left-1.5"
                                                                     alt="Img"
                                                                     src="/static/img/35.svg"/>
                                                            </div>
                                                            <div
                                                                className="absolute w-[7px] h-1 top-[35px] left-[125px]">
                                                                <div
                                                                    className="w-1 h-1 bg-[#c2aee5] absolute top-0 left-0.5"/>
                                                                <img className="absolute w-px h-1 top-0 left-0"
                                                                     alt="Img"
                                                                     src="/static/img/36.svg"/>
                                                                <img className="absolute w-px h-1 top-0 left-1.5"
                                                                     alt="Img"
                                                                     src="/static/img/37.svg"/>
                                                            </div>
                                                            <div
                                                                className="absolute w-[7px] h-1 top-[27px] left-[143px]">
                                                                <div
                                                                    className="w-1 h-1 bg-[#c2aee5] absolute top-0 left-0.5"/>
                                                                <img className="absolute w-px h-1 top-0 left-0"
                                                                     alt="Img"
                                                                     src="/static/img/38.svg"/>
                                                                <img className="absolute w-px h-1 top-0 left-1.5"
                                                                     alt="Img"
                                                                     src="/static/img/39.svg"/>
                                                            </div>
                                                            <div className="absolute w-[7px] h-1 top-24 left-[156px]">
                                                                <div
                                                                    className="w-1 h-1 bg-[#c2aee5] absolute top-0 left-0.5"/>
                                                                <img className="absolute w-px h-1 top-0 left-0"
                                                                     alt="Img"
                                                                     src="/static/img/40.svg"/>
                                                                <img className="absolute w-px h-1 top-0 left-1.5"
                                                                     alt="Img"
                                                                     src="/static/img/41.svg"/>
                                                            </div>
                                                            <div
                                                                className="absolute w-[7px] h-1 top-[72px] left-[171px]">
                                                                <div
                                                                    className="w-1 h-1 bg-[#c2aee5] absolute top-0 left-0.5"/>
                                                                <img className="absolute w-px h-1 top-0 left-0"
                                                                     alt="Img"
                                                                     src="/static/img/42.svg"/>
                                                                <img className="absolute w-px h-1 top-0 left-1.5"
                                                                     alt="Img"
                                                                     src="/static/img/43.svg"/>
                                                            </div>
                                                            <div
                                                                className="absolute w-[7px] h-1 top-[131px] left-[141px]">
                                                                <div
                                                                    className="w-1 h-1 bg-[#c2aee5] absolute top-0 left-0.5"/>
                                                                <img className="absolute w-px h-1 top-0 left-0"
                                                                     alt="Img"
                                                                     src="/static/img/44.svg"/>
                                                                <img className="absolute w-px h-1 top-0 left-1.5"
                                                                     alt="Img"
                                                                     src="/static/img/45.svg"/>
                                                            </div>
                                                            <div
                                                                className="absolute w-[7px] h-1 top-[153px] left-[101px]">
                                                                <div
                                                                    className="w-1 h-1 bg-[#c2aee5] absolute top-0 left-0.5"/>
                                                                <img className="absolute w-px h-1 top-0 left-0"
                                                                     alt="Img"
                                                                     src="/static/img/46.svg"/>
                                                                <img className="absolute w-px h-1 top-0 left-1.5"
                                                                     alt="Img"
                                                                     src="/static/img/47.svg"/>
                                                            </div>
                                                            <div
                                                                className="absolute w-[7px] h-1 top-[168px] left-[89px]">
                                                                <div
                                                                    className="w-1 h-1 bg-[#c2aee5] absolute top-0 left-0.5"/>
                                                                <img className="absolute w-px h-1 top-0 left-0"
                                                                     alt="Img"
                                                                     src="/static/img/48.svg"/>
                                                                <img className="absolute w-px h-1 top-0 left-1.5"
                                                                     alt="Img"
                                                                     src="/static/img/49.svg"/>
                                                            </div>
                                                            <div
                                                                className="absolute w-[7px] h-1 top-[140px] left-[30px]">
                                                                <div
                                                                    className="w-1 h-1 bg-[#c2aee5] absolute top-0 left-0.5"/>
                                                                <img className="absolute w-px h-1 top-0 left-0"
                                                                     alt="Img"
                                                                     src="/static/img/50.svg"/>
                                                                <img className="absolute w-px h-1 top-0 left-1.5"
                                                                     alt="Img"
                                                                     src="/static/img/51.svg"/>
                                                            </div>
                                                            <div className="absolute w-[7px] h-1 top-24 left-0">
                                                                <div
                                                                    className="w-1 h-1 bg-[#c2aee5] absolute top-0 left-0.5"/>
                                                                <img className="absolute w-px h-1 top-0 left-0"
                                                                     alt="Img"
                                                                     src="/static/img/52.svg"/>
                                                                <img className="absolute w-px h-1 top-0 left-1.5"
                                                                     alt="Img"
                                                                     src="/static/img/53.svg"/>
                                                            </div>
                                                            <div
                                                                className="h-1 top-[74px] left-[22px] absolute w-[7px]">
                                                                <div
                                                                    className="w-1 h-1 bg-[#c2aee5] absolute top-0 left-0.5"/>
                                                                <img className="absolute w-px h-1 top-0 left-0"
                                                                     alt="Img"
                                                                     src="/static/img/54.svg"/>
                                                                <img className="absolute w-px h-1 top-0 left-1.5"
                                                                     alt="Img"
                                                                     src="/static/img/55.svg"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="justify-center gap-[15px] px-2.5 py-0 relative self-stretch w-full flex-[0_0_auto] flex flex-col items-start">
                                                <div
                                                    className="relative w-fit mt-[-1.00px] [font-family:'Aeonik-Medium',Helvetica] font-medium text-[#f4f4f6] text-xl tracking-[-0.20px] leading-[22.0px] whitespace-nowrap">
                                                    Bittensor ecosystem
                                                </div>
                                                <p className="relative self-stretch [font-family:'Aeonik-Regular',Helvetica] font-normal text-[#9897a3] text-lg tracking-[0] leading-[23.4px]">
                                                    Tao Incubator connects projects to Bittensor&#39;s decentralized
                                                    intelligence,
                                                    offering a
                                                    secure and advanced space for innovation.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-start gap-[15px] relative flex-1 grow">
                                            <div
                                                className="h-[275.23px] bg-[#87849b12] rounded-[3.29px] relative self-stretch w-full overflow-hidden">
                                                <div className="relative w-[130px] h-[234px] top-[-26px] left-[125px]">
                                                    <div className="relative h-52 top-[26px]">
                                                        <img
                                                            className="absolute w-px h-[188px] top-0 left-3.5"
                                                            alt="Vector"
                                                            src="/static/img/vector-1094-4.svg"
                                                        />
                                                        <div className="absolute w-[130px] h-[39px] top-[169px] left-0">
                                                            <div
                                                                className="relative w-[132px] h-[41px] -top-px -left-px rounded-[0.82px]">
                                                                <div
                                                                    className="absolute w-[9px] h-[5px] top-[18px] left-[11px]">
                                                                    <div
                                                                        className="w-[5px] h-[5px] bg-[#87849b] absolute top-0 left-0.5"/>
                                                                    <img className="absolute w-px h-[5px] top-0 left-0"
                                                                         alt="Img"
                                                                         src="/static/img/56.svg"/>
                                                                    <img className="absolute w-px h-[5px] top-0 left-2"
                                                                         alt="Img"
                                                                         src="/static/img/57.svg"/>
                                                                </div>
                                                                <div
                                                                    className="absolute w-[132px] h-[41px] top-0 left-0 rounded-[0.82px] border-[0.82px] border-dashed border-[#87849b] opacity-70"/>
                                                            </div>
                                                        </div>
                                                        <div className="absolute w-[130px] h-[39px] top-[67px] left-0">
                                                            <div
                                                                className="relative w-[132px] h-[41px] -top-px -left-px rounded-[0.82px]">
                                                                <div
                                                                    className="absolute w-[9px] h-[5px] top-[18px] left-[11px]">
                                                                    <div
                                                                        className="w-[5px] h-[5px] bg-[#c2aee5] absolute top-0 left-0.5"/>
                                                                    <img className="absolute w-px h-[5px] top-0 left-0"
                                                                         alt="Img"
                                                                         src="/static/img/58.svg"/>
                                                                    <img className="absolute w-px h-[5px] top-0 left-2"
                                                                         alt="Img"
                                                                         src="/static/img/59.svg"/>
                                                                </div>
                                                                <div
                                                                    className="absolute w-[132px] h-[41px] top-0 left-0 rounded-[0.82px] border-[0.82px] border-solid border-[#c2aee5]"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="justify-center gap-[15px] px-2.5 py-0 relative self-stretch w-full flex-[0_0_auto] flex flex-col items-start">
                                                <div
                                                    className="relative w-fit mt-[-1.00px] [font-family:'Aeonik-Medium',Helvetica] font-medium text-[#f4f4f6] text-xl tracking-[-0.20px] leading-[22.0px] whitespace-nowrap">
                                                    Structured phases
                                                </div>
                                                <p className="relative self-stretch [font-family:'Aeonik-Regular',Helvetica] font-normal text-[#9897a3] text-lg tracking-[0] leading-[23.4px]">
                                                    Two investment phases: a priority round for $TINC holders and a
                                                    public round, with
                                                    all
                                                    investments tracked for fair distribution.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className={`flex flex-col bg-[#87849b12] absolute ${
                        screenWidth < 810
                            ? "w-[390px]"
                            : screenWidth >= 810 && screenWidth < 1200
                                ? "w-[809px]"
                                : screenWidth >= 1200 && screenWidth < 1440
                                    ? "w-[1160px]"
                                    : screenWidth >= 1440
                                        ? "w-[1397px]"
                                        : ""
                    } ${
                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                            ? "left-0"
                            : screenWidth >= 1200 && screenWidth < 1440
                                ? "left-5"
                                : screenWidth >= 1440
                                    ? "left-[22px]"
                                    : ""
                    } ${
                        screenWidth < 810
                            ? "items-center"
                            : (screenWidth >= 1200 && screenWidth < 1440) ||
                            screenWidth >= 1440 ||
                            (screenWidth >= 810 && screenWidth < 1200)
                                ? "items-start"
                                : ""
                    } ${
                        screenWidth < 810
                            ? "top-[7496px]"
                            : screenWidth >= 810 && screenWidth < 1200
                                ? "top-[6552px]"
                                : screenWidth >= 1200 && screenWidth < 1440
                                    ? "top-[6322px]"
                                    : screenWidth >= 1440
                                        ? "top-[6577px]"
                                        : ""
                    } ${
                        screenWidth < 810
                            ? "gap-[37px]"
                            : screenWidth >= 810 && screenWidth < 1200
                                ? "gap-[60px]"
                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                    ? "gap-[82px]"
                                    : ""
                    } ${
                        screenWidth < 810
                            ? "pt-[30px] pb-[50px] px-[15px]"
                            : screenWidth >= 810 && screenWidth < 1200
                                ? "pt-[30px] pb-[50px] px-5"
                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                    ? "px-[33px] py-[30px]"
                                    : ""
                    } ${
                        (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                            ? "overflow-hidden"
                            : ""
                    } ${
                        (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                            ? "rounded-[5px]"
                            : ""
                    } ${screenWidth < 810 ? "justify-center" : ""}`}
                >
                    <div
                        className={`flex flex-[0_0_auto] relative ${
                            (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                ? "w-full"
                                : (screenWidth >= 1200 && screenWidth < 1440)
                                    ? "w-[834px]"
                                    : screenWidth >= 1440
                                        ? "w-[1334px]"
                                        : ""
                        } ${(screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810 ? "self-stretch" : ""} ${
                            screenWidth < 810 ? "flex-col" : ""
                        } ${
                            screenWidth < 810
                                ? "items-start"
                                : (screenWidth >= 1200 && screenWidth < 1440) ||
                                screenWidth >= 1440 ||
                                (screenWidth >= 810 && screenWidth < 1200)
                                    ? "items-center"
                                    : ""
                        } ${
                            screenWidth >= 1200 && screenWidth < 1440 ? "mr-[-241.00px]" : screenWidth >= 1440 ? "mr-[-4.00px]" : ""
                        } ${screenWidth < 810 ? "gap-[25px]" : ""} ${
                            screenWidth < 810
                                ? "justify-center"
                                : (screenWidth >= 1200 && screenWidth < 1440) ||
                                screenWidth >= 1440 ||
                                (screenWidth >= 810 && screenWidth < 1200)
                                    ? "justify-between"
                                    : ""
                        }`}
                    >
                        <div
                            className={`items-center flex-[0_0_auto] relative ${screenWidth < 810 ? "w-full" : ""} ${
                                screenWidth < 810
                                    ? "flex"
                                    : (screenWidth >= 1200 && screenWidth < 1440) ||
                                    screenWidth >= 1440 ||
                                    (screenWidth >= 810 && screenWidth < 1200)
                                        ? "inline-flex"
                                        : ""
                            } ${screenWidth < 810 ? "self-stretch" : ""} ${
                                (screenWidth >= 1200 && screenWidth < 1440) ||
                                screenWidth >= 1440 ||
                                (screenWidth >= 810 && screenWidth < 1200)
                                    ? "gap-8"
                                    : ""
                            } ${
                                screenWidth < 810
                                    ? "justify-between"
                                    : (screenWidth >= 1200 && screenWidth < 1440) ||
                                    screenWidth >= 1440 ||
                                    (screenWidth >= 810 && screenWidth < 1200)
                                        ? "justify-center"
                                        : ""
                            }`}
                        >
                            {screenWidth < 810 && (
                                <>
                                    <img
                                        className="relative w-[185.34px] h-[18.81px]"
                                        alt="Tao incubator"
                                        src="/static/img/tao-incubator-logomark.svg"
                                    />
                                    <div
                                        className="inline-flex items-center justify-center gap-[27px] relative flex-[0_0_auto]">
                                        <Twitter2 className="!relative !w-[23px] !h-[23px]"/>
                                        <Telegram2 className="!relative !w-[23px] !h-[23px]"/>
                                        <GitHub className="!relative !w-[23px] !h-[23px]" color="#E8E7EC"/>
                                    </div>
                                </>
                            )}

                            {((screenWidth >= 1200 && screenWidth < 1440) || (screenWidth >= 810 && screenWidth < 1200)) && (
                                <Twitter2 className="!relative !w-[23px] !h-[23px]"/>
                            )}

                            {screenWidth >= 810 && screenWidth < 1200 &&
                                <Telegram1 className="!relative !w-[23px] !h-[23px]"/>}

                            {screenWidth >= 1200 && screenWidth < 1440 &&
                                <Telegram2 className="!relative !w-[23px] !h-[23px]"/>}

                            {((screenWidth >= 1200 && screenWidth < 1440) || (screenWidth >= 810 && screenWidth < 1200)) && (
                                <GitHub className="!relative !w-[23px] !h-[23px]" color="#E8E7EC"/>
                            )}

                            {screenWidth >= 1440 && (
                                <>
                                    <Twitter className="!relative !w-[23px] !h-[23px]" color="#E8E7EC"/>
                                    <Telegram3 className="!relative !w-[23px] !h-[23px]" color="#E8E7EC"/>
                                    <GitHub className="!relative !w-[23px] !h-[23px]" color="#E8E7EC"/>
                                </>
                            )}
                        </div>
                        <div
                            className={`gap-5 flex-[0_0_auto] relative ${screenWidth < 810 ? "w-full" : ""} ${
                                screenWidth < 810
                                    ? "flex"
                                    : (screenWidth >= 1200 && screenWidth < 1440) ||
                                    screenWidth >= 1440 ||
                                    (screenWidth >= 810 && screenWidth < 1200)
                                        ? "inline-flex"
                                        : ""
                            } ${screenWidth < 810 ? "self-stretch" : ""} ${screenWidth < 810 ? "flex-col" : ""} ${
                                screenWidth < 810
                                    ? "items-start"
                                    : (screenWidth >= 1200 && screenWidth < 1440) ||
                                    screenWidth >= 1440 ||
                                    (screenWidth >= 810 && screenWidth < 1200)
                                        ? "items-center"
                                        : ""
                            } ${screenWidth < 810 ? "justify-center" : ""}`}
                        >
                            {screenWidth < 810 && (
                                <>
                                    <img
                                        className="relative self-stretch w-full h-px mt-[-0.50px]"
                                        alt="Vector"
                                        src="/static/img/vector-1098.svg"
                                    />
                                    <PlaceHolderButton property1="default"/>
                                    <img className="relative self-stretch w-full h-px" alt="Vector"
                                         src="/static/img/vector-1098.svg"/>
                                </>
                            )}

                            {((screenWidth >= 1200 && screenWidth < 1440) ||
                                screenWidth >= 1440 ||
                                (screenWidth >= 810 && screenWidth < 1200)) && (
                                <>
                                    <PlaceHolderButton property1="default" text="About"/>
                                    <img
                                        className="w-px h-[22.08px] relative"
                                        alt="Vector"
                                        src={
                                            screenWidth >= 810 && screenWidth < 1200
                                                ? "/static/img/vector-1093-1.svg"
                                                : screenWidth >= 1200 && screenWidth < 1440
                                                    ? "/static/img/vector-1091-2.svg"
                                                    : screenWidth >= 1440
                                                        ? "/static/img/vector-1093-3.svg"
                                                        : undefined
                                        }
                                    />
                                </>
                            )}

                            <PlaceHolderButton className="!w-16" divClassName="!left-px" property1="default"
                                               text="Features"/>
                            {((screenWidth >= 1200 && screenWidth < 1440) ||
                                screenWidth >= 1440 ||
                                (screenWidth >= 810 && screenWidth < 1200)) && (
                                <>
                                    <img
                                        className="w-px h-[22.08px] relative"
                                        alt="Vector"
                                        src={
                                            screenWidth >= 810 && screenWidth < 1200
                                                ? "/static/img/vector-1093-1.svg"
                                                : screenWidth >= 1200 && screenWidth < 1440
                                                    ? "/static/img/vector-1091-2.svg"
                                                    : screenWidth >= 1440
                                                        ? "/static/img/vector-1093-3.svg"
                                                        : undefined
                                        }
                                    />
                                    <PlaceHolderButton className="!w-[71px]" property1="default" text="Roadmap"/>
                                    <img
                                        className={`w-px h-[22.08px] relative ${
                                            screenWidth >= 1200 && screenWidth < 1440 ? "mt-[-2509.46px]" : ""
                                        } ${screenWidth >= 1200 && screenWidth < 1440 ? "ml-[-2567.39px]" : ""}`}
                                        alt="Vector"
                                        src={
                                            screenWidth >= 810 && screenWidth < 1200
                                                ? "/static/img/vector-1093-1.svg"
                                                : screenWidth >= 1200 && screenWidth < 1440
                                                    ? "/static/img/vector-1093-2.png"
                                                    : screenWidth >= 1440
                                                        ? "/static/img/vector-1093-3.svg"
                                                        : undefined
                                        }
                                    />
                                    <PlaceHolderButton className="!w-[82px]" property1="default" text="Launchpad"/>
                                    <img
                                        className={`w-px h-[22.08px] relative ${
                                            screenWidth >= 1200 && screenWidth < 1440 ? "mt-[-2509.46px]" : ""
                                        } ${screenWidth >= 1200 && screenWidth < 1440 ? "ml-[-2445.39px]" : ""}`}
                                        alt="Vector"
                                        src={
                                            screenWidth >= 810 && screenWidth < 1200
                                                ? "/static/img/vector-1093-1.svg"
                                                : screenWidth >= 1200 && screenWidth < 1440
                                                    ? "/static/img/vector-1093-2.png"
                                                    : screenWidth >= 1440
                                                        ? "/static/img/vector-1093-3.svg"
                                                        : undefined
                                        }
                                    />
                                    <PlaceHolderButton className="!w-[58px]" property1="default" text="Contact"/>
                                </>
                            )}

                            {screenWidth < 810 && (
                                <>
                                    <img className="relative self-stretch w-full h-px" alt="Vector"
                                         src="/static/img/vector-1098.svg"/>
                                    <PlaceHolderButton className="!w-[71px]" property1="default" text="Roadmap"/>
                                    <img className="relative self-stretch w-full h-px" alt="Vector"
                                         src="/static/img/vector-1098.svg"/>
                                    <PlaceHolderButton className="!w-[82px]" property1="default" text="Launchpad"/>
                                    <img className="relative self-stretch w-full h-px" alt="Vector"
                                         src="/static/img/vector-1098.svg"/>
                                    <PlaceHolderButton className="!w-[58px]" property1="default" text="Contact"/>
                                    <img
                                        className="relative self-stretch w-full h-px mb-[-0.50px]"
                                        alt="Vector"
                                        src="/static/img/vector-1098.svg"
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <img
                        className={`relative ${
                            (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                ? "w-full"
                                : screenWidth >= 1200 && screenWidth < 1440
                                    ? "w-[1115px]"
                                    : screenWidth >= 1440
                                        ? "w-[1334.72px]"
                                        : ""
                        } ${(screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810 ? "self-stretch" : ""} ${
                            screenWidth >= 1200 && screenWidth < 1440 ? "mr-[-21.00px]" : screenWidth >= 1440 ? "mr-[-3.72px]" : ""
                        } ${
                            screenWidth < 810
                                ? "h-[350.93px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "h-[78.05px]"
                                    : screenWidth >= 1200 && screenWidth < 1440
                                        ? "h-[113.16px]"
                                        : screenWidth >= 1440
                                            ? "h-[135.46px]"
                                            : ""
                        }`}
                        alt="Tao incubator logo"
                        src={
                            screenWidth < 810
                                ? "/static/img/tao-incubator-logo.svg"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "/static/img/tao-incubator-logo-1.svg"
                                    : screenWidth >= 1200 && screenWidth < 1440
                                        ? "/static/img/tao-incubator-logo-2.svg"
                                        : screenWidth >= 1440
                                            ? "/static/img/tao-incubator-logo-3.svg"
                                            : undefined
                        }
                    />
                    <div
                        className={`relative ${screenWidth < 810 ? "[font-family:'Aeonik-Regular',Helvetica]" : ""} ${
                            screenWidth < 810
                                ? "w-fit"
                                : (screenWidth >= 1200 && screenWidth < 1440) ||
                                screenWidth >= 1440 ||
                                (screenWidth >= 810 && screenWidth < 1200)
                                    ? "w-full"
                                    : ""
                        } ${
                            (screenWidth >= 1200 && screenWidth < 1440) ||
                            screenWidth >= 1440 ||
                            (screenWidth >= 810 && screenWidth < 1200)
                                ? "flex"
                                : ""
                        } ${
                            (screenWidth >= 1200 && screenWidth < 1440) ||
                            screenWidth >= 1440 ||
                            (screenWidth >= 810 && screenWidth < 1200)
                                ? "self-stretch"
                                : ""
                        } ${screenWidth < 810 ? "tracking-[0]" : ""} ${screenWidth < 810 ? "text-base" : ""} ${
                            (screenWidth >= 1200 && screenWidth < 1440) ||
                            screenWidth >= 1440 ||
                            (screenWidth >= 810 && screenWidth < 1200)
                                ? "items-center"
                                : ""
                        } ${screenWidth < 810 ? "text-[#9997a4]" : ""} ${
                            (screenWidth >= 1200 && screenWidth < 1440) ||
                            screenWidth >= 1440 ||
                            (screenWidth >= 810 && screenWidth < 1200)
                                ? "flex-[0_0_auto]"
                                : ""
                        } ${screenWidth < 810 ? "font-normal" : ""} ${screenWidth < 810 ? "text-center" : ""} ${
                            screenWidth < 810 ? "whitespace-nowrap" : ""
                        } ${
                            (screenWidth >= 1200 && screenWidth < 1440) ||
                            screenWidth >= 1440 ||
                            (screenWidth >= 810 && screenWidth < 1200)
                                ? "justify-between"
                                : ""
                        } ${screenWidth < 810 ? "leading-4" : ""}`}
                    >
                        {screenWidth < 810 && <>© TAOINCUBATOR 2024</>}

                        {((screenWidth >= 1200 && screenWidth < 1440) ||
                            screenWidth >= 1440 ||
                            (screenWidth >= 810 && screenWidth < 1200)) && (
                            <>
                                <div
                                    className="[font-family:'Aeonik-Regular',Helvetica] w-fit mt-[-1.00px] tracking-[0] text-base text-[#9997a4] relative font-normal text-center whitespace-nowrap leading-4">
                                    © TAOINCUBATOR 2024
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div
                    className={`absolute ${
                        (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                            ? "w-[1397px]"
                            : screenWidth >= 810 && screenWidth < 1200
                                ? "w-[1723px]"
                                : screenWidth >= 1440
                                    ? "w-[1570px]"
                                    : ""
                    } ${
                        screenWidth < 810
                            ? "left-[-503px]"
                            : screenWidth >= 810 && screenWidth < 1200
                                ? "left-[-457px]"
                                : screenWidth >= 1200 && screenWidth < 1440
                                    ? "left-[-98px]"
                                    : screenWidth >= 1440
                                        ? "left-[-65px]"
                                        : ""
                    } ${
                        screenWidth < 810
                            ? "top-[6732px]"
                            : screenWidth >= 810 && screenWidth < 1200
                                ? "top-[5591px]"
                                : screenWidth >= 1200 && screenWidth < 1440
                                    ? "top-[5558px]"
                                    : screenWidth >= 1440
                                        ? "top-[5699px]"
                                        : ""
                    } ${
                        (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                            ? "h-[763px]"
                            : screenWidth >= 810 && screenWidth < 1200
                                ? "h-[961px]"
                                : screenWidth >= 1440
                                    ? "h-[878px]"
                                    : ""
                    }`}
                >
                    <div
                        className={`relative ${
                            screenWidth >= 810 && screenWidth < 1200 ? "w-[1397px]" : screenWidth >= 1440 ? "w-[1440px]" : ""
                        } ${screenWidth >= 810 && screenWidth < 1200 ? "left-[163px]" : screenWidth >= 1440 ? "left-[65px]" : ""} ${
                            screenWidth >= 1440 ? "bg-[100%_100%]" : ""
                        } ${
                            (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                ? "h-[763px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "h-[961px]"
                                    : screenWidth >= 1440
                                        ? "h-[878px]"
                                        : ""
                        } ${screenWidth >= 1440 ? "bg-[url(/static/img/lines-13.svg)]" : ""}`}
                    >
                        {((screenWidth >= 1200 && screenWidth < 1440) ||
                            (screenWidth >= 810 && screenWidth < 1200) ||
                            screenWidth < 810) && (
                            <img
                                className={`top-0 absolute ${
                                    screenWidth < 810
                                        ? "w-[390px]"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "w-[810px]"
                                            : screenWidth >= 1200 && screenWidth < 1440
                                                ? "w-[1200px]"
                                                : ""
                                } ${
                                    screenWidth < 810
                                        ? "left-[503px]"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "left-[294px]"
                                            : screenWidth >= 1200 && screenWidth < 1440
                                                ? "left-[98px]"
                                                : ""
                                } ${
                                    (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                        ? "h-[763px]"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "h-[961px]"
                                            : ""
                                }`}
                                alt="Lines"
                                src={
                                    screenWidth < 810
                                        ? "/static/img/lines-1.svg"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "/static/img/lines-5.svg"
                                            : screenWidth >= 1200 && screenWidth < 1440
                                                ? "/static/img/lines-9.svg"
                                                : undefined
                                }
                            />
                        )}

                        <div
                            className={`w-[1397px] h-[430px] absolute ${
                                (screenWidth >= 1200 && screenWidth < 1440) ||
                                (screenWidth >= 810 && screenWidth < 1200) ||
                                screenWidth < 810
                                    ? "left-0"
                                    : screenWidth >= 1440
                                        ? "left-[21px]"
                                        : ""
                            } ${
                                (screenWidth >= 1200 && screenWidth < 1440) || screenWidth < 810
                                    ? "top-[334px]"
                                    : screenWidth >= 810 && screenWidth < 1200
                                        ? "top-[531px]"
                                        : screenWidth >= 1440
                                            ? "top-[448px]"
                                            : ""
                            }`}
                        >
                            {((screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810) && (
                                <>
                                    <img
                                        className={`w-0.5 top-[4088px] h-[174px] absolute ${
                                            screenWidth >= 810 && screenWidth < 1200
                                                ? "left-[-2321px]"
                                                : screenWidth < 810
                                                    ? "left-[-4333px]"
                                                    : ""
                                        }`}
                                        alt="Element"
                                        src={
                                            screenWidth >= 810 && screenWidth < 1200
                                                ? "/static/img/7-1.png"
                                                : screenWidth < 810
                                                    ? "/static/img/1.png"
                                                    : undefined
                                        }
                                    />
                                    <div className="w-[1397px] left-0 -top-1 h-[442px] absolute">
                                        <img
                                            className={`top-0 h-[439px] absolute ${
                                                screenWidth >= 810 && screenWidth < 1200 ? "w-[26px]" : screenWidth < 810 ? "w-[21px]" : ""
                                            } ${
                                                screenWidth >= 810 && screenWidth < 1200
                                                    ? "left-[1077px]"
                                                    : screenWidth < 810
                                                        ? "left-[873px]"
                                                        : ""
                                            }`}
                                            alt="Element"
                                            src={
                                                screenWidth >= 810 && screenWidth < 1200
                                                    ? "/static/img/6-1.svg"
                                                    : screenWidth < 810
                                                        ? "/static/img/6-4.svg"
                                                        : undefined
                                            }
                                        />
                                        <img
                                            className={`w-[33px] top-[243px] h-[194px] absolute ${
                                                screenWidth < 810
                                                    ? "left-[773px]"
                                                    : screenWidth >= 810 && screenWidth < 1200
                                                        ? "left-[880px]"
                                                        : ""
                                            }`}
                                            alt="Element"
                                            src={
                                                screenWidth < 810
                                                    ? "/static/img/5-4.svg"
                                                    : screenWidth >= 810 && screenWidth < 1200
                                                        ? "/static/img/5-1.svg"
                                                        : undefined
                                            }
                                        />
                                        <img
                                            className="w-[27px] left-[690px] top-[188px] h-[251px] absolute"
                                            alt="Element"
                                            src={
                                                screenWidth < 810
                                                    ? "/static/img/4.svg"
                                                    : screenWidth >= 810 && screenWidth < 1200
                                                        ? "/static/img/4-1.svg"
                                                        : undefined
                                            }
                                        />
                                        <img
                                            className={`w-[19px] top-[364px] h-[76px] absolute ${
                                                screenWidth < 810
                                                    ? "left-[604px]"
                                                    : screenWidth >= 810 && screenWidth < 1200
                                                        ? "left-[501px]"
                                                        : ""
                                            }`}
                                            alt="Element"
                                            src={
                                                screenWidth < 810
                                                    ? "/static/img/3.svg"
                                                    : screenWidth >= 810 && screenWidth < 1200
                                                        ? "/static/img/3-1.svg"
                                                        : undefined
                                            }
                                        />
                                        <img
                                            className={`w-[19px] top-[61px] h-[382px] absolute ${
                                                screenWidth < 810
                                                    ? "left-[515px]"
                                                    : screenWidth >= 810 && screenWidth < 1200
                                                        ? "left-[309px]"
                                                        : ""
                                            }`}
                                            alt="Element"
                                            src={
                                                screenWidth < 810
                                                    ? "/static/img/2.svg"
                                                    : screenWidth >= 810 && screenWidth < 1200
                                                        ? "/static/img/2-1.svg"
                                                        : undefined
                                            }
                                        />
                                        {screenWidth >= 810 && screenWidth < 1200 && (
                                            <img className="absolute w-1 h-[341px] top-24 left-[294px]" alt="Element"
                                                 src="/static/img/1-1.svg"/>
                                        )}

                                        <div
                                            className="[background:linear-gradient(180deg,rgb(7,6,7)_0%,rgba(7,6,7,0)_100%)] w-[1397px] left-0 opacity-80 top-[261px] h-[173px] absolute"/>
                                    </div>
                                </>
                            )}

                            {screenWidth < 810 && (
                                <img
                                    className="absolute w-[111px] h-[325px] top-[3939px] left-[-5272px]"
                                    alt="Element"
                                    src="/static/img/1.png"
                                />
                            )}

                            {((screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440) && (
                                <div className="-top-1 h-[442px] relative">
                                    <img
                                        className={`w-[19px] top-[245px] h-[190px] absolute ${
                                            screenWidth >= 1200 && screenWidth < 1440
                                                ? "left-[1109px]"
                                                : screenWidth >= 1440
                                                    ? "left-[1197px]"
                                                    : ""
                                        }`}
                                        alt="Element"
                                        src={
                                            screenWidth >= 1200 && screenWidth < 1440
                                                ? "/static/img/7-2.svg"
                                                : screenWidth >= 1440
                                                    ? "/static/img/7-3.svg"
                                                    : undefined
                                        }
                                    />
                                    <img
                                        className={`w-[26px] top-0 h-[439px] absolute ${
                                            screenWidth >= 1200 && screenWidth < 1440
                                                ? "left-[967px]"
                                                : screenWidth >= 1440
                                                    ? "left-[1027px]"
                                                    : ""
                                        }`}
                                        alt="Element"
                                        src={
                                            screenWidth >= 1200 && screenWidth < 1440
                                                ? "/static/img/6-2.svg"
                                                : screenWidth >= 1440
                                                    ? "/static/img/6-3-2.svg"
                                                    : undefined
                                        }
                                    />
                                    <img
                                        className={`w-[33px] top-[243px] h-[194px] absolute ${
                                            screenWidth >= 1200 && screenWidth < 1440
                                                ? "left-[822px]"
                                                : screenWidth >= 1440
                                                    ? "left-[850px]"
                                                    : ""
                                        }`}
                                        alt="Element"
                                        src={
                                            screenWidth >= 1200 && screenWidth < 1440
                                                ? "/static/img/5-2-2.svg"
                                                : screenWidth >= 1440
                                                    ? "/static/img/5-3.svg"
                                                    : undefined
                                        }
                                    />
                                    <img
                                        className="w-[27px] left-[690px] top-[188px] h-[251px] absolute"
                                        alt="Element"
                                        src={
                                            screenWidth >= 1200 && screenWidth < 1440
                                                ? "/static/img/4-2.svg"
                                                : screenWidth >= 1440
                                                    ? "/static/img/4-3.svg"
                                                    : undefined
                                        }
                                    />
                                    <img
                                        className={`w-[19px] top-[364px] h-[76px] absolute ${
                                            screenWidth >= 1200 && screenWidth < 1440
                                                ? "left-[556px]"
                                                : screenWidth >= 1440
                                                    ? "left-[527px]"
                                                    : ""
                                        }`}
                                        alt="Element"
                                        src={
                                            screenWidth >= 1200 && screenWidth < 1440
                                                ? "/static/img/3-2.svg"
                                                : screenWidth >= 1440
                                                    ? "/static/img/3-3.svg"
                                                    : undefined
                                        }
                                    />
                                    <img
                                        className={`w-[19px] top-[61px] h-[382px] absolute ${
                                            screenWidth >= 1200 && screenWidth < 1440
                                                ? "left-[417px]"
                                                : screenWidth >= 1440
                                                    ? "left-[359px]"
                                                    : ""
                                        }`}
                                        alt="Element"
                                        src={
                                            screenWidth >= 1200 && screenWidth < 1440
                                                ? "/static/img/2-2.svg"
                                                : screenWidth >= 1440
                                                    ? "/static/img/2-3.svg"
                                                    : undefined
                                        }
                                    />
                                    <img
                                        className={`w-32 top-24 h-[341px] absolute ${
                                            screenWidth >= 1200 && screenWidth < 1440
                                                ? "left-[170px]"
                                                : screenWidth >= 1440
                                                    ? "left-[85px]"
                                                    : ""
                                        }`}
                                        alt="Element"
                                        src={
                                            screenWidth >= 1200 && screenWidth < 1440
                                                ? "/static/img/1-2.svg"
                                                : screenWidth >= 1440
                                                    ? "/static/img/1-3.svg"
                                                    : undefined
                                        }
                                    />
                                    <div
                                        className="[background:linear-gradient(180deg,rgb(7,6,7)_0%,rgba(7,6,7,0)_100%)] w-[1397px] left-0 opacity-80 top-[261px] h-[173px] absolute"/>
                                </div>
                            )}
                        </div>
                        {((screenWidth >= 1200 && screenWidth < 1440) ||
                            (screenWidth >= 810 && screenWidth < 1200) ||
                            screenWidth < 810) && (
                            <div
                                className={`flex-col items-center gap-[35px] absolute ${screenWidth < 810 ? "w-[361px]" : ""} ${
                                    screenWidth < 810
                                        ? "left-[518px]"
                                        : (screenWidth >= 1200 && screenWidth < 1440) || (screenWidth >= 810 && screenWidth < 1200)
                                            ? "left-[364px]"
                                            : ""
                                } ${
                                    screenWidth < 810
                                        ? "flex"
                                        : (screenWidth >= 1200 && screenWidth < 1440) || (screenWidth >= 810 && screenWidth < 1200)
                                            ? "inline-flex"
                                            : ""
                                } ${
                                    screenWidth < 810
                                        ? "top-[264px]"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "top-[345px]"
                                            : screenWidth >= 1200 && screenWidth < 1440
                                                ? "top-[268px]"
                                                : ""
                                }`}
                            >
                                <p
                                    className={`[font-family:'Aeonik-Medium',Helvetica] mt-[-1.00px] text-[#f4f4f6] relative font-medium ${
                                        (screenWidth >= 1200 && screenWidth < 1440) || (screenWidth >= 810 && screenWidth < 1200)
                                            ? "w-[669.88px]"
                                            : ""
                                    } ${screenWidth < 810 ? "self-stretch" : ""} ${
                                        screenWidth < 810
                                            ? "tracking-[-0.56px]"
                                            : screenWidth >= 810 && screenWidth < 1200
                                                ? "tracking-[-0.70px]"
                                                : screenWidth >= 1200 && screenWidth < 1440
                                                    ? "tracking-[-0.80px]"
                                                    : ""
                                    } ${
                                        screenWidth < 810
                                            ? "text-[56px]"
                                            : screenWidth >= 810 && screenWidth < 1200
                                                ? "text-[70px]"
                                                : screenWidth >= 1200 && screenWidth < 1440
                                                    ? "text-[80px]"
                                                    : ""
                                    } ${
                                        (screenWidth >= 1200 && screenWidth < 1440) || (screenWidth >= 810 && screenWidth < 1200)
                                            ? "text-center"
                                            : ""
                                    } ${
                                        screenWidth < 810
                                            ? "leading-[53.2px]"
                                            : screenWidth >= 810 && screenWidth < 1200
                                                ? "leading-[66.5px]"
                                                : screenWidth >= 1200 && screenWidth < 1440
                                                    ? "leading-[76.0px]"
                                                    : ""
                                    }`}
                                >
                                    Ready to turn your ideas into reality?
                                </p>
                                <div className="inline-flex items-center gap-2.5 flex-[0_0_auto] relative">
                                    <LaunchAProject
                                        className={
                                            screenWidth < 810
                                                ? "!h-[40.5px] !flex-[0_0_auto] !px-[18px] !py-0"
                                                : (screenWidth >= 1200 && screenWidth < 1440) || (screenWidth >= 810 && screenWidth < 1200)
                                                    ? "!h-[40.5px] !flex !w-[183px]"
                                                    : undefined
                                        }
                                        property1="default"
                                        text="Launch a project"
                                    />
                                    <Explore
                                        className={
                                            screenWidth < 810
                                                ? "!h-[40.5px] !w-[175px]"
                                                : (screenWidth >= 1200 && screenWidth < 1440) || (screenWidth >= 810 && screenWidth < 1200)
                                                    ? "!h-[40.5px]"
                                                    : undefined
                                        }
                                        property1="default"
                                        onClick={() => window.location.href = 'https://www.google.com'}
                                    />
                                </div>
                            </div>
                        )}

                        {screenWidth >= 1440 && (
                            <div
                                className="inline-flex flex-col items-center gap-[35px] absolute top-[349px] left-[385px]">
                                <p className="relative w-[669.88px] mt-[-1.00px] [font-family:'Aeonik-Medium',Helvetica] font-medium text-[#f4f4f6] text-[80px] text-center tracking-[-0.80px] leading-[76.0px]">
                                    Ready to turn your ideas into reality?
                                </p>
                                <div className="gap-2.5 inline-flex items-center relative flex-[0_0_auto]">
                                    <LaunchAProject
                                        className="!h-[40.5px] !flex !w-[183px]"
                                        property1="default"
                                        text="Launch a project"
                                    />
                                    <Explore className="!h-[40.5px]" property1="default"/>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={`flex flex-col items-start gap-[37px] overflow-hidden justify-end absolute ${
                        screenWidth < 810
                            ? "w-[391px]"
                            : screenWidth >= 810 && screenWidth < 1200
                                ? "w-[771px]"
                                : screenWidth >= 1200 && screenWidth < 1440
                                    ? "w-[1161px]"
                                    : screenWidth >= 1440
                                        ? "w-[1397px]"
                                        : ""
                    } ${
                        screenWidth < 810
                            ? "left-0"
                            : (screenWidth >= 1200 && screenWidth < 1440) || (screenWidth >= 810 && screenWidth < 1200)
                                ? "left-5"
                                : screenWidth >= 1440
                                    ? "left-[22px]"
                                    : ""
                    } ${
                        (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                            ? "top-[66px]"
                            : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                ? "top-[81px]"
                                : ""
                    } ${
                        screenWidth < 810
                            ? "pt-0 pb-[25px] px-[15px]"
                            : screenWidth >= 810 && screenWidth < 1200
                                ? "pt-0 pb-[27px] px-[22px]"
                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                    ? "pt-0 pb-[37px] px-8"
                                    : ""
                    } ${
                        screenWidth < 810
                            ? "h-[564px]"
                            : screenWidth >= 810 && screenWidth < 1200
                                ? "h-[724px]"
                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                    ? "h-[643px]"
                                    : ""
                    } ${
                        (screenWidth >= 1200 && screenWidth < 1440) ||
                        screenWidth >= 1440 ||
                        (screenWidth >= 810 && screenWidth < 1200)
                            ? "rounded-[5px]"
                            : ""
                    }`}
                >
                    <img
                        className={`left-0 absolute ${
                            screenWidth < 810
                                ? "w-[390px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "w-[771px]"
                                    : screenWidth >= 1200 && screenWidth < 1440
                                        ? "w-[1161px]"
                                        : screenWidth >= 1440
                                            ? "w-[1397px]"
                                            : ""
                        } ${
                            (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 || screenWidth < 810
                                ? "top-0"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "top-[81px]"
                                    : ""
                        } ${
                            screenWidth < 810
                                ? "h-[564px]"
                                : (screenWidth >= 1200 && screenWidth < 1440) ||
                                screenWidth >= 1440 ||
                                (screenWidth >= 810 && screenWidth < 1200)
                                    ? "h-[643px]"
                                    : ""
                        }`}
                        alt="Gradient"
                        src={
                            screenWidth < 810
                                ? "/static/img/gradient.png"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "/static/img/gradient-1.png"
                                    : screenWidth >= 1200 && screenWidth < 1440
                                        ? "/static/img/gradient-2.png"
                                        : screenWidth >= 1440
                                            ? "/static/img/gradient-3.png"
                                            : undefined
                        }
                    />
                    <img
                        className={`left-0 top-0 absolute ${
                            screenWidth < 810
                                ? "w-[390px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "w-[771px]"
                                    : screenWidth >= 1200 && screenWidth < 1440
                                        ? "w-[1161px]"
                                        : screenWidth >= 1440
                                            ? "w-[1397px]"
                                            : ""
                        } ${
                            screenWidth < 810
                                ? "h-[564px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "h-[724px]"
                                    : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                        ? "h-[643px]"
                                        : ""
                        }`}
                        alt="Lines"
                        src={
                            screenWidth < 810
                                ? "/static/img/lines-3.svg"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "/static/img/lines-7.svg"
                                    : screenWidth >= 1200 && screenWidth < 1440
                                        ? "/static/img/lines-11.svg"
                                        : screenWidth >= 1440
                                            ? "/static/img/lines-15.svg"
                                            : undefined
                        }
                    />
                    <div
                        className={`mix-blend-screen absolute ${
                            screenWidth < 810
                                ? "w-[1075px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "w-[1124px]"
                                    : screenWidth >= 1200 && screenWidth < 1440
                                        ? "w-[1288px]"
                                        : screenWidth >= 1440
                                            ? "w-[1388px]"
                                            : ""
                        } ${
                            screenWidth < 810
                                ? "left-[-335px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "left-[-170px]"
                                    : screenWidth >= 1200 && screenWidth < 1440
                                        ? "-left-16"
                                        : screenWidth >= 1440
                                            ? "left-2"
                                            : ""
                        } ${
                            screenWidth < 810
                                ? "top-[-217px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "top-[-59px]"
                                    : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                        ? "top-[7px]"
                                        : ""
                        } ${
                            screenWidth < 810
                                ? "h-[814px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "h-[783px]"
                                    : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                        ? "h-[781px]"
                                        : ""
                        }`}
                    >
                        {((screenWidth >= 1200 && screenWidth < 1440) ||
                            (screenWidth >= 810 && screenWidth < 1200) ||
                            screenWidth < 810) && (
                            <div
                                className={`relative ${
                                    screenWidth < 810
                                        ? "w-[390px]"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "w-[771px]"
                                            : screenWidth >= 1200 && screenWidth < 1440
                                                ? "w-[1161px]"
                                                : ""
                                } ${
                                    screenWidth < 810
                                        ? "left-[335px]"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "left-[170px]"
                                            : screenWidth >= 1200 && screenWidth < 1440
                                                ? "left-16"
                                                : ""
                                } ${screenWidth < 810 ? "top-[217px]" : screenWidth >= 810 && screenWidth < 1200 ? "top-[59px]" : ""} ${
                                    screenWidth < 810 ? "bg-cover" : ""
                                } ${
                                    screenWidth < 810
                                        ? "h-[564px]"
                                        : screenWidth >= 810 && screenWidth < 1200
                                            ? "h-[724px]"
                                            : screenWidth >= 1200 && screenWidth < 1440
                                                ? "h-[636px]"
                                                : ""
                                } ${screenWidth < 810 ? "bg-[url(/static/img/only-middle-line-3.gif)]" : ""} ${
                                    screenWidth < 810 ? "bg-[50%_50%]" : ""
                                }`}
                            >
                                <img
                                    className={`top-0 object-cover absolute ${
                                        (screenWidth >= 1200 && screenWidth < 1440) || (screenWidth >= 810 && screenWidth < 1200)
                                            ? "w-[463px]"
                                            : screenWidth < 810
                                                ? "w-[113px]"
                                                : ""
                                    } ${
                                        screenWidth >= 810 && screenWidth < 1200
                                            ? "left-[158px]"
                                            : screenWidth >= 1200 && screenWidth < 1440
                                                ? "left-[349px]"
                                                : screenWidth < 810
                                                    ? "left-[277px]"
                                                    : ""
                                    } ${
                                        screenWidth >= 810 && screenWidth < 1200
                                            ? "h-[724px]"
                                            : screenWidth >= 1200 && screenWidth < 1440
                                                ? "h-[636px]"
                                                : screenWidth < 810
                                                    ? "h-[564px]"
                                                    : ""
                                    }`}
                                    alt="Only middle line"
                                    src={
                                        (screenWidth >= 1200 && screenWidth < 1440) || (screenWidth >= 810 && screenWidth < 1200)
                                            ? "/static/img/only-middle-line-3.gif"
                                            : screenWidth < 810
                                                ? "/static/img/only-right-line-3.gif"
                                                : undefined
                                    }
                                />
                                <img
                                    className={`top-0 object-cover absolute ${
                                        screenWidth < 810
                                            ? "w-32"
                                            : screenWidth >= 810 && screenWidth < 1200
                                                ? "w-[280px]"
                                                : screenWidth >= 1200 && screenWidth < 1440
                                                    ? "w-[399px]"
                                                    : ""
                                    } ${
                                        screenWidth < 810
                                            ? "left-0"
                                            : screenWidth >= 810 && screenWidth < 1200
                                                ? "left-[491px]"
                                                : screenWidth >= 1200 && screenWidth < 1440
                                                    ? "left-[762px]"
                                                    : ""
                                    } ${
                                        screenWidth < 810
                                            ? "h-[564px]"
                                            : screenWidth >= 810 && screenWidth < 1200
                                                ? "h-[722px] left-[575px]"
                                                : screenWidth >= 1200 && screenWidth < 1440
                                                    ? "h-[636px]"
                                                    : ""
                                    }`}
                                    alt="Only left line"
                                    src={
                                        screenWidth < 810
                                            ? "/static/img/only-left-line-3.gif"
                                            : (screenWidth >= 1200 && screenWidth < 1440) || (screenWidth >= 810 && screenWidth < 1200)
                                                ? "/static/img/only-right-line-3.gif"
                                                : undefined
                                    }
                                />
                                {((screenWidth >= 1200 && screenWidth < 1440) || (screenWidth >= 810 && screenWidth < 1200)) && (
                                    <img
                                        className={`left-70 top-0 object-cover absolute ${
                                            screenWidth >= 810 && screenWidth < 1200
                                                ? "w-[292px] right-[560px]"
                                                : screenWidth >= 1200 && screenWidth < 1440
                                                    ? "w-[399px]"
                                                    : ""
                                        } ${
                                            screenWidth >= 810 && screenWidth < 1200
                                                ? "h-[724px]"
                                                : screenWidth >= 1200 && screenWidth < 1440
                                                    ? "h-[636px]"
                                                    : ""
                                        }`}
                                        alt="Only left line"
                                        src="/static/img/only-left-line-3.gif"
                                    />
                                )}
                            </div>
                        )}

                        {screenWidth >= 1440 && (
                            <>
                                <img
                                    className="absolute w-[463px] h-[636px] top-[70px] left-[463px] object-cover"
                                    alt="Only middle line"
                                    src="/static/img/only-middle-line-3.gif"
                                />
                                <img
                                    className="absolute w-[463px] h-[636px] top-[70px] left-[925px] object-cover"
                                    alt="Only right line"
                                    src="/static/img/only-right-line-3.gif"
                                />
                                <img
                                    className="absolute w-[463px] h-[636px] top-[70px] left-0 object-cover"
                                    alt="Only left line"
                                    src="/static/img/only-left-line-3.gif"
                                />
                            </>
                        )}
                    </div>
                    <div
                        className={`[font-family:'Aeonik-Medium',Helvetica] text-[#f4f4f6] font-medium relative ${
                            (screenWidth >= 1200 && screenWidth < 1440) ||
                            screenWidth >= 1440 ||
                            (screenWidth >= 810 && screenWidth < 1200)
                                ? "w-[815px]"
                                : ""
                        } ${screenWidth < 810 ? "self-stretch" : ""} ${
                            screenWidth < 810
                                ? "tracking-[-0.56px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "tracking-[-0.70px]"
                                    : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                        ? "tracking-[-0.80px]"
                                        : ""
                        } ${
                            screenWidth < 810
                                ? "text-[56px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "text-[70px]"
                                    : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                        ? "text-[80px]"
                                        : ""
                        } ${screenWidth >= 810 && screenWidth < 1200 ? "mr-[-88.00px]" : ""} ${
                            screenWidth < 810
                                ? "leading-[53.2px]"
                                : screenWidth >= 810 && screenWidth < 1200
                                    ? "leading-[66.5px]"
                                    : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                        ? "leading-[76.0px]"
                                        : ""
                        }`}
                    >
                        Launch your project
                        <br/>
                        in seconds
                    </div>
                    <div
                        className={`w-full flex self-stretch flex-[0_0_auto] relative ${
                            (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810 ? "flex-col" : ""
                        } ${
                            (screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810
                                ? "items-start"
                                : (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440
                                    ? "items-center"
                                    : ""
                        } ${(screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810 ? "gap-[34px]" : ""} ${
                            (screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440 ? "justify-between" : ""
                        }`}
                    >
                        <p
                            className={`[font-family:'Aeonik-Regular',Helvetica] mt-[-1.00px] tracking-[0] text-lg text-[#9997a4] font-normal leading-[23.4px] relative ${
                                (screenWidth >= 1200 && screenWidth < 1440) ||
                                screenWidth >= 1440 ||
                                (screenWidth >= 810 && screenWidth < 1200)
                                    ? "w-[435.63px]"
                                    : ""
                            } ${screenWidth < 810 ? "self-stretch" : ""}`}
                        >
                            The first decentralized incubator for AI and blockchain projects built on the Bittensor
                            ecosystem.
                        </p>
                        <div className="inline-flex items-center gap-2.5 flex-[0_0_auto] relative">
                            <LaunchAProject
                                className={
                                    screenWidth < 810
                                        ? "!h-[40.5px] !flex-[0_0_auto] !px-[18px] !py-0"
                                        : (screenWidth >= 1200 && screenWidth < 1440) ||
                                        screenWidth >= 1440 ||
                                        (screenWidth >= 810 && screenWidth < 1200)
                                            ? "!h-[40.5px] !flex !w-[183px]"
                                            : undefined
                                }
                                property1="default"
                                text="Launch a project"
                            />
                            <Explore
                                className={
                                    screenWidth < 810
                                        ? "!h-[40.5px] !w-[175px]"
                                        : (screenWidth >= 1200 && screenWidth < 1440) ||
                                        screenWidth >= 1440 ||
                                        (screenWidth >= 810 && screenWidth < 1200)
                                            ? "!h-[40.5px]"
                                            : undefined
                                }
                                property1="default"
                            />
                        </div>
                    </div>
                </div>
                <div
                    className={`flex items-center top-0 [-webkit-backdrop-filter:blur(20px)_brightness(100%)] justify-between bg-[#070607f2] backdrop-blur-[20px] backdrop-brightness-[100%] fixed ${
                        screenWidth < 810
                            ? "w-[391px]"
                            : screenWidth >= 810 && screenWidth < 1200
                                ? "w-[810px]"
                                : screenWidth >= 1200 && screenWidth < 1440
                                    ? "w-[1200px]"
                                    : screenWidth >= 1440
                                        ? "w-[1440px]"
                                        : ""
                    } ${
                        screenWidth < 810
                            ? "p-[15px]"
                            : screenWidth >= 810 && screenWidth < 1200
                                ? "px-5 py-[15px]"
                                : screenWidth >= 1200 && screenWidth < 1440
                                    ? "p-5"
                                    : screenWidth >= 1440
                                        ? "px-[54px] py-5"
                                        : ""
                    }`}
                >
                    {!isBurgerOpen && (
                        <>
                            {((screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440) && (
                                <div className="flex self-stretch items-center grow gap-5 flex-1 relative">
                                    <PlaceHolderButton property1="default" text="About"/>
                                    <img
                                        className="w-px h-[22.08px] relative"
                                        alt="Vector"
                                        src={
                                            screenWidth >= 1200 && screenWidth < 1440
                                                ? "/static/img/vector-1089.svg"
                                                : screenWidth >= 1440
                                                    ? "/static/img/vector-1089-1.svg"
                                                    : undefined
                                        }
                                    />
                                    <PlaceHolderButton className="!w-16" divClassName="!left-px" property1="default"
                                                       text="Features"/>
                                    <img
                                        className="w-px h-[22.08px] relative"
                                        alt="Vector"
                                        src={
                                            screenWidth >= 1200 && screenWidth < 1440
                                                ? "/static/img/vector-1089.svg"
                                                : screenWidth >= 1440
                                                    ? "/static/img/vector-1089-1.svg"
                                                    : undefined
                                        }
                                    />
                                    <PlaceHolderButton className="!w-[71px]" property1="default" text="Roadmap"/>
                                    <img
                                        className="w-px h-[22.08px] relative"
                                        alt="Vector"
                                        src={
                                            screenWidth >= 1200 && screenWidth < 1440
                                                ? "/static/img/vector-1089.svg"
                                                : screenWidth >= 1440
                                                    ? "/static/img/vector-1089-1.svg"
                                                    : undefined
                                        }
                                    />
                                    <PlaceHolderButton className="!w-[82px]" property1="default" text="Launchpad"/>
                                </div>
                            )}

                            <img
                                className={`relative ${
                                    (screenWidth >= 1200 && screenWidth < 1440) ||
                                    screenWidth >= 1440 ||
                                    (screenWidth >= 810 && screenWidth < 1200)
                                        ? "grow flex-1"
                                        : screenWidth < 810
                                            ? "flex-[0_0_auto]"
                                            : ""
                                }`}
                                alt="Tao incubator logo"
                                src={
                                    screenWidth >= 1200 && screenWidth < 1440
                                        ? "/static/img/tao-incubator-lofo-2.svg"
                                        : screenWidth >= 1440
                                            ? "/static/img/tao-incubator-lofo-3.svg"
                                            : screenWidth < 810
                                                ? "/static/img/tao-incubator-lofo.svg"
                                                : screenWidth >= 810 && screenWidth < 1200
                                                    ? "/static/img/tao-incubator-lofo-1.svg"
                                                    : undefined
                                }
                            />

                            {((screenWidth >= 1200 && screenWidth < 1440) || screenWidth >= 1440) && (
                                <div className="flex flex-col items-end grow gap-2.5 flex-1 justify-center relative">
                                    <PropertyDefaultWrapper property1="default"/>
                                </div>
                            )}

                            {((screenWidth >= 810 && screenWidth < 1200) || screenWidth < 810) && (
                                <div className="flex items-center grow gap-3 flex-1 justify-end relative">
                                    <PropertyDefaultWrapper className="!flex-[0_0_auto]" property1="default"/>
                                    <img
                                        onClick={() => setIsBurgerOpen(true)}
                                        className="w-9 h-9 relative cursor-pointer"
                                        alt="Open Menu"
                                        src={
                                            screenWidth < 810
                                                ? "/static/img/connect-wallet.svg"
                                                : screenWidth >= 810 && screenWidth < 1200
                                                    ? "/static/img/connect-wallet-1.svg"
                                                    : undefined
                                        }
                                    />
                                </div>
                            )}
                        </>
                    )}
                    {isBurgerOpen && <Burger onClose={() => setIsBurgerOpen(false)}/>}
                </div>
            </div>
        </div>
    );
};
