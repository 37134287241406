import React from "react";
import {useWindowWidth} from "../../breakpoints";
import {LaunchAProject} from "../LaunchAProject";


export const Burger = ({onClose}) => {
    const screenWidth = useWindowWidth();
    const handleClick = (text) => {
        const element = document.getElementById(text);
        if (element) {
            onClose();
            element.scrollIntoView({behavior: "smooth"});
        } else {
            console.warn(`Element with id '${text}' not found.`);
        }
    };

    return (
        <div className="w-full flex flex-row justify-center bg-[#060607]">
            <div
                className={`overflow-hidden bg-[#060607] relative ${
                    screenWidth < 810 ? "w-[390px]" : screenWidth >= 810 ? "w-[810px]" : ""
                } ${screenWidth < 810 ? "h-[632px]" : screenWidth >= 810 ? "h-[784px]" : ""}`}
            >
                <div
                    className={`flex flex-col items-start top-[89px] gap-5 justify-center absolute ${
                        screenWidth < 810 ? "w-[360px]" : screenWidth >= 810 ? "w-[770px]" : ""
                    } ${screenWidth < 810 ? "left-[15px]" : screenWidth >= 810 ? "left-5" : ""}`}
                >
                    <div
                        onClick={() => handleClick('About')}
                        className={`[font-family:'Aeonik-Medium',Helvetica] w-fit mt-[-1.00px] text-[#f4f4f6] font-medium whitespace-nowrap relative ${
                            screenWidth < 810 ? "tracking-[-0.32px]" : screenWidth >= 810 ? "tracking-[-0.29px]" : ""
                        } ${screenWidth < 810 ? "text-[32px]" : screenWidth >= 810 ? "text-[29px]" : ""} ${
                            screenWidth < 810 ? "leading-8" : screenWidth >= 810 ? "leading-[29px]" : ""
                        }`}
                    >
                        About
                    </div>
                    <img
                        className="w-full self-stretch h-px relative"
                        alt="Vector"
                        src={screenWidth < 810 ? "/static/img/vector-1093.svg" : screenWidth >= 810 ? "/static/img/vector-1093-1.svg" : undefined}
                    />
                    <div
                        onClick={() => handleClick('Features')}
                        className={`[font-family:'Aeonik-Medium',Helvetica] w-fit text-[#f4f4f6] font-medium whitespace-nowrap relative ${
                            screenWidth < 810 ? "tracking-[-0.32px]" : screenWidth >= 810 ? "tracking-[-0.29px]" : ""
                        } ${screenWidth < 810 ? "text-[32px]" : screenWidth >= 810 ? "text-[29px]" : ""} ${
                            screenWidth < 810 ? "leading-8" : screenWidth >= 810 ? "leading-[29px]" : ""
                        }`}
                    >
                        Features
                    </div>
                    <img
                        className="w-full self-stretch h-px relative"
                        alt="Vector"
                        src={screenWidth < 810 ? "/static/img/vector-1093.svg" : screenWidth >= 810 ? "/static/img/vector-1093-1.svg" : undefined}
                    />
                    <div
                        onClick={() => handleClick('Roadmap')}
                        className={`[font-family:'Aeonik-Medium',Helvetica] w-fit text-[#f4f4f6] font-medium whitespace-nowrap relative ${
                            screenWidth < 810 ? "tracking-[-0.32px]" : screenWidth >= 810 ? "tracking-[-0.29px]" : ""
                        } ${screenWidth < 810 ? "text-[32px]" : screenWidth >= 810 ? "text-[29px]" : ""} ${
                            screenWidth < 810 ? "leading-8" : screenWidth >= 810 ? "leading-[29px]" : ""
                        }`}
                    >
                        Roadmap
                    </div>
                    <img
                        className="w-full self-stretch h-px relative"
                        alt="Vector"
                        src={screenWidth < 810 ? "/static/img/vector-1093.svg" : screenWidth >= 810 ? "/static/img/vector-1093-1.svg" : undefined}
                    />
                    <div
                        //onClick={handleClick}
                        className={`[font-family:'Aeonik-Medium',Helvetica] w-fit text-[#f4f4f6] font-medium whitespace-nowrap relative ${
                            screenWidth < 810 ? "tracking-[-0.32px]" : screenWidth >= 810 ? "tracking-[-0.29px]" : ""
                        } ${screenWidth < 810 ? "text-[32px]" : screenWidth >= 810 ? "text-[29px]" : ""} ${
                            screenWidth < 810 ? "leading-8" : screenWidth >= 810 ? "leading-[29px]" : ""
                        }`}
                    >
                        Launchpad
                    </div>
                    <img
                        className="w-full self-stretch h-px relative"
                        alt="Vector"
                        src={screenWidth < 810 ? "/static/img/vector-1093.svg" : screenWidth >= 810 ? "/static/img/vector-1093-1.svg" : undefined}
                    />
                    <div
                        //onClick={handleClick}
                        className={`[font-family:'Aeonik-Medium',Helvetica] w-fit text-[#f4f4f6] font-medium whitespace-nowrap relative ${
                            screenWidth < 810 ? "tracking-[-0.32px]" : screenWidth >= 810 ? "tracking-[-0.29px]" : ""
                        } ${screenWidth < 810 ? "text-[32px]" : screenWidth >= 810 ? "text-[29px]" : ""} ${
                            screenWidth < 810 ? "leading-8" : screenWidth >= 810 ? "leading-[29px]" : ""
                        }`}
                    >
                        Contact
                    </div>
                </div>
                <div
                    className={`flex items-center top-0 [-webkit-backdrop-filter:blur(20px)_brightness(100%)] justify-between bg-[#070607f2] backdrop-blur-[20px] backdrop-brightness-[100%] fixed ${
                        screenWidth < 810 ? "w-[391px]" : screenWidth >= 810 ? "w-[810px]" : ""
                    } ${screenWidth < 810 ? "p-[15px]" : screenWidth >= 810 ? "px-5 py-[15px]" : ""}`}
                >
                    <img
                        className={`relative ${screenWidth >= 810 ? "grow" : ""} ${
                            screenWidth < 810 ? "flex-[0_0_auto]" : screenWidth >= 810 ? "flex-1" : ""
                        }`}
                        alt="Tao incubator lofo"
                        src={
                            screenWidth < 810
                                ? "/static/img/tao-incubator-lofo.svg"
                                : screenWidth >= 810
                                    ? "/static/img/tao-incubator-lofo-1.svg"
                                    : undefined
                        }
                    />
                    <div className="flex items-center grow gap-3 flex-1 justify-end relative">
                        <LaunchAProject className="!flex-[0_0_auto]" property1="default"/>
                        <img
                            onClick={onClose}
                            className="w-9 h-9 relative cursor-pointer"
                            alt="Open Menu"
                            src={
                                screenWidth < 810
                                    ? "/static/img/connect-wallet.svg"
                                    : screenWidth >= 810 && screenWidth < 1200
                                        ? "/static/img/connect-wallet-1.svg"
                                        : undefined
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
