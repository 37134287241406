/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const PlaceHolderButton = ({property1, className, divClassName, text = "About"}) => {
    const handleClick = () => {
        const element = document.getElementById(text);
        if (element) {
            element.scrollIntoView({behavior: "smooth"});
        } else {
            console.warn(`Element with id '${text}' not found.`);
        }
    };

    return (
        <div
            onClick={handleClick}
            id="about"
            className={`w-11 h-4 relative cursor-pointer select-none ${className}`}
        >
            <div
                className={`[font-family:'Aeonik-Regular',Helvetica] left-0 tracking-[0] text-base -top-px font-normal text-center whitespace-nowrap leading-4 absolute ${
                    property1 === "variant-2" ? "text-[#c2aee5]" : "text-[#e8e7ec]"
                } hover:text-[#c2aee5] transition duration-500 ${divClassName}`}
            >
                {text}
            </div>
        </div>

    );
};

PlaceHolderButton.propTypes = {
    property1: PropTypes.oneOf(["variant-2", "default"]),
    text: PropTypes.string,
};
