/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Telegram3 = ({color = "#E8E7EC", className}) => {
    return (
        <svg
            onClick={() => window.open('https://telegram.com', '_blank', 'noopener,noreferrer')}

            className={`${className} icon`}
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M23.3456 3.99247C23.5236 2.84179 22.4296 1.93355 21.4065 2.38276L1.02898 11.3296C0.295282 11.6517 0.348953 12.7631 1.1099 13.0054L5.3122 14.3437C6.11434 14.5991 6.98276 14.467 7.68319 13.9831L17.1577 7.43744C17.4434 7.24002 17.7548 7.6463 17.5107 7.89787L10.6907 14.9292C10.0292 15.6114 10.1605 16.7672 10.9563 17.2662L18.592 22.0545C19.4484 22.5915 20.5501 22.0521 20.7102 21.0171L23.3456 3.99247Z"
                fill={color}
            />
        </svg>
    );
};

Telegram3.propTypes = {
    color: PropTypes.string,
};
