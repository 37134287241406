/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Twitter2 = ({className}) => {
    return (
        <svg
            onClick={() => window.open('https://twitter.com', '_blank', 'noopener,noreferrer')}

            className={`${className} icon`}
            fill="none"
            height="23"
            viewBox="0 0 23 23"
            width="23"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.4047 9.97104L20.8513 1.50146H19.0873L12.6187 8.85398L7.456 1.50146H1.5L9.30867 12.6208L1.5 21.5014H3.264L10.0907 13.7352L15.544 21.5014H21.5M3.90067 2.80304H6.61067L19.086 20.2638H16.3753"
                fill="#E8E7EC"
            />
        </svg>
    );
};
