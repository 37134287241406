/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Twitter = ({color = "#E8E7EC", className}) => {
    return (
        <svg
            onClick={() => window.open('https://twitter.com', '_blank', 'noopener,noreferrer')}
            className={`${className} icon`}
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.8578 10.493L21.3045 2.02344H19.5405L13.0718 9.37595L7.90913 2.02344H1.95312L9.76179 13.1428L1.95312 22.0234H3.71713L10.5438 14.2572L15.9971 22.0234H21.9531M4.35379 3.32502H7.06379L19.5391 20.7857H16.8285"
                fill={color}
            />
        </svg>
    );
};

Twitter.propTypes = {
    color: PropTypes.string,
};
